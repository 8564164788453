export const Certs = [
  {
    name: "Sick Leave Certificate",
    priceStartsAt: 250,
    currency: "INR",
    blogLink: "/online-medical-certificates/sick-leave-certificate",
    display: true,
  },
  {
    name: "Fitness Certificate",
    priceStartsAt: 450,
    currency: "INR",
    blogLink: "/online-medical-certificates/fitness-certificate",
    display: true,
  },
  {
    name: "Fit-to-work Certificate",
    priceStartsAt: 450,
    currency: "INR",
    blogLink: "/online-medical-certificates/fit-to-work",
    display: true,
  },
  {
    name: "Fit-to-travel Certificate",
    priceStartsAt: 450,
    currency: "INR",
    blogLink: "/online-medical-certificates/fit-to-travel",
    display: true,
  },
  {
    name: "Fitness Certificate for School Admission",
    priceStartsAt: 450,
    currency: "INR",
    blogLink: "/online-medical-certificates/fitness-for-school-admission",
    display: true,
  },
  {
    name: "Fitness Certificate for Adventure Sports",
    priceStartsAt: 450,
    currency: "INR",
    blogLink: "/online-medical-certificates/fitness-for-adventure-sports",
    display: true,
  },
  {
    name: "Work From Home Certificate",
    priceStartsAt: 750,
    currency: "INR",
    blogLink: "/online-medical-certificates/work-from-home-certificate",
    display: true,
  },
  {
    name: "Driving License Certificate (Form 1A)",
    priceStartsAt: 1200,
    currency: "INR",
    blogLink: "/online-medical-certificates/form-1a-certificate",
    display: false,
  },
  {
    name: "Recovery Certificate",
    priceStartsAt: 450,
    currency: "INR",
    blogLink: "/online-medical-certificates/recovery-certificate",
    display: true,
  },
  {
    name: "Caretaker Certificate",
    priceStartsAt: 450,
    currency: "INR",
    blogLink: "/online-medical-certificates/caretaker-certificate",
    display: true,
  },
  {
    name: "Unfit-to-work Certificate",
    priceStartsAt: 750,
    currency: "INR",
    blogLink: "/online-medical-certificates/unfit-to-work",
    display: true,
  },
  {
    name: "Unfit-to-travel Certificate",
    priceStartsAt: 750,
    currency: "INR",
    blogLink: "/online-medical-certificates/unfit-to-travel",
    display: true,
  },
  {
    name: "Custom Certificate",
    priceStartsAt: 750,
    currency: "INR",
    blogLink: "/online-medical-certificates/custom-certificate",
    display: false,
  },
  {
    name: "Online Doctor Consultation",
    priceStartsAt: 300,
    currency: "INR",
    blogLink: "/doctor-consultation",
    display: true,
  },
];
