import React from "react";
import "./style.css";

const BannerComponent = ({ smallDevice }) => {
  return (
    <div className="banner-wrapper fw-400">
      <div className="container banner-wrapper">
        <h2 className="fw-600 pb-0 h1 text-center">
          Open your medical clinic within minutes
        </h2>
        <h6>Powered by Medoc.life</h6>
        <a
          href="#doctor-onboard"
          className="btn btn-white-rounded px-4 py-2 mt-3 py-sm-3 fw-600"
        >
          Start my virtual clinic 🎉
        </a>
        <div className="d-flex flex-column flex-md-row align-items-center text-center">
          <div className={`col-12 ${smallDevice && "pb-24"}`}>
            <ul className="list-group pt-3 pb-4 fw-500">
              <li className="py-2">
                <i className="fa fa-check-circle-o" />
                &nbsp; Zero setup fees (until July 01, 2023)
              </li>
              <li className="py-2">
                <i className="fa fa-check-circle-o" />
                &nbsp; Recognised by{" "}
                <a
                  href="https://www.startupindia.gov.in/content/sih/en/profile.Startup.63b5719de4b0efec669fac7a.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white fw-600"
                  title="Trademark is solely owned by DIPP"
                >
                  Startup India
                </a>
              </li>
              <li className="py-2">
                <i className="fa fa-check-circle-o" />
                &nbsp; Trusted by 25,000+ users & 4.5 rating on Trustpilot
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerComponent;
