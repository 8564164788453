import React from "react";
import "./style.css";

import { Routes } from "constants/index";

const SectionOneComponent = ({ smallDevice }) => {
  return (
    <div className="section-one-wrapper fw-400">
      <div className="container d-flex flex-column flex-md-row align-items-center section-one-wrapper pt-0">
        <div className={`col-12 col-md-6 ${smallDevice && "py-24"}`}>
          <h2 className={`h1 fw-600 pb-2`}>
            The fastest way to get a medical certificate
          </h2>
          <ul className="list-group pt-sm-3 pb-4 fw-500">
            <li className="py-2">
              <i className="fa fa-check-circle-o" />
              &nbsp; Get online medical certificate in 60 minutes*
            </li>
            <li className="py-2">
              <i className="fa fa-check-circle-o" />
              &nbsp; Signed by a registered Indian doctor
            </li>
            <li className="py-2">
              <i className="fa fa-check-circle-o" />
              &nbsp; No appointment or travel time needed
            </li>
            <li className="py-2">
              <i className="fa fa-check-circle-o" />
              &nbsp; Abiding by National Medical Commission Act 2019
            </li>
            <li className="py-2">
              <i className="fa fa-check-circle-o" />
              &nbsp; Recognised by{" "}
              <a
                href="https://www.startupindia.gov.in/content/sih/en/profile.Startup.63b5719de4b0efec669fac7a.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-violet fw-600"
                title="Trademark is solely owned by DIPP"
              >
                Startup India
              </a>
            </li>
          </ul>
          <a
            href={Routes.LOGIN}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-violet-rounded px-4 py-2 py-sm-3 fw-500"
          >
            Buy Medical Certificate Now
          </a>
        </div>
        <div className="col-12 col-md-6">
          <img
            src="https://storage.googleapis.com/medoc-live.appspot.com/images/doctor6_20.webp"
            alt="play_image"
            className="col-12"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionOneComponent;
