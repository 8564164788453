import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import ScheduleAppointmentComponent from "../schedule_appointment/component";
import { LoadingComponent } from "components";
import { add, update } from "firebase_config";
import { post } from "services";
import { Tables, ENV } from "constants/index";

const AppointmentDetailsComponent = ({ profile }) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    appointment: false,
    doctorId: profile.userId,
    isPrescriptionRequired: true,
    paymentStatus: "pending",
    status: "pending",
  });
  const history = useHistory();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleChange = (key, value) => {
    if (key === "type") {
      const type = profile.types?.find((t) => t.key === value);
      setDetails((prev) => ({
        ...prev,
        [key]: value,
        meetLink: type.link || "",
        product: type,
        appointment: value === "video",
      }));
    } else {
      setDetails((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const isAppointmentRequired = () => {
    if (!details.appointment && details.type === "video") {
      setDetails((prev) => ({
        ...prev,
        appointment: true,
      }));
    } else {
      saveDetailsAndCollectPayment();
    }
  };

  const handlePayment = async (apt) => {
    const options = {
      key: ENV.RAZORPAY_KEY_ID,
      amount: +details.product?.cost * 100,
      currency: "INR",
      name: "Medoc",
      description: details.product?.title,
      image:
        "https://storage.googleapis.com/medoc-live.appspot.com/images/medoc_new_color.webp",
      handler: async (response) => {
        setLoading(true);
        console.log("apt", apt);
        const result = await update(Tables.DC_PUBLIC_APPOINTMENTS, apt.id, {
          paymentStatus: "completed",
        });
        console.log("result", result);
        if (result.status === 200) {
          toast.success("Your request sent to doctor!");
          if (process.env.NODE_ENV !== "development") {
            await post("/send_email", {
              sender: "naga",
              templateName:
                details.type === "text"
                  ? "text_appointment_confirmation.html"
                  : "call_appointment_confirmation.html",
              email: details.email,
              bcc: [profile.email],
              subject: `Dr. ${profile.name} - Thank you for contacting me. I will respond to your request soon`,
              ...details,
              bookedSlot: details.bookedSlot,
              doctorName: profile.name,
              meetLink: details.type === "video" ? details.meetLink : "",
            });
          }
          history.push(`/dr/${profile.username}/success`);
        } else {
          toast.error("Something went wrong. Contact our admin team!");
        }
        setLoading(false);
      },
      callback_url: `https://medoc.life/dr/${profile.username}/success`,
      prefill: {
        name: details.name,
        email: details.email,
        contact: details.mobile,
      },
      notes: {
        type: details.type,
        doctorId: profile.id,
      },
      theme: {
        color: "#6930db",
      },
    };

    const rzPopup = new window.Razorpay(options);
    rzPopup.open();
  };

  const handleErrors = () => {
    let err;
    if (!details.name) {
      err = {
        key: "name",
        message: "Full name is required",
      };
    } else if (!details.email) {
      err = {
        key: "email",
        message: "Email is required",
      };
    } else if (!details.gender) {
      err = {
        key: "gender",
        message: "Gender is required",
      };
    } else if (!details.age) {
      err = {
        key: "age",
        message: "Age is required",
      };
    } else if (!details.mobile) {
      err = {
        key: "mobile",
        message: "Mobile number is required",
      };
    } else if (details.mobile && details.mobile.length !== 12) {
      err = {
        key: "mobile",
        message: "Mobile number must contain 12-digits",
      };
    } else if (!details.type) {
      err = {
        key: "type",
        message: "Service type is required",
      };
    } else if (details.appointment && !details.bookedSlot) {
      err = {
        key: "appointment",
        message: "Appointment is required",
      };
    }

    return err;
  };

  const saveDetailsAndCollectPayment = async () => {
    const err = await handleErrors();
    if (err) {
      toast.error(err.message);
      return;
    }
    setLoading(true);
    const apt = await add(Tables.DC_PUBLIC_APPOINTMENTS, {
      ...details,
      paymentStatus: "pending",
    });
    if (apt.status === 200) {
      await handlePayment(apt.data);
    } else {
      toast.error("Something went wrong. Try again later!");
    }
  };

  return (
    <>
      <div className="row align-items-center mt-4">
        <div className="form-group col-12 col-sm-6">
          <label htmlFor="name">Full name *</label>
          <input
            type="text"
            className="form-control"
            id="name"
            aria-describedby="nameHelp"
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </div>
        <div className="form-group col-12 col-sm-6">
          <label htmlFor="email">Email address *</label>
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            onChange={(e) => handleChange("email", e.target.value)}
          />
        </div>
        <div className="form-group col-12 col-sm-6">
          <label htmlFor="gender">Gender *</label>
          <select
            className="form-control"
            onChange={(e) => handleChange("gender", e.target.value)}
          >
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="form-group col-12 col-sm-6">
          <label htmlFor="number">Age *</label>
          <input
            type="number"
            className="form-control"
            id="age"
            aria-describedby="age"
            onChange={(e) => handleChange("age", e.target.value)}
          />
        </div>
        <div className="form-group col-12 col-sm-6">
          <label htmlFor="mobile">Mobile *</label>
          <input
            type="number"
            className="form-control"
            id="mobile"
            aria-describedby="mobileHelp"
            onChange={(e) => handleChange("mobile", e.target.value)}
          />
          <small id="mobileHelp" className="form-text text-muted">
            Add country code (e.g. 91XXXXXXXXXX)
          </small>
        </div>
        <div className="form-group col-12 col-sm-6">
          <label htmlFor="mobile">Service Type *</label>
          <select
            className="form-control"
            onChange={(e) => handleChange("type", e.target.value)}
          >
            <option value="">Select</option>
            {profile.types?.map(
              (type, idx) =>
                type.enabled && (
                  <option key={idx} value={type.key}>
                    {type.title} - {type.cost} INR/-
                  </option>
                )
            )}
          </select>
        </div>
        <div className="form-group col-12 col-sm-6">
          <label htmlFor="message">Your Message</label>
          <textarea
            className="form-control"
            id="message"
            rows="3"
            onChange={(e) => handleChange("message", e.target.value)}
          />
        </div>
      </div>
      {details.appointment && (
        <ScheduleAppointmentComponent
          profile={profile}
          handleChange={handleChange}
        />
      )}
      {loading ? (
        <div>
          <LoadingComponent /> <span className="fw-500">Loading...</span>
        </div>
      ) : (
        <button
          className="btn btn-violet mt-3"
          onClick={isAppointmentRequired}
          disabled={loading}
        >
          Confirm & Pay
        </button>
      )}
      <span className="text-muted ml-2 mt-5">* No refunds provided</span>
    </>
  );
};

export default AppointmentDetailsComponent;
