import React from "react";

const ServicesComponent = ({ profile }) => {
  const redirectToLogin = () => {
    window.open("https://app.medoc.life");
  };

  return (
    <>
      <div className="row align-items-center mt-4">
        {profile.types?.map((type, idx) => (
          <div className="my-3 col-12 col-sm-4" key={idx}>
            <div className="card p-4 b-0 br-12">
              <div className="d-flex flex-row justify-content-between text-violet fw-500">
                {type.title}
                <span className="align-items-center">
                  <i className="fa fa-inr mr-1" />
                  {type.cost}
                </span>
              </div>
              <p className="fs-12 text-justify pt-2">{type.description}</p>
            </div>
          </div>
        ))}
        <div className="my-3 col-12 col-sm-4 pointer" onClick={redirectToLogin}>
          <div className="card p-4 b-0 br-12">
            <div className="d-flex flex-row justify-content-between text-violet fw-500">
              Referral Letter
            </div>
            <p className="fs-12 text-justify pt-2">
              <span className="fw-600">Ad</span> Get referral letter from Dr.{" "}
              {profile.name}. To get clinical scans, tests, reports etc.
            </p>
          </div>
        </div>
        <div className="my-3 col-12 col-sm-4 pointer" onClick={redirectToLogin}>
          <div className="card p-4 b-0 br-12">
            <div className="d-flex flex-row justify-content-between text-violet fw-500">
              Get Medical Certificates
            </div>
            <p className="fs-12 text-justify pt-2">
              <span className="fw-600">Ad</span> Our doctors provide 10+ medical
              certificates for different medical conditions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesComponent;
