import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Locations } from "constants/index";

const SearchBoxComponent = () => {
  const [searchValue, setSearchValue] = useState();
  const [searchResults, setSearchResults] = useState();
  const history = useHistory();

  const handleChange = (keyword) => {
    const re = new RegExp(keyword, "i");
    const res = Locations.filter((location) =>
      Object.keys(location).some((key) => location.name.match(re))
    );
    setSearchResults(res);
    setSearchValue(keyword);
  };

  const redirectTo = (res) => {
    setSearchValue(res.name);
    history.push(res.url);
  };

  return (
    <div className="search-wrapper">
      <div className="search-box d-flex flex-row justify-content-center">
        <div className="input-group-prepend">
          <span className="input-group-text" id="search-city">
            <i className="fa fa-search" />
          </span>
        </div>
        <div className="search-box-wrapper">
          <input
            type="text"
            className="py-3 pl-3"
            placeholder="Search State"
            aria-label="Search State"
            aria-describedby="search-city"
            onChange={(e) => handleChange(e.target.value)}
          />
          {searchValue && (
            <div className="search-results">
              {searchResults.length ? (
                searchResults.map((res, idx) => (
                  <div
                    className="list-type-1 pl-3 pointer"
                    key={`${name}_${idx}`}
                    onClick={(e) => redirectTo(res)}
                  >
                    {res.name}
                  </div>
                ))
              ) : (
                <div className="pl-3 py-2">No results found</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBoxComponent;
