export const Tables = {
  POSTS: "posts",
  LOGS: "logs",
  USER_BETS: "userBets",
  USERS: "users",
  TRANSACTIONS: "transactions",
  REFERRALS: "referrals",
  PROMOCODES: "promocodes",
  MEDICAL_REQUESTS: "medicalRequests",
  ORGANIZATION_REQUESTS: "organizationRequests",
  SECOND_OPINION_REQUESTS: "secondOpinionRequests",
  MEDICAL_REQUEST_FEEDBACKS: "medicalRequestFeedbacks",
  CLIENT_REQUESTS: "clientRequests",
  SHORT_LINKS: "shortLinks",
  DOCTOR_REQUESTS: "doctorRequests",
  DC_PUBLIC_PROFILE: "doctorPublicProfiles",
  DC_PUBLIC_APPOINTMENTS: "doctorPublicAppointments",
};
