const blog = {
  name: "recovery-certificate",
  category: "",
  banner_url:
    "https://storage.googleapis.com/medoc-live.appspot.com/images/step1_1.jpg",
  title: "When you need a recovery certificate?",
  date: "28 Dec 2022",
  meta_description:
    "A recovery certificate is a document that is issued by a medical professional to confirm that an individual is recovering from an illness, injury, or medical procedure. In India, a recovery certificate may be required in a variety of different situations, and understanding when and why one may be necessary can be important for individuals and their families...",
  meta_keywords:
    "doctor certificate,medical certificate,certificate of doctor,recovery certificate",
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/medoc-offc/' target='_blank' style='color: #6930DB !important;'>Medoc.Life</a></div>`,
  body: `
  <div style="text-align: justify">
  <p>
  A recovery certificate is a document that is issued by a medical professional to confirm that an individual is recovering from an illness, injury, or medical procedure. In India, a recovery certificate may be required in a variety of different situations, and understanding when and why one may be necessary can be important for individuals and their families.
  </p>

  <p>
  One common reason that an individual may need a recovery certificate in India is if they are suffering from a serious illness or injury and are unable to work or attend school. A recovery certificate can provide proof of the individual's inability to work or attend school, and may be required by an employer or educational institution in order to provide time off or accommodations. For example, if an individual has been diagnosed with a chronic illness that requires frequent hospital visits or periods of rest, a recovery certificate may be necessary to provide evidence of the individual's need for time off work.
  </p>

  <p>
  Another reason that a recovery certificate may be required in India is if an individual has been involved in an accident or suffered a physical injury. In these cases, a recovery certificate may be required by insurance companies or other parties in order to provide proof of the individual's injury and the need for medical treatment. For example, if an individual has been in a car accident and has suffered a broken bone, a recovery certificate may be necessary to provide evidence of the injury and the need for medical care.
  </p>

  <p>
  A recovery certificate may also be required in India if an individual has undergone surgery or a medical procedure. In these cases, a recovery certificate may be required by an employer or other party in order to provide proof of the individual's need for time off work or other accommodations. For example, if an individual has undergone a major surgery and is expected to need several weeks of recovery time, a recovery certificate may be necessary to provide evidence of the individual's need for time off work.
  </p>

  <p>
  Finally, a recovery certificate may be required in India if an individual has been hospitalized for an extended period of time. In these cases, a recovery certificate may be required by an employer or other party in order to provide proof of the individual's hospitalization and the need for time off work or other accommodations. For example, if an individual has been hospitalized for several weeks due to a serious illness, a recovery certificate may be necessary to provide evidence of the individual's hospitalization and the need for time off work.
  </p>

  <p>
  Overall, a recovery certificate is an important document that can be required in a variety of different situations in India. Understanding when and why one may be necessary can help individuals and their families to be prepared and to take the necessary steps to obtain a recovery certificate if needed.
  </p>
  <br/><br/>
  </div>
  `,
};

export default blog;
