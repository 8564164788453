export const Routes = {
  DEFAULT: "/",
  HOME: "/home",
  DOCTOR_CONSULTATION: "/doctor-consultation",
  MEDICAL_CERTIFICATE: "/medical-certificate",
  MEDOC_GLOBAL: "/medoc-global",
  JOIN_INFLUENCER: "/join-influencer",
  EMPLOYER_VERIFY: "/verify-certificate",
  CONTACT_US: "/contact-us",
  ABOUT_US: "/about-us",
  DOCTOR_ONBOARD: "/doctor-onboard",
  FAQ: "/frequently-asked-questions",
  TNC: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  REFUND_POLICY: "/refund-policy",
  COOKIE_POLICY: "cookie-policy",
  LOGIN: "https://app.medoc.life",
  LOGOUT: "/logout",
  SIGNUP: "/signup",
  SIGNUP_USERNAME: "/signup/:username",
  SIGNUP_SUCCESS: "/signup_success",
  FORGOT_PASSWORD: "/forgot_password",
  SEARCH: "/search",
  LOCATIONS: "/locations",
  BLOG_TEMPLATE: "/online-medical-certificates/:blogName",
  SHORT_LINKS: "/ml/:shortCode",
  VIRTUAL_CLINIC: "/virtual-clinic",
  PLUS_CORPORATES: "/plus/corporates",
  STORE: "/store",
  APP: "https://app.medoc.life",
  BLOGS: "https://blog.medoc.life",
};
