import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import {
  HomeComponent,
  HomeCertificatesComponent,
  HomeConsultationComponent,
  HomeGlobalComponent,
  FaqComponent,
  EmployerVerifyComponent,
  InfluencerVerifyComponent,
  TncComponent,
  PrivacyPolicyComponent,
  RefundPolicyComponent,
  CookiePolicyComponent,
  ShowDocumentComponent,
  NotFoundComponent,
  ContactUsComponent,
  DoctorOnboardComponent,
  AboutUsComponent,
  ProductsComponent,
  LocationsComponent,
  SearchComponent,
  DoctorProfileComponent,
  DoctorProfileSuccessComponent,
  VirtualClinicComponent,
  BusinessComponent,
  StoreComponent,
} from "components";

export const Routes = (props) => {
  const location = useLocation();

  return (
    <main style={{ position: "relative", overflow: "hidden" }}>
      <Switch location={location} key={location.pathname}>
        <Route exact path="/" component={HomeComponent} />
        <Route exact path="/home" component={HomeComponent} />
        <Route
          exact
          path="/medical-certificate"
          component={HomeCertificatesComponent}
        />
        <Route
          exact
          path="/doctor-consultation"
          component={HomeConsultationComponent}
        />
        <Route exact path="/medoc-global" component={HomeGlobalComponent} />
        <Route exact path="/faq" component={FaqComponent} />
        <Route
          exact
          path="/frequently-asked-questions"
          component={FaqComponent}
        />
        <Route
          exact
          path="/employer-verify"
          component={EmployerVerifyComponent}
        />
        <Route
          exact
          path="/verify-certificate"
          component={EmployerVerifyComponent}
        />
        <Route exact path="/contact-us" component={ContactUsComponent} />
        <Route
          exact
          path="/doctor-onboard"
          component={DoctorOnboardComponent}
        />
        <Route exact path="/about-us" component={AboutUsComponent} />
        <Route
          exact
          path="/join-influencer"
          component={InfluencerVerifyComponent}
        />
        <Route exact path="/tnc" component={TncComponent} />
        <Route exact path="/pp" component={PrivacyPolicyComponent} />
        <Route exact path="/rp" component={RefundPolicyComponent} />
        <Route exact path="/cp" component={CookiePolicyComponent} />
        <Route exact path="/terms-and-conditions" component={TncComponent} />
        <Route
          exact
          path="/privacy-policy"
          component={PrivacyPolicyComponent}
        />
        <Route exact path="/refund-policy" component={RefundPolicyComponent} />
        <Route exact path="/cookie-policy" component={CookiePolicyComponent} />
        <Route path="/ml/:shortCode" component={ShowDocumentComponent} />
        <Route
          exact
          path="/online-medical-certificates/:product"
          component={ProductsComponent}
        />
        <Route exact path="/dr/:username" component={DoctorProfileComponent} />
        <Route
          exact
          path="/dr/:username/success"
          component={DoctorProfileSuccessComponent}
        />
        <Route exact path="/products/:product" component={ProductsComponent} />
        <Route exact path="/search" component={SearchComponent} />
        <Route
          exact
          path="/locations/:location"
          component={LocationsComponent}
        />
        <Route
          exact
          path="/virtual-clinic"
          component={VirtualClinicComponent}
        />
        <Route exact path="/plus/corporates" component={BusinessComponent} />
        <Route exact path="/store" component={StoreComponent} />
        <Route path="*" component={NotFoundComponent} />
        <Redirect to="/" />
      </Switch>
    </main>
  );
};

export default Routes;
