import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const SectionTwoComponent = ({ smallDevice }) => {
  return (
    <div id="section-two" className="section-two-wrapper container">
      <div>
        <h1 className="mt-5 mb-4 pt-5 header">What's Medoc Global?</h1>
        <p className="lead col-12 col-sm-10 px-sm-5 mx-sm-5 fw-400">
          Medoc Global is a healthcare advice platform that connects Indian
          doctors with patients around the world. Our doctors help you with{" "}
          <b>second opinions</b>.
        </p>
        <div className="row align-items-center my-5 py-sm-5">
          <div className="col-12 col-sm-6">
            <img
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/step1_3.jpg"
              alt="play_image"
              className="col-12 puzzle-gif"
            />
          </div>
          <div className="steps lh-32 col-12 col-sm-5 text-center text-sm-left p-5 p-sm-0 pl-sm-4 ">
            <span className="text-orange-600">Step 1</span>
            <h3 className="steps-header my-3 my-sm-2">Submit your request</h3>
            <div className="steps-body">
              Complete a quick medical questionnaire - most people tell us it
              only takes a few mintues
            </div>
          </div>
        </div>
        {smallDevice ? (
          <div className="row align-items-center my-5">
            <div className="col-12">
              <img
                src="https://storage.googleapis.com/medoc-live.appspot.com/images/step2_1.jpg"
                alt="play_image"
                className="col-12 puzzle-gif"
              />
            </div>
            <div className="lh-32 col-12 col-sm-5 text-center text-sm-left p-5 p-sm-0 pl-sm-4 ">
              <span className="text-orange-600">Step 2</span>
              <h3 className="steps-header my-3 my-sm-0">
                Your Doctor Buddy reviews
              </h3>
              <div className="steps-body fs-18">
                Your Doctor Buddy will review your request and call you to
                provide medical advice
              </div>
            </div>
          </div>
        ) : (
          <div className="row align-items-center text-sm-left my-5 py-4">
            <div className="lh-32 col-12 col-sm-6">
              <span className="text-orange-600">Step 2</span>
              <h3 className="steps-header my-3 my-sm-2">
                Your Doctor Buddy reviews
              </h3>
              <div className="steps-body">
                Your Doctor Buddy will review your request and call you to
                provide medical advice
              </div>
            </div>
            <div className="col-12 col-sm-5">
              <img
                src="https://storage.googleapis.com/medoc-live.appspot.com/images/step2_1.jpg"
                alt="play_image"
                className="col-12 puzzle-gif"
              />
            </div>
          </div>
        )}
        <div className="row align-items-center my-5 py-sm-5">
          <div className="col-12 col-sm-6">
            <img
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/step3_1.jpg"
              alt="play_image"
              className="col-12 puzzle-gif"
            />
          </div>
          <div className="lh-32 col-12 col-sm-5 text-center text-sm-left p-5 p-sm-0 pl-sm-4 ">
            <span className="text-orange-600">Step 3</span>
            <h3 className="steps-header my-3 my-sm-2">
              Receive further support via chat
            </h3>
            <div className="steps-body">
              Get constant feedback and advice via chat facility in the app -
              simple as that!
            </div>
          </div>
        </div>
      </div>
      <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white">
        <h1 className="text-center">Get started with Medoc Global now.</h1>
        <Link
          to="/login"
          className="btn btn-lg btn-orange fs-16 px-4 fw-500 mt-3"
        >
          Seek Advice
        </Link>
      </div>
      <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white d-none">
        <h1 className="text-center">Till now</h1>
        <hr className="hr-small hr-white mt-4 mb-sm-5" />
        <div className="d-flex flex-column flex-md-row justify-content-around">
          <div>
            <h4 className="pt-5 pt-md-0">100+</h4>
            <span className="lead">games</span>
          </div>
          <div>
            <h4 className="pt-5 pt-md-0">1000+ </h4>
            <span className="lead">players</span>
          </div>
          <div>
            <h4 className="pt-5 pt-md-0">£100,000+ </h4>
            <span className="lead">paid</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwoComponent;
