import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { GlobalFaqs } from "constants/index";

const SectionFourComponent = ({ smallDevice }) => {
  return (
    <div
      id="faq"
      className={`section-four-wrapper ${
        smallDevice ? "mb-5" : "dashboard-content"
      }`}
    >
      <h1 className="header text-center my-5 py-4">
        Frequently Asked Questions
      </h1>{" "}
      {GlobalFaqs.map((obj, objIdx) => (
        <div className="container" key={`${obj.key}_${objIdx}`}>
          {obj.values[0] &&
            obj.values.map(
              (que, queIdx) =>
                queIdx < 8 && (
                  <div key={queIdx}>
                    <input
                      type="checkbox"
                      id={`question_${objIdx}_${queIdx}`}
                      name="q"
                      className="questions"
                    />
                    <div>
                      <label
                        htmlFor={`question_${objIdx}_${queIdx}`}
                        className="d-flex flex-row align-items-center justify-content-between question fs-24 fw-500"
                      >
                        <div>{que.question}</div>
                        <div className="plus">+</div>
                      </label>
                    </div>
                    <div
                      className="answers fw-500"
                      dangerouslySetInnerHTML={{ __html: que.answer }}
                    ></div>
                    <hr />
                  </div>
                )
            )}
        </div>
      ))}
      <div className="text-center mt-4 d-none">
        <Link to="/faq" className="btn btn-violet-rounded px-4 py-2 fw-400">
          See more
        </Link>
      </div>
    </div>
  );
};

export default SectionFourComponent;
