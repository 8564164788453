import React from "react";
import "../style.css";

const AboutMeComponent = ({ profile }) => {
  return (
    <>
      <div className="d-flex flex-column flex-sm-row mt-4 mt-sm-5">
        <div className="text-center">
          <img
            src={profile.photoURL}
            alt={profile.name}
            className="profile-photo mb-3"
          />
        </div>
        <div className="media-body ml-sm-4">
          <h3 className="mt-0 text-center text-sm-left">Dr. {profile.name}</h3>
          <div className="pb-1 text-success text-center text-sm-left">
            <i className="fa fa-check-circle fs-16" />
            <span className="fs-14 pl-1 fw-600">
              Medical Registration Verified
            </span>
          </div>
          <div className="d-flex flex-row align-items-center my-1">
            <span className="fs-12">Qualification:</span>
            <span className="fs-16 ml-2 fw-500">{profile.qualification}</span>
          </div>
          <div className="d-flex flex-row align-items-center my-1">
            <span className="fs-12">Languages:</span>
            <div className="d-flex flex-row ml-2 fw-500">
              {profile.dialects?.map((d, idx) => (
                <span className="pl-1" key={d.label}>
                  {d.label}
                  {idx !== profile.dialects.length - 1 && ", "}
                </span>
              ))}
            </div>
          </div>
          <div className="d-flex flex-row align-items-center my-1">
            <span className="fs-12">Social Media:</span>
            <div className="d-flex flex-row">
              {profile.socialMediaLinks?.map(
                (sml) =>
                  sml.link && (
                    <a
                      href={sml.link}
                      target="_blank"
                      rel="noreferrer"
                      key={sml.link}
                    >
                      <i className={`fa fa-${sml.key} ml-2 text-violet`} />
                    </a>
                  )
              )}
            </div>
          </div>
          <div className="mt-2 text-justify">{profile.bio}</div>
        </div>
      </div>
      {profile.achievements && (
        <div className="d-flex flex-column flex-sm-row align-items-center justify-content-sm-around achievements mt-3">
          {profile.achievements.map((ach) => (
            <div className="text-violet p-3 fw-600" key={ach}>
              <i className="fa fa-check-circle-o pr-1" />
              {ach}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default AboutMeComponent;
