import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { TestimonialsComponent } from "components";
import { Routes } from "constants/index";

const SectionTwoComponent = ({ smallDevice }) => {
  return (
    <>
      <div id="section-two" className="section-two-wrapper container d-none">
        <div>
          <h2 className="mt-5 header h1">What's medoc?</h2>
          <p className="fs-sm-22 col-12 col-sm-10 px-sm-5 mx-sm-5 fw-400 text-violet">
            Medoc is an online health platform that helps you enjoy confidential
            consultations with registered Indian doctors - hassle free
          </p>
        </div>
      </div>
      <div className="py-5">
        <TestimonialsComponent smallDevice={smallDevice} />
      </div>
      <div id="section-two" className="section-two-wrapper container">
        <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white box">
          <h3 className="text-center">Trusted by 100+ companies.</h3>
          <p>
            Ensure employee health & fitness at <b>Zero Cost</b>
          </p>
          <div className="d-none flex-column flex-md-row justify-content-around mt-sm-5 mb-4">
            <div data-aos="fade-right" data-aos-duration="2500">
              <p className="display-4 pt-5 pt-md-0">500+</p>
              <span className="h4 text-white">Consultations</span>
            </div>
            <div data-aos="fade-up" data-aos-duration="2500">
              <p className="display-4 pt-5 pt-md-0">10,000+ </p>
              <span className="h4 text-white">Users</span>
            </div>
            <div data-aos="fade-left" data-aos-duration="2500">
              <p className="display-4 pt-5 pt-md-0">2M+ </p>
              <span className="h4 text-white">Impressions</span>
            </div>
          </div>
          <Link
            to={Routes.PLUS_CORPORATES}
            className="btn btn-violet-outline-r col-12 col-sm-3 px-4 fw-500 mt-3 py-2"
            data-aos="fade-up"
            data-aos-duration="2500"
          >
            Medoc For Business
          </Link>
        </div>
      </div>
    </>
  );
};

export default SectionTwoComponent;
