import React from "react";
import "./style.css";

import { Routes } from "constants/index";

const HowItWorksComponent = () => {
  return (
    <>
      <div className="container work-wrapper">
        <h2
          className="text-blue text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          So, how does it work?
        </h2>
        <div
          className="row justify-content-center align-items-center"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="col-12 col-sm-4 mt-5">
            <div className="card">
              <div className="card-header p-0">
                <div className="step-text btn btn-orange-rounded fs-12 fw-500 px-3">
                  Step 1
                </div>
                <img
                  src="https://storage.googleapis.com/medoc-live.appspot.com/images/step1_3.webp"
                  className="card-img-top"
                  alt="Questionnaire"
                />
              </div>
              <div className="card-body text-center text-blue">
                <div className="h4 text-blue">Fill a form</div>
                <p className="text-blue p-2">
                  <a
                    href={Routes.LOGIN}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-violet pointer fw-600"
                  >
                    Answer simple questions
                  </a>{" "}
                  - about yourself, medical condition and upload ID documents.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 mt-5">
            <div className="card">
              <div className="card-header p-0">
                <div className="step-text btn btn-orange-rounded fw-500 px-3">
                  Step 2
                </div>
                <img
                  src="https://storage.googleapis.com/medoc-live.appspot.com/images/step2_1.webp"
                  className="card-img-top"
                  alt="Questionnaire"
                />
              </div>
              <div className="card-body text-center">
                <div className="h4 text-blue">Doctor reviews</div>
                <p className="text-blue p-2">
                  A certified M.B.B.S doctor will review the information and
                  respond to you within 60 mins
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 mt-5">
            <div className="card">
              <div className="card-header p-0">
                <div className="step-text btn btn-orange-rounded fs-12 fw-500 px-3">
                  Step 3
                </div>
                <img
                  src="https://storage.googleapis.com/medoc-live.appspot.com/images/step3_1.webp"
                  className="card-img-top"
                  alt="Questionnaire"
                />
              </div>
              <div className="card-body text-center">
                <div className="h4 text-blue">Get back to rest</div>
                <p className="text-blue p-2">
                  We deliver the digital certificate via email as soon as doctor
                  review completes
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorksComponent;
