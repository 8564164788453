import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Routes } from "constants/index";

const StaticFooterComponent = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-3 text-center text-sm-left">
            <p className="row-header h2">medoc</p>
            <address className="address my-4 my-sm-3 text-center text-sm-left">
              1-4/7-19, KK Nagar, Vijayawada, <br />
              Andhra Pradesh <br />
              India - 520012
            </address>
            <b className="contact-email">contact@medoc.life</b> <br />
            <b className="contact-email">+91-(949)0086504</b>
          </div>
          <div className="col-12 col-sm-3 mt-4 my-sm-0 text-center text-sm-left">
            <h6>PRODUCTS</h6>
            <ul className="row-list list-style-type-none">
              <li>
                <Link to={Routes.MEDICAL_CERTIFICATE} className="text-white">
                  Medical Certificates
                </Link>
              </li>
              <li>
                <Link to={Routes.DOCTOR_CONSULTATION} className="text-white">
                  Doctor Consultations
                </Link>
              </li>
              <li>
                <Link to={Routes.VIRTUAL_CLINIC} className="text-white">
                  Virtual Clinics
                </Link>
              </li>
              <li>
                <a
                  href="https://selfcare.medoc.life"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  Self Care
                </a>
              </li>
              <li>
                <a
                  href="https://lab.medoc.life"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  Medoc AI Labs
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-3 mt-4 my-sm-0 text-center text-sm-left">
            <h6>COMPANY</h6>
            <ul className="row-list list-style-type-none">
              <li>
                <Link to={Routes.PLUS_CORPORATES} className="text-white">
                  For Corporates
                </Link>
              </li>
              <li>
                <Link to={Routes.PLUS_CORPORATES} className="text-white">
                  For Partners
                </Link>
              </li>
              <li>
                <Link to={Routes.ABOUT_US} className="text-white">
                  About us
                </Link>
              </li>
              <li>
                <Link to={Routes.CONTACT_US} className="text-white">
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-3 text-center text-sm-left">
            <h6>RESOURCES</h6>
            <ul className="row-list">
              <li>
                <a
                  href="https://blog.medoc.life"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-white"
                >
                  Blogs
                </a>
              </li>
              <li>
                <Link to={Routes.FAQ} className="text-white">
                  FAQ
                </Link>
              </li>
              <li>
                <a
                  href="https://www.trustpilot.com/evaluate/medoc.life"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  Leave a review
                </a>
              </li>
            </ul>
            <div className="text-center text-sm-left mb-3">
              Connect us at
              <a
                href="https://www.linkedin.com/company/medoc-offc"
                target="_blank"
                rel="noreferrer noopener"
                className="text-white px-2"
                title="Linkedin"
              >
                <i className="fa fa-linkedin fs-22" />
              </a>
              <a
                href="https://www.instagram.com/medoc_life/"
                target="_blank"
                rel="noreferrer noopener"
                className="text-white px-2"
                title="Instagram"
              >
                <i className="fa fa-instagram fs-22" />
              </a>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row fs-12 justify-content-center mt-4">
          <Link to={Routes.TNC} className="text-white">
            | Terms & Conditions |
          </Link>
          <Link to={Routes.PRIVACY_POLICY} className="text-white">
            | Privacy Policy |
          </Link>
          <Link to={Routes.PRIVACY_POLICY} className="text-white">
            | Refund Policy |
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StaticFooterComponent;
