import React from "react";
import { TypeAnimation } from "react-type-animation";

import "./style.css";

const SectionOneComponent = ({ smallDevice }) => {
  return (
    <div className="section-one-wrapper fw-400">
      <div className="container section-one-wrapper">
        <h2 className="fw-600 pb-0 h1 text-center d-flex flex-column flex-sm-row">
          Online Healthcare is{" "}
          <u>
            <TypeAnimation
              sequence={[
                "Accessible!", // Types "One"
                2000, // Waits 1s
                "Affordable!", // Deletes "One" and types "Two"
                2000, // Waits 2s
                "Convenient!", // Types "Three" without deleting "Two"
                2000, // Waits 2s
              ]}
              wrapper="div"
              cursor={true}
              repeat={Infinity}
              className="pl-3"
            />
          </u>
        </h2>
        <div className="d-flex flex-column flex-md-row align-items-center">
          <div className={`col-12 col-md-6 ${smallDevice && "pb-24"}`}>
            <ul className="list-group pt-5 pb-4 fw-500">
              <li className="py-2">
                <i className="fa fa-check-circle-o" />
                &nbsp; Get online medical certificate within 60 minutes
              </li>
              <li className="py-2">
                <i className="fa fa-check-circle-o" />
                &nbsp; No appointment or travel time needed
              </li>
              <li className="py-2">
                <i className="fa fa-check-circle-o" />
                &nbsp; Recognised by{" "}
                <a
                  href="https://www.startupindia.gov.in/content/sih/en/profile.Startup.63b5719de4b0efec669fac7a.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-violet fw-600"
                  title="Trademark is solely owned by DIPP"
                >
                  Startup India
                </a>
              </li>
              <li className="py-2">
                <i className="fa fa-check-circle-o" />
                &nbsp; Trusted by 35,000+ users & 4.2 rating on Trustpilot
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <img
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_71.webp"
              alt="play_image"
              className="col-12 home-image-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOneComponent;
