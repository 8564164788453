import React from "react";
import "./style.css";

import { HowItWorksComponent, TestimonialsComponent } from "components";
import { Routes } from "constants/index";

const SectionTwoComponent = ({ smallDevice }) => {
  return (
    <>
      <div className="">
        <HowItWorksComponent />
        <div className="text-center">
          <a
            href={Routes.LOGIN}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-violet-rounded text-white p-3 px-4 pointer"
          >
            Buy Certificate
          </a>
        </div>
        <div className="py-5">
          <TestimonialsComponent smallDevice={smallDevice} />
        </div>
      </div>
    </>
  );
};

export default SectionTwoComponent;
