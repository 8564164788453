import React from "react";
import "./style.css";

const SelfCareComponent = ({ smallDevice }) => {
  return (
    <>
      <div className="self-care-wrapper py-5">
        <div className="container">
          <div className="d-flex flex-column flex-md-row">
            <div className="col-12 col-sm-6"></div>
            <div className="col-12 col-sm-6">
              <h2 className="">Medoc Self Care</h2>
              <p>
                Get home remedies for 50+ diseases. Treat yourself better at
                home with constant doctor supervision. Easy-to-follow and Do you
                know you can self-care at home without reaching doctor?
              </p>
              <ul className="pl-3">
                <li>Simple healthcare tips for 50+ diseases</li>
                <li>
                  Each disease is thorougly reviewed by a qualified Medicial
                  Practitioner
                </li>
                <li>
                  Contact our medical team at <b>zero</b> cost
                </li>
              </ul>
              <div className="btn btn-violet-outline-r px-4 py-2 fs-14">
                Explore Now
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelfCareComponent;
