import React from "react";
import "./style.css";

const Offers = [
  {
    title: "Unlimited Appointments",
    description:
      "Patients can book unlimited appointment in your virtual clinic",
    display: true,
  },
  {
    title: "Easy Appointment Scheduler",
    description:
      "Our user-friendly scheduler helps you avoid double bookings and scheduling conflicts, making appointment management a breeze",
    display: true,
  },
  {
    title: "Verified Badge",
    description: "Get verified badge to build customers trust",
    display: true,
  },
  {
    title: "Emails",
    description:
      "Your patients will be informed and engaged every step of the way with automated appointment confirmation and feedback emails",
    display: true,
  },
  {
    title: "Storage Limit",
    description:
      "Keep all your patient records and files organized and secure with our generous storage limit of 50 MB.",
    display: true,
  },
  {
    title: "Customer Support",
    description: "Get support within 24 hours via whatsapp",
    display: true,
  },
  {
    title: "Analytics",
    description:
      "Gain valuable insights into your clinic's performance with our basic analytics, allowing you to make informed decisions and improve your practice over time",
    display: false,
  },
  {
    title: "Zero Cost Marketing",
    description: "Advertise on our social media platforms",
    display: false,
  },
  {
    title: "Google Ads",
    description:
      "Get 500 INR/- credits every month to grow your clinic and reach more patients with Ads on google.",
    display: false,
  },
  {
    title: "Google Business Profile",
    description:
      "We build business profile for your virtual clinic on google and we help you to get good SEO",
    display: false,
  },
];

const OfferingsComponent = ({ smallDevice }) => {
  return (
    <div id="section-one-two" className="section-one-two-wrapper container">
      <h2 className="text-center my-5 pt-5 py-sm-0 fw-600">
        What we offer at Virtual Clinic
      </h2>
      <div className="card-product py-4 px-sm-5">
        {Offers.map(
          (t, i) =>
            t.display && (
              <div key={i}>
                <div
                  className="row text-center text-sm-left"
                  data-aos="zoom-in-down"
                  data-aos-duration="1500"
                >
                  <div className="col-12 col-sm-6 fw-600 pt-1 pl-sm-5 ml-2">
                    {t.title}
                  </div>
                  <p className="col-12 col-sm-5 pt-1 pl-sm-4">
                    {t.description}
                  </p>
                </div>
                {i !== Offers.length - 1 && <hr className="py-0" />}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default OfferingsComponent;
