import React from "react";
import { Link } from "react-router-dom";

import { States } from "constants/index";

const AvailableLocationsComponent = () => {
  return (
    <div className="container available-locations">
      <h2 className="h5 text-center fw-300 my-5">
        States we deliver medical certificates and doctor consultations
      </h2>
      <div className="row align-items-center text-left mb-5">
        {States.map((group, groupIdx) => (
          <ul
            className="col-12 col-sm-3 list-type-none list-type-1 pl-sm-5 text-center text-sm-left mb-0"
            key={groupIdx}
          >
            {group.map((state, stateIdx) => (
              <li key={`${state}_${groupIdx}_${stateIdx}`}>
                <Link to={state.url} className="text-black">
                  {state.name}
                </Link>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default AvailableLocationsComponent;
