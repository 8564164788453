import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Faqs } from "constants/index";

const SectionFourComponent = ({ smallDevice }) => {
  return (
    <div
      id="faq"
      className={`section-four-wrapper ${
        smallDevice ? "my-5" : "dashboard-content mb-5"
      }`}
    >
      <h2 className="header text-center my-5 py-4">
        Frequently Asked Questions
      </h2>{" "}
      {Faqs.map((obj, objIdx) => (
        <div className="container" key={`${obj.key}_${objIdx}`}>
          {obj.values[0] &&
            obj.values.map(
              (que, queIdx) =>
                queIdx < 120 && (
                  <div key={queIdx}>
                    <input
                      type="checkbox"
                      id={`question_${objIdx}_${queIdx}`}
                      name="q"
                      className="questions"
                    />
                    <div>
                      <label
                        htmlFor={`question_${objIdx}_${queIdx}`}
                        className="d-flex flex-row align-items-center justify-content-between question fs-24 fw-500"
                      >
                        {que.html ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: que.question }}
                          ></div>
                        ) : (
                          <div>{que.question}</div>
                        )}
                        <div className="plus">+</div>
                      </label>
                    </div>
                    <div
                      className="answers fw-500"
                      dangerouslySetInnerHTML={{ __html: que.answer }}
                    ></div>
                    {queIdx < obj.values.length - 1 && <hr />}
                  </div>
                )
            )}
        </div>
      ))}
      <div className="text-center mt-5">
        <Link to="/faq" className="btn btn-violet-rounded px-4 py-2 fw-400">
          Show All
        </Link>
      </div>
    </div>
  );
};

export default SectionFourComponent;
