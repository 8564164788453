import React from "react";
import "./style.css";

import { Testimonials } from "constants/index";

const TestimonialsComponent = ({ smallDevice }) => {
  const speed = "100000";

  return (
    <div className="testimonials">
      <div className="align-items-center">
        <h2 className="text-center text-secondary my-5 fw-600 h6">
          What our customers say
        </h2>
        <div className="inner">
          <div className="wrapper">
            <div
              style={{ "--speed": `${speed}ms` }}
              className="d-flex flex-row testimonials"
            >
              {Testimonials.map((tt, i) => (
                <div className="mx-3" key={i}>
                  <div className="card-testimonial p-4">
                    <p className="text-violet">{tt.description}</p>
                    <div>{tt.name}</div>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{ "--speed": `${speed}ms` }}
              className="d-flex flex-row testimonials"
            >
              {Testimonials.map((tt, i) => (
                <div className="mx-3" key={i}>
                  <div className="card-testimonial p-4">
                    <p className="text-violet">{tt.description}</p>
                    <div>{tt.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <p className="mt-3 text-center">
          See 100+ reviews{" "}
          <a
            href="https://www.trustpilot.com/review/medoc.life"
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="fw-500 text-violet text-underline"
          >
            on our Trustpilot page
          </a>
        </p>
      </div>
    </div>
  );
};

export default TestimonialsComponent;
