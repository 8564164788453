import React from "react";
import { Link } from "react-router-dom";

import { Routes } from "constants/index";
import "./style.css";

const StaticHeaderTwoComponent = ({ headerText }) => {
  return (
    <div className="static-header-two">
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container">
          <Link to="/" className="home-page-title fw-600">
            <img
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/medoc_new_color.webp"
              className="navbar-brand"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent2"
            aria-controls="navbarSupportedContent2"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fa fa-bars" />
          </button>

          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent2"
          >
            <ul className="navbar-nav ml-auto align-items-center list-left">
              <li className={`nav-item ${!headerText && "d-none"}`}>
                <div className="nav-link">
                  <a
                    href={Routes.LOGIN}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-violet-rounded py-2 fs-14 fw-500"
                  >
                    Open my clinic
                  </a>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto align-items-center list-right">
              <li className="nav-item">
                <div className="nav-link">
                  <a
                    href={`${Routes.VIRTUAL_CLINIC}#doctor-onboard`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-violet-rounded py-2 fs-14 fw-500"
                  >
                    Open my clinic
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default StaticHeaderTwoComponent;
