import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import SectionOneComponent from "./section_one/component";
import SectionOneTwoComponent from "./section_one_two/component";
import SectionTwoComponent from "./section_two/component";
import SectionThreeComponent from "./section_three/component";
import SectionFourComponent from "./section_four/component";
import BlogsComponent from "../home/blogs/component";
import { StaticHeaderComponent, StaticFooterComponent } from "components";
import "./style.css";

const HomeCertificatesComponent = () => {
  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  return (
    <div className="home-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Medoc | Medical Certificates Online Across India</title>
        <meta
          name="description"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation. Price starts at 250 INR/-"
        />
        <link rel="canonical" href="https://medoc.life/medical-certificate" />
        <meta
          name="keywords"
          content="medical certificate,doctor medical certificate,medical report,online consultation"
        />
        <meta
          property="og:title"
          content="Medical Certificate Online In Minutes By Certified Indian Doctors"
        />
        <meta
          property="og:description"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation."
        />
        <meta
          property="og:url"
          content="https://medoc.life/medical-certificate"
        />
        <meta
          property="og:site_name"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation."
        />
        <meta
          property="article:modified_time"
          content="2022-12-21T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medical Certificate Online In Minutes By Certified Indian Doctors"
        />
        <meta
          name="twitter:description"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="3 minutes" />
      </Helmet>
      <StaticHeaderComponent />
      <SectionOneComponent smallDevice={smallDevice} />
      <div
        className="trustpilot-widget text-center my-4 mb-0"
        data-locale="en-UK"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="63489030a91712093d8e88e0"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
        data-min-review-count="10"
        data-without-reviews-preferred-string-id="1"
        data-style-alignment="center"
      >
        <a
          href="https://www.trustpilot.com/review/medoc.life"
          target="_blank"
          rel="noopener noreferrer"
          className="text-black fw-500"
        >
          <span className="fw-600">Excellent 4.7</span> out of 5 &nbsp;
          <img
            src="https://storage.googleapis.com/medoc-live.appspot.com/images/trustpilot.png"
            width="35"
            loading="lazy"
            alt="Medoc Trustpilot"
            className="tp-icon"
          />
          Trustpilot.
        </a>
      </div>
      <hr />
      <SectionOneTwoComponent smallDevice={smallDevice} />
      <SectionTwoComponent smallDevice={smallDevice} />
      <SectionThreeComponent smallDevice={smallDevice} />
      <BlogsComponent />
      <SectionFourComponent smallDevice={smallDevice} />
      <StaticFooterComponent />
    </div>
  );
};

export default HomeCertificatesComponent;
