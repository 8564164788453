import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./style.css";

import { StaticHeaderComponent, StaticFooterComponent } from "components";
import { Routes } from "constants/index";

const PrivacyPolicyComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Medoc | Privacy Policy | Protecting Your Personal Information on Our
          Medical Certificate Platform in India
        </title>
        <link rel="canonical" href="https://medoc.life/pp" />
        <meta
          name="description"
          content="Learn about our commitment to protecting your personal information on our medical certificate platform in India. Our privacy policy outlines how we handle and secure your data."
        />
        <meta
          name="keywords"
          content="medical certificate online,doctor consultation,digital healthcare,India,privacy policy,personal information,data protection"
        />
        <meta
          property="og:title"
          content="Medoc | Privacy Policy | Protecting Your Personal Information on Our Medical Certificate Platform in India"
        />
        <meta
          property="og:description"
          content="Learn about our commitment to protecting your personal information on our medical certificate platform in India. Our privacy policy outlines how we handle and secure your data."
        />
        <meta property="og:url" content="https://medoc.life/pp" />
        <meta property="og:site_name" content="medoc.life" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/razorpay_logo.jpeg"
        />
        <meta property="og:image:width" content="590" />
        <meta property="og:image:height" content="615" />
        <meta
          property="article:modified_time"
          content="2023-2-2T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medoc | Privacy Policy | Protecting Your Personal Information on Our Medical Certificate Platform in India"
        />
        <meta
          name="twitter:description"
          content="Learn about our commitment to protecting your personal information on medical certificate platform in India. Our privacy policy outlines how we handle and secure your data."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="15 minutes" />
      </Helmet>

      <StaticHeaderComponent />
      <div className="container privacy-policy-wrapper">
        <h3 className="page-title">Privacy Policy</h3>
        <div>
          Learn more about how{" "}
          <Link to={Routes.DEFAULT} className="fw-500">
            Medoc.life
          </Link>{" "}
          collect and use data.
        </div>
        <hr />
        <ol>
          <li>
            <b className="pp-header">Overview</b>
            <p className="pp-body">
              Thank you for using the Medoc.life platform provided by Perla
              InfoTech Pvt Ltd (Medoc.life), a platform that connects
              individuals with health practitioners for the purposes of the
              health practitioner running telehealth consultations and, if
              appropriate, providing other health services. Your privacy is
              important to us and we are committed to protecting your privacy in
              accordance to the Information Technology Act 2000 (Privacy Act),
              allied rules and applicable laws. This Policy outlines how we
              collect, use, disclose and store your personal information and
              lets you know how you can access that information. This Policy
              applies to our obligations when handling information in India.
              Please read this Policy carefully and contact us using the details
              below if you have questions.
            </p>
          </li>
          <li>
            <b className="pp-header">Consent</b>
            <p className="pp-body">
              By providing personal information, you consent to us collecting,
              using, storing and disclosing your personal information in
              accordance with this Policy or as required or permitted by law. If
              you continue using our services, then we will treat your use as
              your consent to us handling your personal information in
              accordance with this Policy. We will generally obtain consent from
              the owner of personal information to collect their personal
              information. Consent will usually be provided in writing; however,
              sometimes it may be provided orally or may be implied through a
              person’s conduct. We endeavour to only ask for your personal
              information if it is reasonably necessary for the activities that
              you are seeking to be involved in.
            </p>
          </li>
          <li>
            <b className="pp-header">
              What personal information do we collect and why do we collect it?
            </b>
            <p className="pp-body">
              {" "}
              <br />
              <b className="pp-header">Information collected about our users</b>
              <ul>
                <li>Your name, address, and contact details.</li>
                <li>Date of birth.</li>
                <li>Gender</li>
                <li>Place of work and its address</li>
                <li>
                  Any photos or records that you upload, such as a medical
                  record, personal ID.
                </li>
                <li>
                  Your device ID, device type and information, geo-location
                  information, Internet Protocol (IP) address, standard web log
                  information, browser session data, device and network
                  information, statistics on page views, acquisition sources,
                  search queries, browsing behaviour and information gathered
                  through internet cookies.
                </li>
                <li>
                  Your device ID, device type and information, geo-location
                  information, Internet Protocol (IP) address, standard web log
                  information, browser session data, device and network
                  information, statistics on page views, acquisition sources,
                  search queries, browsing behaviour and information gathered
                  through internet cookies.
                </li>
              </ul>
              <br />
              <b className="pp-header">Why we collect it</b>
              <ul>
                <li>
                  For the purpose for which the personal information was
                  originally collected.
                </li>
                <li>To identify and interact with you.</li>
                <li>To perform administrative and operational functions.</li>
                <li>
                  To comply with any legal requirements, including any purpose
                  authorised or required by an Indian law, court or tribunal.
                </li>
                <li>For any other purpose for which you give your consent.</li>
              </ul>
              <br />
              <b className="pp-header">How we collect it</b>
              <ul>
                <li>use our services.</li>
                <li>provide information to us on our platform.</li>
                <li>set up a profile with us.</li>
                <li>
                  interact or share personal information with us via our social
                  media; and communicate with us.
                </li>
                <li>Through our third party service providers.</li>
              </ul>
              <br />
              <b className="pp-header">
                About our general users that have may not subscribed to our
                Service but interact with us
              </b>
              <ul>
                <li>
                  Information you have provided in communications we have with
                  you.
                </li>
                <li>
                  Information you have provided in the platform before you
                  submit it to us, such as through cart abandonment.
                </li>
                <li>
                  Information about your access and use of our website,
                  including browser session data, device and network
                  information, statistics on page views, acquisition sources,
                  search queries, browsing behaviour and information gathered
                  through internet cookies.
                </li>
                <li>To identify and interact with you.</li>
                <li>To perform administrative and operational functions.</li>
              </ul>
              <br />
              <b className="pp-header">
                About contractors or prospective staff members (including health
                practitioners)
              </b>
              <ul>
                <li>
                  Your name, address, contact details (including email address
                  and phone number) and date of birth.
                </li>
                <li>
                  Your nationality and which countries you hold citizenship of.
                </li>
                <li>
                  Educational details, such as schools you have attended, any
                  qualifications you have received, transcripts and/or English
                  language test results.
                </li>
                <li>
                  Employment details, such as a CV, qualifications attained or
                  examples of work.
                </li>
                <li>
                  Any licences with relevant regulatory boards and/or other
                  bodies, councils or authorities.
                </li>
                <li>To enable us to carry out our recruitment functions.</li>
                <li>To correspond with you.</li>
                <li>To fulfil the terms of any contractual relationship.</li>
                <li>To ensure that you can perform your duties.</li>
              </ul>
              <br />
              <p>
                If you choose not to provide information as requested, we may
                not be able to service your needs. For example, it will not be
                possible for us to provide you with our service if you want to
                remain anonymous or use a pseudonym. We sometimes receive
                unsolicited personal information. In circumstances where we
                receive unsolicited personal information we will usually destroy
                or de-identify the information as soon as practicable if it is
                lawful and reasonable to do so unless the unsolicited personal
                information is reasonably necessary for, or directly related to,
                our functions or activities.
              </p>
            </p>
          </li>
          <li>
            <b className="pp-header">Sensitive information</b>
            <p className="pp-body">
              We may collect sensitive information from you. This includes
              health information, for example, details regarding your medical
              history, symptoms, or any health information contained in any
              documents you upload. If you consent to providing us with this
              information, we will only use it for facilitating our provision of
              services and to enable you to use our platform. You provide us
              with your health information when you enter it onto our platform.
              When you enter your health information, you are consenting to
              Medoc.life: (a) collecting and handling it in accordance with this
              Privacy Policy; and (b) sharing it with the health practitioners
              who have agreed to our terms for the purpose of providing our
              services to you and the health practitioners, and to facilitate
              the health practitioner’s provision of their services to you. If
              you do not agree to this, you should not provide us with your
              health information.
            </p>
          </li>
          <li>
            <b className="pp-header">Disclosing your personal information</b>
            <p className="pp-body">
              We may disclose your personal information to the following third
              parties: (a) our business or commercial partners; (b) the health
              practitioners who have agreed to our terms; (c) our professional
              advisers, dealers and agents; (d) third parties and contractors
              who provide services to us, including customer enquiries and
              support services, IT service providers, data storage, webhosting
              and server providers, marketing and advertising organisations,
              payment processing service providers; (e) payment system operators
              and debt-recovery functions; (f) third parties to collect and
              process data, such as Firebase, Google Cloud Platform, Google
              Analytics, Amazon Web Services, or other third parties; and (g)
              any third parties authorised by you to receive information held by
              us. We may also disclose your personal information if we are
              required, authorised or permitted by law. We may send information
              to third parties that are located outside of India for the
              purposes of providing our services. These third parties are
              located in the United Kingdom and the European Union, although
              this list may change from time to time. Disclosure is made to the
              extent that it is necessary to perform our functions or
              activities.
            </p>
          </li>
          <li>
            <b className="pp-header">
              Using your personal information for direct marketing
            </b>
            <p className="pp-body">
              From time to time, and in support of our future development and
              growth, we may use your personal information to contact you to
              promote and market our products and services. You can opt-out from
              being contacted for direct marketing purposes by contacting us at
              contact@medoc.life or by using the unsubscribe facility included
              in each direct marketing communication we send. Once we receive a
              request to opt out from receiving marketing information, we will
              stop sending such information within a reasonable amount of time.
            </p>
          </li>
          <li>
            <b className="pp-header">Security</b>
            <p className="pp-body">
              We take all reasonable steps to protect personal information under
              our control from misuse, interference and loss, and from
              unauthorised access, modification or disclosure. We hold your
              personal information electronically in secure databases operated
              by our third-party service providers. We protect the personal
              information we hold through a number of different layers
              including: (a) encrypted browsing through HTTPS; (b) storing
              authentication details, such as passwords, in hashed or non
              reversible formats; (c) actively monitoring errors and logs using
              industry level tooling; (d) operating within a secure cloud
              environment; and (e) relying on TLS security to interact with the
              databases. Our servers are hosted with Google Cloud Platform and
              Amazon Web Services and we use the provided security functionality
              and monitoring to detect and prevent persistent access to rogue
              services. Server access and deployment are limited to revokable
              access keys that can only be regenerated on a master account.
              Access to servers can only be gained by using industry standard
              encryption keys that are generated and regularly updated,
              including when employees leave Medoc. User logs redact certain
              types of information, such as passwords, before they are logged to
              prevent user information leaking to third parties. Servers and
              databases are limited to internal access only to prevent database
              access to the public, unless it relates to certain whitelisted
              services or for monitoring and troubleshooting. While we take
              reasonable steps to ensure your personal information is protected
              from loss, misuse and unauthorised access, modification or
              disclosure, security measures over the internet can never be
              guaranteed. We encourage you to play an important role in keeping
              your personal information secure, by maintaining the
              confidentiality of any passwords and account details used on our
              website.
            </p>
          </li>
          <li>
            <b className="pp-header">
              Accessing or correcting your personal information
            </b>
            <p className="pp-body">
              If you would like to access your personal information, please
              contact us using the details below. In certain circumstances, we
              may not be able to give you access to your personal information,
              in which case we will write to you to explain why we cannot comply
              with your request. We try to ensure any personal information we
              hold about you is accurate, up-to-date, complete and relevant. If
              you believe the personal information we hold about you should be
              updated, please contact us using the details below and we will
              take reasonable steps to ensure it is corrected if appropriate.
            </p>
          </li>
          <li>
            <b className="pp-header">
              Destroying or de-identifying personal information
            </b>
            <p className="pp-body">
              We destroy or de-identify personal and sensitive information when
              we no longer need it unless we are otherwise required or
              authorised by law to retain the information.
            </p>
          </li>
          <li>
            <b className="pp-header">Making a complaint</b>
            <p className="pp-body">
              If you believe your privacy has been breached or you have a
              complaint about our handling of your personal information, please
              contact us using the details below. We take privacy complaints
              seriously. If you make a complaint, we will respond within 5 days
              to acknowledge your complaint. We will try to resolve your
              complaint within 30 days. When this is not reasonably possible, we
              will contact you within that time to let you know how long we will
              take to resolve your complaint. We will investigate your complaint
              and write to you to explain our decision as soon as practicable.
            </p>
          </li>
          <li>
            <b className="pp-header">Changes</b>
            <p className="pp-body">
              We may, from time to time, amend this Policy. We will notify you
              of any changes to this Policy and any changes to this Policy will
              be effective immediately upon the posting of the revised Policy on
              our website. By continuing to use the services following any
              changes, you will be deemed to have agreed to such changes.
            </p>
          </li>
          <li>
            <b className="pp-header">Contact us</b>
            <p className="pp-body">
              All questions or queries about this Policy and complaints should
              be directed to: Privacy Officer Email: contact@medoc.life This
              Policy was last updated in October 2022.
            </p>
          </li>
        </ol>
      </div>
      <StaticFooterComponent />
    </>
  );
};

export default PrivacyPolicyComponent;
