export const Testimonials = [
  {
    description: "The service is very good 🙌",
    name: "Sabari S",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "27/12/2022",
  },
  {
    description:
      "Extra ordinary.  I received my certificate within  4-5 minutes.",
    name: "Faiz Saifi",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "27/12/2022",
  },
  {
    description: "Superb experience 🫶",
    name: "Amit Singh",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "27/12/2022",
  },
  {
    description: "Fast and convienient services 🚀",
    name: "Dhruv Acharya",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "27/12/2022",
  },
  {
    description: "Best service. Fully satisfied 👌👌",
    name: "Pranali Kadam",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "27/12/2022",
  },
  {
    description: "Thank you. Amazing and fast service!",
    name: "Lokesh Kumar Ranjan",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "17/12/2022",
  },
  {
    description:
      "It was a speedy process and got the certificate from doctor buddy in 10 min! 👍",
    name: "Parth Khurana",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "25/12/2022",
  },
  {
    description: "Received sick leave certificate in 30 minutes. Very Fast ✨",
    name: "Kalyan Shetty",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "15/12/2022",
  },
  {
    description: "Great experience. Will definitely recommend Medoc",
    name: "Unni Thomas",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "14/12/2022",
  },
  {
    description: "Thanks for timely help. Affordable cost. ❤️",
    name: "Vishal Kumar",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "11/12/2022",
  },
  {
    description: "Very nice. Super fast service!",
    name: "Nitesh Singh",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "08/12/2022",
  },
  {
    description: "Thank you so much for this help 😊",
    name: "Vineet Khandelwal",
    image:
      "https://storage.googleapis.com/medoc-live.appspot.com/images/home_banner_5.png",
    date: "07/12/2022",
  },
];
