import React, { useState } from "react";
import { toast } from "react-toastify";
import "./style.css";

import { add } from "firebase_config";
import { post } from "services";
import { Tables } from "constants/index";

const DoctorOnboardComponent = ({ smallDevice }) => {
  const [loading, setLoading] = useState(false);
  const [employer, setEmployer] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const handleChange = (key, value) => {
    setEmployer({
      ...employer,
      [key]: value,
    });
  };

  const submitRrequest = async () => {
    if (!employer.name) {
      toast.error("Full name is required");
      return;
    } else if (!employer.email) {
      toast.error("Email is required");
      return;
    } else if (!employer.mobile) {
      toast.error("Mobile is required");
      return;
    } else if (!employer.qualification) {
      toast.error("Qualification is required");
      return;
    } else if (!employer.number) {
      toast.error("IMC number is required");
      return;
    } else if (!employer.state) {
      toast.error("Registered state is required");
      return;
    }
    setLoading(true);
    const res = await add(Tables.DOCTOR_REQUESTS, employer);
    if (res.status === 200) {
      toast.success(
        "We sent next steps to your email. Check your Inbox/Spam folder!"
      );
      if (process.env.NODE_ENV === "development") {
        await post("/send_email", {
          sender: "naga",
          templateName: "virtual_clinic_sign_up.html",
          email: employer.email,
          subject:
            "Invitation to join Medoc Virtual Clinic: Treat Patients digitally across India.",
        });
      }
      setEmployer("");
    }
    setLoading(false);
  };

  return (
    <div id="doctor-onboard">
      <div className="container pt-3 py-5 my-sm-5">
        <div className="row">
          <div className="col-12 col-sm-6">
            <h2>Open your virtual clinic today!</h2>
            <p className="pt-3">
              You can set up virtual clinic within a day. We'd love to work with
              certified M.B.B.S professionals and private clinicans across
              India. <br /> <br />
              We assist you in receiving consultations anywhere in India. <br />{" "}
              <br />
              Contact us for more information{" "}
              <a href="mailto:contact@medoc.life" className="text-violet">
                contact@medoc.life
              </a>
            </p>
          </div>
          {employer ? (
            <div className="col-12 col-sm-6 pt-3 pt-sm-0">
              <div className="">
                <label className="text-violet fw-500">
                  Full name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Full name"
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </div>
              <div className="">
                <label className="text-violet mt-3 fw-500">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Email"
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </div>
              <div className="">
                <label className="text-violet mt-3 fw-500">
                  Mobile <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Mobile"
                  onChange={(e) => handleChange("mobile", e.target.value)}
                />
              </div>
              <div className="">
                <label className="text-violet fw-500 mt-3">
                  Qualification(s) <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Qualification(s)"
                  onChange={(e) =>
                    handleChange("qualification", e.target.value)
                  }
                />
              </div>
              <div className="">
                <label className="text-violet fw-500 mt-3">
                  Indian Medical Council Registered Number{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="IMC Number"
                  onChange={(e) => handleChange("number", e.target.value)}
                />
              </div>
              <div className="">
                <label className="text-violet fw-500 mt-3">
                  Registered State <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Registered State"
                  onChange={(e) => handleChange("state", e.target.value)}
                />
              </div>
              <div className="">
                <label className="text-violet mt-3 fw-500">
                  Any other info you'd like to add{" "}
                </label>
                <textarea
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Your private clinic details, Linkedin profile, or any"
                  onChange={(e) => handleChange("message", e.target.value)}
                />
              </div>
              <div
                className="btn btn-violet-rounded mt-4 btn-sm p-2 px-3"
                onClick={submitRrequest}
              >
                {loading ? "Submitting..." : "Get Next Steps!"}
              </div>
            </div>
          ) : (
            <div className="col-12 col-sm-6 pt-3 pt-sm-0 text-center text-violet fw-500">
              We sent next steps to your email. Check your Inbox/Spam folder!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DoctorOnboardComponent;
