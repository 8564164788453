import React, { useState } from "react";
import { toast } from "react-toastify";

import { add } from "firebase_config";
import { Tables } from "constants/index";

const ScheduleDemoComponent = ({ smallDevice }) => {
  const [loading, setLoading] = useState(false);
  const [employer, setEmployer] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const handleChange = (key, value) => {
    setEmployer({
      ...employer,
      [key]: value,
    });
  };

  const submitRrequest = async () => {
    console.log("asfda", employer);
    if (!employer.name) {
      toast.error("Full name is required");
      return;
    } else if (!employer.orgName) {
      toast.error("Organization Name is required");
      return;
    } else if (!employer.email) {
      toast.error("Email is required");
      return;
    } else if (employer.email && employer.email.includes("gmail")) {
      toast.error("Company email is required");
      return;
    } else if (!employer.mobile) {
      toast.error("Mobile is required");
      return;
    }
    setLoading(true);
    const res = await add(Tables.ORGANIZATION_REQUESTS, employer);
    if (res.status === 200) {
      toast.success(
        "We received your demo request. Our team will contact you in next 24 hours."
      );
      setEmployer("");
    }
    setLoading(false);
  };

  return (
    <div id="doctor-onboard">
      <div className="container pt-3 py-5 my-sm-5">
        <div className="row">
          <div className="col-12 col-sm-6">
            <h2>Manage Your Workplace Health with ease!</h2>
            <p className="pt-3">
              Our services include sick leaves & fitness tracker, mental health
              solutions, doctor consultations, self-care tools, AI-based symptom
              tracker & more. <br /> <br />
              Contact us for more information{" "}
              <a href="mailto:contact@medoc.life" className="text-violet">
                contact@medoc.life
              </a>
            </p>
          </div>
          {employer ? (
            <div className="col-12 col-sm-6 pt-3 pt-sm-0">
              <div className="">
                <label className="text-violet fw-500">
                  Full name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Full name"
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </div>
              <div className="">
                <label className="text-violet mt-3 fw-500">
                  Organization Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Organization Name"
                  onChange={(e) => handleChange("orgName", e.target.value)}
                />
              </div>
              <div className="">
                <label className="text-violet mt-3 fw-500">
                  Official Email ID <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Official Email ID"
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </div>
              <div className="">
                <label className="text-violet mt-3 fw-500">
                  Contact Number <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Contact Number"
                  onChange={(e) => handleChange("mobile", e.target.value)}
                />
              </div>
              <div
                className="btn btn-violet-rounded mt-4 btn-sm p-2 px-3"
                onClick={submitRrequest}
              >
                {loading ? "Submitting..." : "Schedule a demo"}
              </div>
            </div>
          ) : (
            <div className="col-12 col-sm-6 pt-3 pt-sm-0 text-center text-violet fw-500">
              We received your demo request. Our team will contact you in next
              24 hours.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleDemoComponent;
