import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.css";

import { StaticHeaderComponent, StaticFooterComponent } from "components";
import { Faqs, ConsultationFaqs } from "constants/index";

const FaqComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Medoc | Digital Healthcare FAQs | Get Your Health-Related Questions
          Answered in India
        </title>
        <link rel="canonical" href="https://medoc.life/faq" />
        <meta
          name="description"
          content="Get answers to your health-related questions with our digital healthcare FAQ page in India. Our platform provides reliable and up-to-date information."
        />
        <meta
          name="keywords"
          content="digital healthcare,India,FAQ,questions,answers,health platform"
        />
        <meta
          property="og:title"
          content="Medoc | Digital Healthcare FAQs | Get Your Health-Related Questions Answered in India"
        />
        <meta
          property="og:description"
          content="Get answers to your health-related questions with our digital healthcare FAQ page in India. Our platform provides reliable and up-to-date information."
        />
        <meta property="og:url" content="https://medoc.life/faq" />
        <meta property="og:site_name" content="medoc.life" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/razorpay_logo.jpeg"
        />
        <meta property="og:image:width" content="590" />
        <meta property="og:image:height" content="615" />
        <meta
          property="article:modified_time"
          content="2023-2-2T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medoc | Digital Healthcare FAQs | Get Your Health-Related Questions Answered in India"
        />
        <meta
          name="twitter:description"
          content="Get answers to your health-related questions with our digital healthcare FAQ page in India. Our platform provides reliable and up-to-date information."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="3 minutes" />
      </Helmet>

      <StaticHeaderComponent />
      <div className="dashboard-content faq-wrapper">
        <h2 className="fw-300 text-center pt-5">
          Online Medical Certificates FAQs
        </h2>
        <div className="mt-4">
          {Faqs.map((obj, objIdx) => (
            <div className="container" key={`${obj.key}_${objIdx}`}>
              <div className="col-12">
                <div className="">
                  {obj.values[0] &&
                    obj.values.map((que, queIdx) => (
                      <div key={queIdx}>
                        <input
                          type="checkbox"
                          id={`question_${objIdx}_${queIdx}`}
                          name="q"
                          className="questions"
                        />
                        <div>
                          <label
                            htmlFor={`question_${objIdx}_${queIdx}`}
                            className="d-flex flex-row align-items-center justify-content-between question"
                          >
                            <div>{que.question}</div>
                            <div className="plus">+</div>
                          </label>
                        </div>
                        <div
                          className="answers"
                          dangerouslySetInnerHTML={{ __html: que.answer }}
                        ></div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <h2 className="fw-300 text-center pt-5">
          Online Doctor Consultations FAQs
        </h2>
        <div className="mt-4">
          {ConsultationFaqs.map((obj, objIdx) => (
            <div className="container" key={`${obj.key}_${objIdx}`}>
              <div className="col-12">
                <div className="">
                  {obj.values[0] &&
                    obj.values.map((que, queIdx) => (
                      <div key={queIdx}>
                        <input
                          type="checkbox"
                          id={`cquestion_${objIdx}_${queIdx}`}
                          name="q"
                          className="questions"
                        />
                        <div>
                          <label
                            htmlFor={`cquestion_${objIdx}_${queIdx}`}
                            className="d-flex flex-row align-items-center justify-content-between question"
                          >
                            <div>{que.question}</div>
                            <div className="plus">+</div>
                          </label>
                        </div>
                        <div
                          className="answers"
                          dangerouslySetInnerHTML={{ __html: que.answer }}
                        ></div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <StaticFooterComponent />
    </>
  );
};

export default FaqComponent;
