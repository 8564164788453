import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Routes } from "constants/index";

const SectionOneComponent = ({ smallDevice }) => {
  return (
    <div className="section-one-wrapper fw-400">
      <div className="container d-flex flex-column flex-md-row align-items-center section-one-wrapper">
        <div className={`col-12 col-md-6 ${smallDevice && "py-24"}`}>
          <h1 className={`fw-600 pb-2`}>Medoc Global</h1>
          <ul className="list-group pt-sm-3 pb-4 fw-500">
            <li className="py-2">
              <i className="fa fa-check-circle"></i>&nbsp; Talk to an Indian
              doctor within 24 hours
            </li>
            <li className="py-2">
              <i className="fa fa-check-circle"></i>&nbsp; Get an instant
              medical advice and second opinion
            </li>
            <li className="py-2">
              <i className="fa fa-check-circle"></i>&nbsp; Treat yourself in a
              better care with affordable cost
            </li>
          </ul>
          <Link
            to={{
              pathname: Routes.LOGIN,
              state: { requestType: "fromAbroad" },
            }}
            className="btn btn-violet-rounded px-4 py-2 py-sm-3 fw-500"
          >
            Seek Advice
          </Link>
        </div>
        <div className="col-12 col-md-6">
          <img
            src="https://storage.googleapis.com/medoc-live.appspot.com/images/doctor4.png"
            alt="play_image"
            className="col-12"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionOneComponent;
