import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import BannerComponent from "./banner/component";
import PricingComponent from "./pricing/component";
import ScheduleDemoComponent from "./schedule_demo/component";
import { StaticHeaderComponent, StaticFooterComponent } from "components";
import "./style.css";

const CorporatesComponent = () => {
  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  return (
    <div className="business-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manage Employee Health | All at one place</title>
        <link rel="canonical" href="https://medoc.life/virtual-clinic" />
        <meta
          name="description"
          content="Easily manage your employee Sick Leaves & Fitness reports at one place with zero cost."
        />
        <meta
          property="og:title"
          content="Manage Employee Health - Sick Leaves & Fitness"
        />
        <meta
          property="og:description"
          content="Easily manage your employee Sick Leaves & Fitness reports at one place with zero cost."
        />
        <meta property="og:url" content="https://medoc.life/" />
        <meta property="og:site_name" content="medoc.life" />
        <meta
          property="article:modified_time"
          content="2023-12-10T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Manage Employee Health - Sick Leaves & Fitness"
        />
        <meta
          name="twitter:description"
          content="Easily manage your employee Sick Leaves & Fitness reports at one place with zero cost."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="5 minutes" />
      </Helmet>
      <StaticHeaderComponent smallDevice={smallDevice} bottomFooter="false" />
      <BannerComponent smallDevice={smallDevice} />
      <ScheduleDemoComponent smallDevice={smallDevice} />
      <PricingComponent smallDevice={smallDevice} />
      <StaticFooterComponent />
    </div>
  );
};

export default CorporatesComponent;
