import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import SectionOneComponent from "./section_one/component";
import SectionOneTwoComponent from "./section_one_two/component";
import SectionTwoComponent from "./section_two/component";
import SectionFourComponent from "./section_four/component";
import DoctorConsultationComponent from "./doctor_consultation/component";
import BlogsComponent from "./blogs/component";
import {
  StaticHeaderComponent,
  StaticFooterComponent,
  SelfCareComponent,
  NumbersComponent,
} from "components";
import "./style.css";

import { Routes } from "constants/index";

const HomeComponent = () => {
  const [smallDevice, setSmallDevice] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  useEffect(() => {
    const token = window.sessionStorage.getItem("token");
    if (!token) {
      history.push(Routes.DEFAULT);
    } else {
      history.push(Routes.APP_POSTS);
    }
  }, []);

  return (
    <div className="home-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Medoc | Medical Certificates Online Across India</title>
        <link rel="canonical" href="https://medoc.life" />
        <meta
          name="description"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation. Price starts at 250 INR/-"
        />
        <meta
          property="og:title"
          content="Medoc - Online Medical Certificate Provider Across India"
        />
        <meta
          property="og:description"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation."
        />
        <meta property="og:url" content="https://medoc.life/" />
        <meta
          property="og:site_name"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation."
        />
        <meta
          property="article:modified_time"
          content="2022-12-10T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medical Certificate Online In Minutes By Certified Indian Doctors"
        />
        <meta
          name="twitter:description"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="5 minutes" />
      </Helmet>
      <StaticHeaderComponent smallDevice={smallDevice} />
      <SectionOneComponent smallDevice={smallDevice} />
      <SectionOneTwoComponent smallDevice={smallDevice} />
      <NumbersComponent smallDevice={smallDevice} />
      <SelfCareComponent smallDevice={smallDevice} />
      <DoctorConsultationComponent smallDevice={smallDevice} />
      <SectionTwoComponent smallDevice={smallDevice} />
      <BlogsComponent smallDevice={smallDevice} />
      <SectionFourComponent smallDevice={smallDevice} />
      <StaticFooterComponent />
    </div>
  );
};

export default HomeComponent;
