import React from "react";
import { Helmet } from "react-helmet";
import "./style.css";

import { StaticHeaderComponent, StaticFooterComponent } from "components";
import AvailableLocationsComponent from "./available-locations";
import SearchBoxComponent from "./search-box";

const SearchComponent = (props) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Medoc Search</title>
        <meta
          name="description"
          content="Find online medical certificate doctor near you. Price starts at 250 INR/-"
        />
        <link rel="canonical" href="https://medoc.life/medical-certificate" />
        <meta
          name="keywords"
          content="medical certificate,doctor medical certificate,medical report,online consultation"
        />
        <meta
          property="og:title"
          content="Medical Certificate Online In Minutes By Certified Indian Doctors"
        />
        <meta
          property="og:description"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation."
        />
        <meta
          property="og:url"
          content="https://medoc.life/medical-certificate"
        />
        <meta
          property="og:site_name"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation."
        />
        <meta
          property="article:modified_time"
          content="2022-12-21T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medical Certificate Online In Minutes By Certified Indian Doctors"
        />
        <meta
          name="twitter:description"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="3 minutes" />
      </Helmet>

      <StaticHeaderComponent />
      <div className="container pt-5 mt-5">
        <h1 className="h4 text-center fw-300 mt-5">
          We operate across 4000+ cities and 100,000+ villages in India
        </h1>
        <SearchBoxComponent />
      </div>
      <AvailableLocationsComponent />
      <StaticFooterComponent />
    </>
  );
};

export default SearchComponent;
