import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Metadata, Routes } from "constants/index";
import "./style.css";

const StaticHeaderComponent = ({ routes, bottomFooter }) => {
  const [metaDetails, setMetaDetails] = useState({});
  useEffect(() => {
    const path = window.location.pathname;
    Object.keys(Metadata).map((key) => {
      if (key.includes(path)) {
        setMetaDetails(Metadata[key]);
      }
      return key;
    });
  }, [metaDetails]);

  return (
    <div className="static-header">
      <Helmet>
        <title>Medoc Global - Chat with Indian Doctors From Abroad</title>
        <meta
          name="keywords"
          content="medical advice,online medical consultation,indian doctors,sick leave"
        />
        <meta
          name="description"
          content="Medoc Global is an online healthcare app that provides medical advice by certified Indian doctors. We help patients from UK, USA, Canada, Australia, Europe."
        />
        <meta
          property="og:title"
          content="Medoc Global - Chat with Indian Doctors From Abroad"
        />
        <meta
          property="og:description"
          content="Medoc Global is an online healthcare app that provides medical advice by certified Indian doctors. We help patients from UK, USA, Canada, Australia, Europe."
        />
        <meta property="og:url" content="https://medoc.life/" />
        <meta
          property="og:site_name"
          content="Medoc.life is an online medical certificate app that provides 100% authentic medical certificates with free doctor consultation."
        />
        <meta
          name="twitter:title"
          content="Medoc Global - Chat with Indian Doctors From Abroad"
        />
        <meta
          name="twitter:description"
          content="Medoc Global is an online healthcare app that provides medical advice by certified Indian doctors. We help patients from UK, USA, Canada, Australia, Europe."
        />
      </Helmet>
      <Link
        to={Routes.LOGIN}
        className={
          bottomFooter === "false" ? "d-none" : "add-on-text fixed-bottom"
        }
      >
        <small>&#128994;</small> Doctor Buddies are online
      </Link>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="home-page-title fw-600">
              medoc
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto align-items-center">
              <li className="nav-item">
                <div className="nav-link fw-500">
                  <Link to={Routes.ABOUT_US} className="text-violet">
                    About Us
                  </Link>
                </div>
              </li>
              <li className="nav-item px-3 d-none">
                <div className="nav-link fw-500">
                  <Link to={Routes.LOGIN} className="text-violet">
                    Blog
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  <a
                    href={Routes.LOGIN}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-violet py-2 px-4"
                  >
                    Seek Advice
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default StaticHeaderComponent;
