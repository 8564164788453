import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./style.css";

import { StaticHeaderComponent, StaticFooterComponent } from "components";
import { Routes } from "constants/index";

const AboutUsComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Medoc | About Us | Discover Our Mission and Vision on Our Online
          Healthcare Platform in India
        </title>
        <link rel="canonical" href="https://medoc.life/about-us" />
        <meta
          name="description"
          content="Get to know our online medical certificates platform in India. Learn about our mission, vision, and company information on our About Us page."
        />
        <meta
          name="keywords"
          content="medical certificate online,doctor consultation,digital healthcare,India,about us,company information,mission,vision"
        />
        <meta
          property="og:title"
          content="Medoc | About Us | Discover Our Mission and Vision on Our Online Medical Certificates Platform in India"
        />
        <meta
          property="og:description"
          content="Get to know our online medical certificates platform in India. Learn about our mission, vision, and company information on our About Us page."
        />
        <meta property="og:url" content="https://medoc.life/about-us" />
        <meta property="og:site_name" content="medoc.life" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/razorpay_logo.jpeg"
        />
        <meta property="og:image:width" content="590" />
        <meta property="og:image:height" content="615" />
        <meta
          property="article:modified_time"
          content="2023-2-2T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medoc | About Us | Discover Our Mission and Vision on Our Online Medical Certificates Platform in India"
        />
        <meta
          name="twitter:description"
          content="Get to know our online medical certificates platform in India. Learn about our mission, vision, and company information on our About Us page."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="15 minutes" />
      </Helmet>

      <StaticHeaderComponent />
      <div className="container about-us-wrapper">
        <h3 className="page-title">About Us</h3>
        <div>
          <Link to={Routes.DEFAULT} className="fw-500">
            Medoc.life
          </Link>{" "}
          is a healthtech startup company that provides Aritifical Intelligence
          powered self care, doctor consultations and medical certificates
          within 60 minutes, anywhere in India. We also provide guided home-made
          doctor consultations from licenced Indian doctors. Our headquarters
          are in Vijayawada, Andhra Pradesh, India.
        </div>

        <p className="text-center d-none">
          Recognised by
          <a
            href="https://www.startupindia.gov.in/content/sih/en/profile.Startup.63b5719de4b0efec669fac7a.html"
            target="_blank"
            rel="noopener noreferrer"
            className="text-violet fw-600"
            title="Trademark is solely owned by DIPP"
          >
            <img
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/startup_india_2.png"
              alt="Trademark is solely owned by DIPP"
              className="si-logo"
            />
          </a>
          <a
            href="https://www.linkedin.com/posts/srmecell_srm-srmuniversity-srmist-activity-7024002290705469440-gXkx?utm_source=share&utm_medium=member_desktop"
            target="_blank"
            rel="noopener noreferrer"
            className="text-violet fw-600 d-none d-sm-inline"
          >
            <img
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/hatchlab_logo.webp"
              alt="Medoc-Hatchlabe"
              className="hl-logo"
            />
          </a>
        </p>
        <p className="pb-2 d-sm-none text-center">
          Incubator of
          <a
            href="https://www.linkedin.com/posts/srmecell_srm-srmuniversity-srmist-activity-7024002290705469440-gXkx?utm_source=share&utm_medium=member_desktop"
            target="_blank"
            rel="noopener noreferrer"
            className="text-violet fw-600 text-center"
            title="Trademark is solely owned by DIPP"
          >
            <img
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/hatchlab_logo.webp"
              alt="Trademark is solely owned by DIPP"
              className="hl-logo pl-2"
            />
          </a>
        </p>
        <ul className="mt-3">
          <li>
            <b className="pp-header">Our mission</b>
            <p className="pp-body">
              We're on a mission to make digital health in India more
              affordable, faster, and dependable. With our revolutionary AI
              powered doctor and patient portals, we bridge the gap between
              doctors and patients. Our specialists treat therapies ranging from
              simple to sophisticated. Furthermore, we would like to make
              medical treatments available in the safety of one's own home.
            </p>
          </li>
          <li>
            <b className="pp-header">Our Vision</b>
            <p className="pp-body">
              <ul>
                <li>
                  We want to revolutionize the India's healthcare system by
                  implementing simple solutions with advanced technologies.
                </li>
              </ul>
            </p>
          </li>
          <li className="d-none">
            <b className="pp-header">Our team</b>
            <p className="pp-body">
              Medoc.life is <b>Made In India</b> product. Built by Indians for
              Indians with exceptional skillset.
            </p>
          </li>
        </ul>
        <hr />
        <div className="text-center">
          <div className="pt-5 pt-sm-3">
            <img
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/nmc_digital_india.png"
              className="img-nmc-logo img-responsive"
              alt="Medoc NMC digital india"
            />{" "}
          </div>
          <div className="fs-12 mt-1">
            Every certificate adheres to guidelines outlined by{" "}
            <a
              href="https://www.nmc.org.in/nmc-act/"
              target="_blank"
              rel="nooopener noreferrer"
              className="text-violet text-underline"
            >
              The National Medical Commission Act, 2019
            </a>
          </div>
          <a
            href={Routes.LOGIN}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-violet-rounded px-3 mt-4"
          >
            {" "}
            Get Certificate Now{" "}
          </a>
        </div>
      </div>
      <StaticFooterComponent />
    </>
  );
};

export default AboutUsComponent;
