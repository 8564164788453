import React, { useState, useEffect } from "react";

import { getQuery, firestore } from "firebase_config";
import { Tables } from "constants/index";

import { StaticHeaderTwoComponent, StaticFooterComponent } from "components";

const DoctorProfileSuccessComponent = (props) => {
  const username = props.match.params.username;
  const [profile, setProfile] = useState();

  useEffect(() => {
    async function getProfile() {
      const pro = await getQuery(
        firestore
          .collection(Tables.DC_PUBLIC_PROFILE)
          .where("username", "==", username)
          .get()
      );
      if (pro[0]) {
        setProfile(pro[0]);
      }
    }

    if (!profile) {
      getProfile();
    }
  });

  return (
    <>
      <StaticHeaderTwoComponent />
      {profile && (
        <>
          <div className="container py-80 mt-5 text-center">
            <i className="fa fa-check-circle fa-3x text-success" /> <br />
            <p className="mt-2">
              We have forwarded your request to Dr. {profile.name}. Please check
              your email for all the necessary information.
            </p>
            <p>
              If you've any queries, mail us{" "}
              <a
                href="mailto:contact@medoc.life"
                className="text-violet fw-500"
              >
                contact@medoc.life
              </a>
            </p>
          </div>
        </>
      )}
      <StaticFooterComponent />
    </>
  );
};

export default DoctorProfileSuccessComponent;
