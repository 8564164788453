import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import BannerComponent from "./banner/component";
import OfferingsComponent from "./offerings/component";
import PricingComponent from "./pricing/component";
import {
  StaticHeaderTwoComponent,
  StaticFooterComponent,
  DoctorOnboardComponent,
  ClinicsComponent,
} from "components";
import "./style.css";

const VirtualClinicComponent = () => {
  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  return (
    <div className="home-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Virtual Clinic | Open a medical clinic in minutes</title>
        <link rel="canonical" href="https://medoc.life/virtual-clinic" />
        <meta
          name="description"
          content="Open a medical clinic digitally and start taking consultations within a day."
        />
        <meta
          property="og:title"
          content="Medoc Virtual Clinic - Open Medical Clinic Within a Day"
        />
        <meta
          property="og:description"
          content="Open a medical clinic digitally and start taking consultations within a day."
        />
        <meta property="og:url" content="https://medoc.life/" />
        <meta property="og:site_name" content="medoc.life" />
        <meta
          property="article:modified_time"
          content="2023-12-10T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medoc Virtual Clinic - Open Medical Clinic Within a Day"
        />
        <meta
          name="twitter:description"
          content="Open a medical clinic digitally and start taking consultations within a day."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="5 minutes" />
      </Helmet>
      <StaticHeaderTwoComponent smallDevice={smallDevice} />
      <BannerComponent smallDevice={smallDevice} />
      <OfferingsComponent smallDevice={smallDevice} />
      <ClinicsComponent smallDevice={smallDevice} />
      <PricingComponent smallDevice={smallDevice} />
      <DoctorOnboardComponent smallDevice={smallDevice} />
      <StaticFooterComponent />
    </div>
  );
};

export default VirtualClinicComponent;
