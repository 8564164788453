import React from "react";

const PricingComponent = ({ smallDevice }) => {
  return (
    <div className="pricing-wrapper container">
      <h2 className="text-center fw-600">Pricing</h2>
      <p className="text-center fs-20 pb-4">
        Start your dream virtual clinic at an affordable cost. Get complete
        control of patients health into your hands.
      </p>
      <div className="row">
        <div className="col-12 col-sm-6 mb-4 mb-sm-0">
          <div className="card p-4 p-sm-5">
            <h3 className="text-center py-4">
              Only <br /> 10% per appointment
            </h3>
            <p className="text-center pb-4">
              No upfront fees. Flat 10% commission per appointment + any taxes.
              We charge a small commission on your earnings so that we earn only
              when you earn
            </p>
            <h6 className="btn btn-violet-rounded btn-sm py-2 text-center">
              Before July 01, 2023
            </h6>
          </div>
        </div>
        <div className="col-12 col-sm-6 my-3 my-sm-0">
          <div className="card p-4 p-sm-5">
            <h3 className="text-center py-4">
              199 INR/- per month + <br /> 15% per appointment
            </h3>
            <p className="text-center pb-4">
              199 INR/- per month. Plus flat 15% commission per appointment +
              any taxes. We charge a small commission on your earnings so that
              we earn only when you earn
            </p>
            <h6 className="btn btn-violet-rounded btn-sm py-2 text-center">
              From July 01, 2023
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
