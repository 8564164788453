import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Routes, Certs } from "constants/index";

const SectionTwoComponent = ({ smallDevice }) => {
  return (
    <div id="section-one-two" className="section-one-two-wrapper container">
      <h2 className="text-center text-secondary my-5 pt-5 py-sm-0 fw-600 h6">
        Our offerings
      </h2>
      <div className="card-product py-3 px-sm-5">
        {Certs.map(
          (t, i) =>
            t.display && (
              <>
                <div
                  className="row text-center text-sm-left"
                  key={i}
                  data-aos="zoom-in-down"
                  data-aos-duration="1500"
                >
                  <div className="col-12 col-sm-5 fw-600 pt-1 pl-sm-4">
                    {t.name}
                  </div>
                  <div className="col-12 col-sm-3 fs-13 pt-1">
                    Starts at {t.priceStartsAt} {t.currency}*
                  </div>
                  <div className="col-12 col-sm-2">
                    <a
                      href={Routes.LOGIN}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-violet-rounded text-violet font-small col-8 my-3 my-sm-0"
                    >
                      Buy Now
                    </a>
                  </div>
                  <div className="col-12 col-sm-2">
                    <Link
                      to={t.blogLink}
                      className="btn btn-violet-outline-r text-violet font-small col-8"
                    >
                      Details
                    </Link>
                  </div>
                </div>
                {i !== Certs.length - 1 && <hr />}
              </>
            )
        )}
        <div className="text-center mt-4 mb-3">
          <Link
            to={Routes.MEDICAL_CERTIFICATE}
            className="btn btn-violet-outline-r py-2 px-5"
          >
            See All Certificates
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SectionTwoComponent;
