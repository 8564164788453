import React from "react";
import { Link } from "react-router-dom";

import { Routes } from "constants/index";

const SectionThreeComponent = ({ smallDevice }) => {
  return (
    <div id="section-three" className="section-three-wrapper container">
      <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white">
        <h2 className="text-center">Trusted by 100+ companies.</h2>
        <p>
          Ensure employee health & fitness at <b>Zero Cost</b>
        </p>
        <Link
          to={Routes.PLUS_CORPORATES}
          className="btn btn-violet-outline-r text-white col-12 col-sm-3 px-4 fw-500 mt-3 py-2"
        >
          Medoc For Business
        </Link>
      </div>
    </div>
  );
};

export default SectionThreeComponent;
