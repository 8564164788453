import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "./style.css";

import { StaticHeaderComponent } from "components";
import { add } from "firebase_config";
import { Routes, Tables } from "constants/index";

const VerifyCertificateComponent = () => {
  const [smallDevice, setSmallDevice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employer, setEmployer] = useState({
    name: "",
    email: "",
    requestId: "",
  });

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  const handleChange = (key, value) => {
    setEmployer({
      ...employer,
      [key]: value,
    });
  };

  const verifyCertificate = async () => {
    if (!employer.name) {
      toast.error("Organization name is required");
      return;
    } else if (!employer.email) {
      toast.error("Organization email is required");
      return;
    } else if (!employer.requestId) {
      toast.error("Cert ID is required");
      return;
    }
    setLoading(true);
    const res = await add(Tables.ORGANIZATION_REQUESTS, employer);
    if (res.status === 200) {
      toast.success(
        "Thank You. Our team will reach out to you shortly on email."
      );
      setEmployer("");
    }
    console.log("res", res);
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Medoc | Verify Certificate | Ensure the Authenticity of Your Online
          Medical Certificate on Our Platform in India
        </title>
        <link rel="canonical" href="https://medoc.life/employer-verify" />
        <meta
          name="description"
          content="Verify the authenticity of your online medical certificate on our platform in India. Our verify certificate page provides easy and secure validation."
        />
        <meta
          name="keywords"
          content="medical certificate online,doctor consultation,digital healthcare,India,verify certificate,authenticity,validation"
        />
        <meta
          property="og:title"
          content="Medoc | Verify Certificate | Ensure the Authenticity of Your Online Medical Certificate on Our Platform in India"
        />
        <meta
          property="og:description"
          content="Verify the authenticity of your online medical certificate on our platform in India. Our verify certificate page provides easy and secure validation."
        />
        <meta property="og:url" content="https://medoc.life/employer-verify" />
        <meta property="og:site_name" content="medoc.life" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/razorpay_logo.jpeg"
        />
        <meta property="og:image:width" content="590" />
        <meta property="og:image:height" content="615" />
        <meta
          property="article:modified_time"
          content="2023-2-2T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medoc | Verify Certificate | Ensure the Authenticity of Your Online Medical Certificate on Our Platform in India"
        />
        <meta
          name="twitter:description"
          content="Verify the authenticity of your online medical certificate on our platform in India. Our verify certificate page provides easy and secure validation."
        />
      </Helmet>

      <div className="home-wrapper">
        <StaticHeaderComponent smallDevice={smallDevice} />
        <div className="container pt-3 pt-sm-5 mt-sm-5">
          <div className="row pt-5 mt-5">
            <div className="col-12 col-sm-6">
              <h2>Check the validity of the certificate!</h2>
              <p className="pt-3">
                We are unable to disclose all of the details that were discussed
                due to doctor-patient confidentiality, however our team will
                verify the information if you have any queries.
              </p>
              <p className="pt-3">
                For any queries, email us to{" "}
                <a href="mailto:contact@medoc.life" className="text-violet">
                  contact@medoc.life
                </a>{" "}
                or{" "}
                <Link to={Routes.DEFAULT} className="fw-500 text-violet">
                  Get Your Certificate Here
                </Link>
              </p>
            </div>
            <div className="col-12 col-sm-6 pt-3 pt-sm-0">
              <div className="">
                <label className="text-violet fw-500">
                  Organization name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Organization name"
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </div>
              <div className="">
                <label className="text-violet mt-3 fw-500">
                  Organization email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Organization email"
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </div>
              <div className="">
                <label className="text-violet mt-3 fw-500">
                  Cert ID <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control font-small"
                  name="form-question"
                  placeholder="Cert ID"
                  onChange={(e) => handleChange("requestId", e.target.value)}
                />
                <small className="text-secondary fs-10">
                  You can get Cert ID from certificate
                </small>
              </div>
              <div
                className="btn btn-violet mt-4 btn-sm"
                onClick={verifyCertificate}
              >
                Verify Certificate
              </div>{" "}
              {loading && <div className="spinner-border"></div>}
            </div>
          </div>
          <object
            data="http://africau.edu/images/default/sample.pdf"
            type="application/pdf"
            width="100%"
            height="100%"
            className="d-none"
          >
            <p>
              Alternative text - include a link{" "}
              <a href="http://africau.edu/images/default/sample.pdf">
                to the PDF!
              </a>
            </p>
          </object>
        </div>
      </div>
    </>
  );
};

export default VerifyCertificateComponent;
