const blog = {
  name: "form-1a-certificate",
  category: "",
  banner_url:
    "https://storage.googleapis.com/medoc-live.appspot.com/images/step3_1.jpg",
  title: "Get Form 1A fitness certificate",
  date: "2 Feb 2023",
  meta_description:
    "One of the most crucial documents required is RTO Form 1A, also known as the Fitness Medical Certificate. This form attests to the medical fitness of the person applying for the driving license and must be submitted to the registering authority...",
  meta_keywords:
    "online medical certificate for driving licence,doctor certificate for driving licence,Form 1a certificate for driving licence",
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/medoc-offc/' target='_blank' style='color: #6930DB !important;'>Medoc.Life</a></div>`,
  body: `
  <div style="text-align: justify">
  <p>
  The process of obtaining a driving license in India involves several steps and the submission of certain documents. One of the most crucial documents required is RTO Form 1A, also known as the Fitness Medical Certificate. This form attests to the medical fitness of the person applying for the driving license and must be submitted to the registering authority. Without the submission of the RTO Form 1A, a driving license cannot be obtained in India.
  </p>

  <p>
  The medical fitness certificate can be obtained from the RTO office or the transport department's website. However, this process has become much easier with medoc.life In our platform, one can quickly fill out a medical form and receive an online RTO Form 1A attested by a certified government doctor in India.
  </p>
  <h2 class="h4">How to get a Medical Fitness Certificate for Driving Licence?</h2>
  <p>
    Get your Fitness Certificate for Driving Licence within 60 minutes along with doctor consultation, ear and eye tests.
    <ul>
      <li>Log on to <a href="https://app.medoc.life" class="text-violet fw-500">Medoc App</a></li>
      <li>Answer simple questions about yourself</li>
      <li>Take 15 mins online ear and eye test</li>
      <li>Get your Form 1A Fitness Certificate attested by a M.B.B.S doctor within 60 minutes.</li>
    </ul>
  </p>
  <br/><br/>
  </div>
  `,
};

export default blog;
