export const Metadata = {
  "/": {
    title: "Medoc | Medical Certificates Online | Only 149 INR",
    description:
      "Medoc is an easiest way to get medical certificates online. A telehealth platform that helps you enjoy confidential consultations with registered Indian doctors.",
    path: "home",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/home": {
    title: "Medoc | Medical Certificates Online | Only 149 INR",
    description:
      "Medoc is an easiest way to get medical certificates online. A telehealth platform that helps you enjoy confidential consultations with registered Indian doctors.",
    path: "home",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/posts": {
    title: "Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "posts",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/profile": {
    title: "Profile | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "profile",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/search": {
    title: "Search | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "search",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/similarities": {
    title: "Similarities | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "similarities",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/graphs": {
    title: "Graphs | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "graphs",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/alerts": {
    title: "Alerts | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "alerts",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/chats": {
    title: "Chats | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "chats",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/chat_requests": {
    title: "Requests | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "chat_requests",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/payments": {
    title: "Payments | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "payments",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/support": {
    title: "Support | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "support",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/create_activity": {
    title: "Share activities | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "create_activity",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/challenges": {
    title: "Challenges | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "challenges",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/send_company_alerts": {
    title: "Send company alerts | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "send_company_alerts",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/app/create_post": {
    title: "Create Request | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    path: "create_post",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/login": {
    title: "Login | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/signup": {
    title: "Signup | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/signup/:username": {
    title: "Signup | Medoc",
    description:
      "Medoc is an online health platform that helps you enjoy confidential consultations with registered Indian doctors. The easiest way to get a medical certificate.",
  },
  "/signup_success": {
    title: "Signup success | Medoc",
  },
  "/forgot_password": {
    title: "Forgot password | Medoc",
    description: "Forgot password in medoc application.",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/logout": {
    title: "Logout | Medoc",
    description: "Logged out.",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/profile_deleted": {
    title: "Profile deleted | Medoc",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  "/error": {
    title: "Medoc - Online Medical Certificates Store",
    description:
      "Medoc is an easiest way to get medical certificates online. A health platform that helps you enjoy confidential consultations with registered Indian doctors.",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
  default: {
    title: "Medoc - Online Medical Certificates Store",
    description:
      "Medoc is an easiest way to get medical certificates online. A health platform that helps you enjoy confidential consultations with registered Indian doctors.",
    keywords:
      "medical certificate,medical certificate for leave,online medical certificate,sick leave,online doctor consultation,free medical certificate",
  },
};
