const blog = {
  name: "caretaker-certificate",
  category: "",
  banner_url:
    "https://storage.googleapis.com/medoc-live.appspot.com/images/step1_1.jpg",
  title: "Get a caretaker certificate to care your loved ones.",
  date: "4 Jan 2023",
  meta_description:
    "A caretaker certificate is an important document to have. It serves as proof of your identity and your relationship to the person for whom you are providing care, and it can also help to establish your ability to care for them...",
  meta_keywords:
    "care taker,caretaker certificate,doctor certificate,medical certificate,certificate of doctor",
  author: `<div>Written by&nbsp;<a href='https://www.linkedin.com/company/medoc-offc/' target='_blank' style='color: #6930DB !important;'>Medoc.Life</a></div>`,
  body: `
  <div style="text-align: justify">
    <p>
    Whether you are caring for a child, an elderly person, or someone with a disability, a caretaker certificate can be an important document to have. It serves as proof of your identity and your relationship to the person for whom you are providing care, and it can also help to establish your ability to care for them.
    </p>

    <p>
    So, when do you need a caretaker certificate in India? Here are a few situations in which you might need one:
    </p>

    <p>
    If you are responsible for the care of a child: If you are a parent, guardian, or other caregiver for a child, you may need a caretaker certificate to enroll them in school, travel with them, or access certain benefits or services.
    </p>

    <p>
    If you are caring for an elderly person: If you are helping to take care of an elderly relative or friend, you may need a caretaker certificate to show that you are authorized to make decisions on their behalf or to access their financial or medical records.
    </p>

    <p>
    If you are caring for someone with a disability: If you are assisting someone with a disability to manage their daily needs, you may need a caretaker certificate to prove that you are their designated caregiver. This can be especially important if you are seeking financial assistance or other support for their care.
    </p>

    <p>
    So, how do you go about obtaining a caretaker certificate in Medoc? Here are the steps you will need to follow:
    </p>

    <p>
    Login to the application: <a href="https://app.medoc.life/login" target="_blank" rel="nooperator noreferrer">Login here</a>
    </p>

    <p>
    Fill a simple questionnaire:
      <ul>
        <li>Name</li>
        <li>Email</li>
        <li>Mobile</li>
        <li>Address</li>
        <li>Carer Name</li>
        <li>Carer Address</li>
      </ul>
    </p>

    <p>
    Submit your application: Once you have completed the questionnaire, you will need to submit your application for a caretaker certificate. This can typically be done within 10 minutes.
    </p>

    <p>
    Obtaining a caretaker certificate in India can be a bit of a process, but not with Medoc. You will receive certificate withing 60 minutes. And once you have it in hand, you can rest assured that you have the documentation you need to take care of the person in your charge.
    </p>
  </div>
  `,
};

export default blog;
