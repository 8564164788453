import React from "react";
import "./style.css";

const BlogsComponent = ({ smallDevice }) => {
  return (
    <div className="blogs container pt-4">
      <div className="align-items-center">
        <h2 className="text-center text-secondary my-5 fw-600 h6">
          News & Articles
        </h2>
        <div className="row justify-content-center align-items-center">
          <div className="my-3 col-12 col-sm-6">
            <div className="card card-blog p-4">
              <div className="text-violet">Medical Certificate</div>
              <p className="fs-12 text-justify pt-2">
                A Medical Certificate is a formal document issued by a medical
                physician or certified health professional attesting to the
                outcome of a patient's examination. This document is frequently
                utilized as a sick note, indicating that an employee or student
                is unable to perform their duties due to medical concerns.
                Additionally, Medical certificates may be required to access
                certain health benefits provided by employers. They are also
                often used by students as an excuse for absence from school or
                college. Medical Certificates are highly personal and private
                documents, thus it is imperative to safeguard patient's privacy.
              </p>
              <a
                href="https://blog.medoc.life"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="fs-12 text-violet text-underline"
              >
                Read More...
              </a>
            </div>
          </div>
          <div className="my-3 col-12 col-sm-6">
            <div className="card card-blog p-4">
              <div className="text-violet">
                Online Medical Certificate Consultation
              </div>
              <p className="fs-12 text-justify pt-2">
                It is imperative that before issuing a Medical Certificate, a
                doctor must be confident and fully understand the patient's
                condition. Medical practitioners must adhere to legal and
                professional guidelines and responsibilities while issuing
                certificates. Furthermore, the certificate must be truthful,
                succinct, and not misleading; a doctor should only sign and seal
                the certificate if they truly believe it to be an accurate
                diagnosis of the patient's condition.
                <br />
                With the approval of telemedicine by the Government of India and
                the Medical Council of India, Medical Certificates are now being
                issued online and must comply with specific formats for a doctor
                to attest.
              </p>
              <a
                href="https://blog.medoc.life"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="fs-12 text-violet text-underline"
              >
                Read More...
              </a>
            </div>
          </div>
          <div className="my-3 col-12 col-sm-6">
            <div className="card card-blog p-4">
              <div className="text-violet">
                Getting a medical leave certificate
              </div>
              <p className="fs-12 text-justify pt-2">
                We are a digital health platform that connects patients with
                certified Indian doctors for medical assistance, and also
                provides medical leave certificates within 60 minutes through a
                simple online process, allowing individuals to obtain the
                certificate from the convenience of their own home. The process
                is quick and simple.
              </p>
              <a
                href="https://blog.medoc.life"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="fs-12 text-violet text-underline"
              >
                Read More...
              </a>
            </div>
          </div>
          <div className="my-3 col-12 col-sm-6">
            <div className="card card-blog p-4">
              <div className="text-violet">
                Medical Certificate Format For Students And Employees
              </div>
              <p className="fs-12 text-justify pt-2">
                A valid medical certificate for students must include certain
                information and meet specific requirements. Unexpected health
                issues can arise at any time, including during important exam
                periods, and in these situations, students must provide proof of
                their absence to their school or university. The certificate
                should be issued by a healthcare provider and indicate that the
                student was unable to attend classes due to a medical condition.
              </p>
              <a
                href="https://blog.medoc.life"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="fs-12 text-violet text-underline"
              >
                Read More...
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsComponent;
