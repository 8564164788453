import React from "react";
import { Link } from "react-router-dom";

import { Routes } from "constants/index";
import "./style.css";

const StaticHeaderComponent = ({ routes, bottomFooter }) => {
  return (
    <div className="static-header">
      <a
        href={Routes.LOGIN}
        target="_blank"
        rel="noopener noreferrer"
        className={
          bottomFooter === "false" ? "d-none" : "add-on-text fixed-bottom"
        }
      >
        <small>&#128994;</small> Doctors are online
      </a>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container-fluid">
          <div className="navbar-brand">
            <Link to="/" className="home-page-title fw-600">
              medoc
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto align-items-center">
              <li className="nav-item fw-500 d-none">
                <div className="nav-link fs-14 px-3">
                  <a href={Routes.SEARCH} className="text-black">
                    Search
                  </a>
                </div>
              </li>
              <li className="nav-item fw-500">
                <div className="nav-link fs-14 px-3">
                  <a href={Routes.MEDICAL_CERTIFICATE} className="text-black">
                    Medical Certificates
                  </a>
                </div>
              </li>
              <li className="nav-item fw-500">
                <div className="nav-link fs-14 px-3">
                  <a href={Routes.DOCTOR_CONSULTATION} className="text-black">
                    Doctor Consultations
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link fs-14 px-3">
                  <a href={Routes.CONTACT_US} className="text-black">
                    Store <span className="badge badge-success">NEW</span>
                  </a>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto align-items-center">
              <li className="nav-item">
                <div className="nav-link fs-14 px-3">
                  <a href={Routes.PLUS_CORPORATES} className="text-black">
                    For Corporates{" "}
                    <span className="badge badge-success d-none">NEW</span>
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link fs-14 px-3">
                  <a href={Routes.VIRTUAL_CLINIC} className="text-black">
                    Virtual Clinic{" "}
                    <span className="badge badge-success d-none">NEW</span>
                  </a>
                </div>
              </li>
              <li className="nav-item d-none">
                <div className="nav-link fs-14 px-3">
                  <a href={Routes.BLOGS} className="text-black">
                    Blogs
                  </a>
                </div>
              </li>
              <li className="nav-item d-none">
                <div className="nav-link fs-14 px-3">
                  <a href={Routes.ABOUT_US} className="text-black">
                    About Us
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link fs-14 px-3">
                  <a href={Routes.CONTACT_US} className="text-black">
                    Help
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  <a
                    href={Routes.LOGIN}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-violet-rounded py-1 px-4 fs-14"
                  >
                    Buy Certificate
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="text-center mt-64">
        <div className="alert alert-violet">
          <a href={Routes.STORE} className="text-white"><b>Get 10% off in medoc store. <u>Shop Now!</u> 🎉 🎉</b></a>
        </div>
      </div>

    </div>
  );
};

export default StaticHeaderComponent;
