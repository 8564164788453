import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Modal from "react-modal";
import Routes from "routes/Routes";
import "./App.css";

import {
  CookieNotification,
  NoInternetNotification,
  LoadingComponent,
  ScrollIntoViewComponent,
} from "components";

// Toast for popups
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

export const AuthContext = React.createContext();

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showModal: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.handleOpenModal();
    }, 120000);
    AOS.init();
  }

  handleOpenModal() {
    this.setState({ showModal: false });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    return this.state.loading ? (
      <div>
        <LoadingComponent />
      </div>
    ) : (
      <div>
        <Modal
          isOpen={this.state.showModal}
          style={customStyles}
          onRequestClose={() => this.setState({ showModal: false })}
        >
          <div
            className="pull-right text-violet p-3 pointer"
            onClick={this.handleCloseModal}
          >
            Close [<i className="fa fa-times text-violet" />]
          </div>
          <img
            src="https://storage.googleapis.com/medoc-live.appspot.com/images/popups/referrer.webp"
            className="col-12 p-0"
          />
        </Modal>

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick={false}
          pauseOnHover
          progressClassName="toast-progress"
          bodyClassName="toast-body"
        />
        <Router>
          <CookieNotification />
          <NoInternetNotification />
          <ScrollIntoViewComponent>
            <Routes />
          </ScrollIntoViewComponent>
        </Router>
      </div>
    );
  }
}

export default App;
