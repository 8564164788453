export const Locations = [
  {
    name: "Andhra Pradesh",
    url: "locations/andhra-pradesh",
    type: "state",
  },
  {
    name: "Arunachal Pradesh",
    url: "locations/arunachal-pradesh",
    type: "state",
  },
  {
    name: "Assam",
    url: "locations/assam",
    type: "state",
  },
  {
    name: "Bihar",
    url: "locations/bihar",
    type: "state",
  },
  {
    name: "Chandigarh",
    url: "locations/chandigarh",
    type: "state",
  },
  {
    name: "Chhattisgarh",
    url: "locations/chhattisgarh",
    type: "state",
  },
  {
    name: "Delhi",
    url: "locations/delhi",
    type: "state",
  },
  {
    name: "Goa",
    url: "locations/goa",
    type: "state",
  },
  {
    name: "Gujarat",
    url: "locations/gujarat",
    type: "state",
  },
  {
    name: "Haryana",
    url: "locations/haryana",
    type: "state",
  },
  {
    name: "Himachal Pradesh",
    url: "locations/himachal-pradesh",
    type: "state",
  },
  {
    name: "Jammu and Kashmir",
    url: "locations/jammu-and-kashmir",
    type: "state",
  },
  {
    name: "Jharkhand",
    url: "locations/jharkhand",
    type: "state",
  },
  {
    name: "Karnataka",
    url: "locations/karnataka",
    type: "state",
  },
  {
    name: "Kerala",
    url: "locations/kerala",
    type: "state",
  },
  {
    name: "Madhya Pradesh",
    url: "locations/madhya-pradesh",
    type: "state",
  },
  {
    name: "Maharashtra",
    url: "locations/maharashtra",
    type: "state",
  },
  {
    name: "Manipur",
    url: "locations/manipur",
    type: "state",
  },
  {
    name: "Meghalaya",
    url: "locations/meghalaya",
    type: "state",
  },
  {
    name: "Mizoram",
    url: "locations/mizoram",
    type: "state",
  },
  {
    name: "Nagaland",
    url: "locations/nagaland",
    type: "state",
  },
  {
    name: "Odisha",
    url: "locations/odisha",
    type: "state",
  },
  {
    name: "Puducherry",
    url: "locations/puducherry",
    type: "state",
  },
  {
    name: "Punjab",
    url: "locations/punjab",
    type: "state",
  },
  {
    name: "Rajasthan",
    url: "locations/rajasthan",
    type: "state",
  },
  {
    name: "Sikkim",
    url: "locations/sikkim",
    type: "state",
  },
  {
    name: "Tamil Nadu",
    url: "locations/tamil-nadu",
    type: "state",
  },
  {
    name: "Telangana",
    url: "locations/telangana",
    type: "state",
  },
  {
    name: "Tripura",
    url: "locations/tripura",
    type: "state",
  },
  {
    name: "Uttar Pradesh",
    url: "locations/uttar-pradesh",
    type: "state",
  },
  {
    name: "Uttarakhand",
    url: "locations/uttarakhand",
    type: "state",
  },
  {
    name: "West Bengal",
    url: "locations/west-bengal",
    type: "state",
  },
];
