export const Products = [
  {
    name: "Skin Care",
    template: require("./skin_care.json"),
  },
  {
    name: "Sexual Care",
    template: require("./sexual_care.json"),
  },
];
