import React from "react";
import { Helmet } from "react-helmet";
import "../style.css";

import { Routes } from "constants/index";

const FitnessComponent = () => {
  return (
    <>
      <Helmet>
        <title>Get Medical Fitness Certificate Online In 60 Mins</title>
        <link
          rel="canonical"
          href="https://medoc.life/online-medical-certificates/fitness-certificate"
        />
        <meta
          name="title"
          content="Get Medical Fitness Certificate Online In 60 Mins"
        />
        <meta
          name="description"
          content="A fitness certificate is a document that attests to an individual's physical health and ability to perform certain types of work or activities."
        />
        <meta
          name="keywords"
          content="medical certificate format,fitness medical certificate format,medical fitness certificate pdf,doctor note online,doctor fitness certificate,medical certificate online delhi"
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://medoc.life/online-medical-certificates/fitness-certificate"
        />
        <meta
          property="og:title"
          content="Get Medical Fitness Certificate Online In 60 Mins"
        />
        <meta
          property="og:description"
          content="A fitness certificate is a document that attests to an individual's physical health and ability to perform certain types of work or activities."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
        />
        <meta property="og:site_name" content="medoc.life" />

        <meta
          property="twitter:url"
          content="https://medoc.life/online-medical-certificates/fitness-certificate"
        />
        <meta
          property="twitter:title"
          content="Get Medical Fitness Certificate Online In 60 Mins"
        />
        <meta
          property="twitter:description"
          content="A fitness certificate is a document that attests to an individual's physical health and ability to perform certain types of work or activities."
        />
        <meta
          property="twitter:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="3 minutes" />
      </Helmet>
      <div className="container blogs-wrapper px-3 px-sm-0">
        <div className="row align-items-center">
          <div className="col-12 col-sm-6">
            <img
              alt="Medical Fitness Certificate Online"
              className="blog-img pinned"
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
            />
          </div>
          <div className="col-12 col-sm-6 d-none d-sm-block">
            <h1 className="h3 mt-4 blog-header">
              Get Medical Fitness Certificate Online In 60 Mins
            </h1>
            <div className="mb-2">
              Written by&nbsp;
              <a
                href="https://www.linkedin.com/company/medoc-offc/"
                target="_blank"
                rel="noreferrer noopener"
                className="text-violet fw-600"
              >
                medoc.life
              </a>
            </div>
            <small className="text-secondary">Last Updated: Apr 08, 2023</small>{" "}
            <br />
            <small className="text-secondary">Next Update: Jan 31, 2024</small>
          </div>

          <div className="d-sm-none">
            <div className="mx-auto d-flex flex-column align-items-center">
              <h1 className="h5 mt-4 blog-header d-none d-sm-flex">
                Get Medical Fitness Certificate Online In 60 Mins
              </h1>
              <h1 className="h5 mt-4 mx-4  blog-header d-sm-none text-center">
                Get Medical Fitness Certificate Online In 60 Mins
              </h1>
              <p className="text-center">
                <div>
                  Written by&nbsp;
                  <a
                    href="https://www.linkedin.com/company/medoc-offc/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-violet fw-600"
                  >
                    medoc.life
                  </a>
                </div>
                <small className="text-secondary">
                  Last Updated: Feb 07, 2023
                </small>{" "}
                <br />
                <small className="text-secondary">
                  Next Update: Jan 31, 2024
                </small>
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row justify-content-center mt-5">
          <a
            href={Routes.LOGIN}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-violet-rounded text-white p-3 px-4 pointer m-2"
          >
            Buy Fitness Certificate At 450 INR/-
          </a>
        </div>
        <div className="mt-3 mt-sm-5 col-12">
          <div className="my-4">
            <h2 className="h5">Medical Fitness Certificate</h2>
            <p>
              A fitness certificate is a document that attests to an
              individual's physical health and ability to perform certain types
              of work or activities. It is often required in situations where
              the nature of the work or activity may involve physical exertion
              or pose a risk to the individual's health.
            </p>
          </div>
          <div className="my-4">
            <h2 className="h5">
              Is{" "}
              <a
                href="https://medoc.life/medical-certificate"
                className="text-orange-600 fw-600"
              >
                online medical fitness certificate
              </a>{" "}
              for you?
            </h2>
            <ul>
              <li>
                Enrollment in educational or training programs: A fitness
                certificate is required for enrollment in certain educational or
                training programs. For example, military or paramilitary
                training programs may have strict physical fitness requirements,
                and individuals may be required to obtain a fitness certificate
                in order to demonstrate that they meet these requirements. This
                is important to ensure that individuals are physically capable
                of handling the demands of the training program and the duties
                they will be required to perform as a result.
              </li>
              <li>
                Industries: If you work in the construction, manufacturing,
                transportation, or mining sectors, you may be required to have a
                fitness certificate in order to demonstrate that you are capable
                of performing the physical demands of the job. This is
                particularly important in industries that involve heavy lifting,
                exposure to hazardous materials, or other strenuous activities
                that may pose a risk to the worker's health.
              </li>
              <li>
                Sports & Recreational: A fitness certificate is required to
                participate in certain sports or recreational activities. For
                example, individuals who wish to engage in competitive athletics
                or adventure sports, such as mountain climbing or white water
                rafting, may be required to obtain a fitness certificate in
                order to demonstrate that they are physically fit and capable of
                participating in these activities. This is important to ensure
                the safety of the individual and those around them.
              </li>
              <li>
                Sports & Recreational: A fitness certificate is required to
                participate in certain sports or recreational activities. For
                example, individuals who wish to engage in competitive athletics
                or adventure sports, such as mountain climbing or white water
                rafting, may be required to obtain a fitness certificate in
                order to demonstrate that they are physically fit and capable of
                participating in these activities. This is important to ensure
                the safety of the individual and those around them.
              </li>
              <li>
                Travel Abroad: A fitness certificate is required for travel to
                certain countries or regions. Some countries or regions may have
                strict health requirements for entry or residence, and may
                require individuals to obtain a fitness certificate as a
                condition of entry or residence. This is typically done to
                ensure that individuals are healthy and will not pose a risk to
                the local population.
              </li>
            </ul>
          </div>
          <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white box">
            <a
              href="https://app.medoc.life"
              target="_blank"
              rel="noopener noreferrer"
              className="h3 text-center text-white"
            >
              Get started with medoc, today.
            </a>
          </div>
          <div className="my-4">
            <h2 className="h5">Fitness Certificate Format</h2>
            <p>
              A fitness certificate is a formal document that serves as proof
              that an individual was physically fit and healthy. Before issuing
              certificate, the following checks will be made via online call -
            </p>
            <ul>
              <li>Basic vitals - Blood Pressute, Pulse</li>
              <li>Any diseases like diabetes, piles</li>
              <li>Any disorders</li>
              <li>Mental status</li>
            </ul>
            Also, the doctor may ask for additional information, if required.
            This certificate is usually issued by a certified M.B.B.S doctor.
          </div>
          <div className="my-4">
            <h2 className="h5">Authenticity of Fitness Certificate</h2>
            <p>
              With the telemedicine act of 2019, getting a medical certificate
              is now quick and convenient. Digital healthcare has made it
              possible for the doctors to issue medical certificates digitally
              after assessing the need and health condition of the customers.
            </p>
            <p>
              In India, patients are not much exposed to digital medical
              certificates, but laws and rules have been sanctioned including
              the Information Technology (IT) Act of 2000, the Indian Medical
              Council (Professional Conduct, Etiquette and Ethics) Regulations
              of 2002, and the Medical Council of India (MCI) guidelines on
              telemedicine for the acceptance and validity of the digital
              medical certificates in the form of fitness, sick leave, caretaker
              and recovery certificates.
            </p>
            <p>
              According to the provisions of the Telemedicine Act of 2019, the
              validity of a medical certificate can be determined by checking if
              it has the necessary information:
            </p>
            <ul>
              <li>Doctor name</li>
              <li>Doctor Registration number</li>
              <li>Doctor Signature</li>
              <li>Doctor Seal with Qualification</li>
              <li>Name of the concerned institution</li>
            </ul>
          </div>
          <div className="my-4">
            <h2 className="h5">Get Medical Fitness Certificate Online</h2>
            <p>A simple process to get a digital fitness certificate:</p>
            <ul>
              <li>
                Answer Simple Questions -
                <ul>
                  <li>
                    about yourself like name, gender, address, height, weight
                  </li>
                  <li>upload 15 secs walking video</li>
                  <li>upload Govt. ID document</li>
                </ul>
              </li>
              <li>
                Quick Doctor Review - a certified M.B.B.S doctor reviews your
                information and provide an online consultation
              </li>
              <li>
                Get Approved Fitness Certificate - you'll receive the
                certificate via email as soon as review completes.
              </li>
            </ul>
          </div>
          <div className="my-4">
            <h2 className="h5">Guidelines</h2>
            <p>
              At{" "}
              <a
                href="https://medoc.life/medical-certificate"
                className="text-orange-600 fw-600"
              >
                Medoc.life
              </a>
              , we strictly adhere to ethical practices and do not condone any
              form of fabricated or fake medical fitness certificates. Each
              certificate is issued only after a thorough examination of the
              medical condition by a registered medical practitioner in India.
              The practitioner must be convinced with your physical health and
              consultation before the certificate is issued, ensuring that all
              medical fitness certificates provided by us are authentic and
              based on actual medical issues.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FitnessComponent;
