export const ConsultationFaqs = [
  {
    key: "General",
    values: [
      {
        question: "When will I receive call from doctor?",
        answer:
          "You will receive a call from our doctor buddy within 120 minutes of your payment confirmation.",
      },
      {
        question: "What medical advice do you provide?",
        answer:
          "We provide healthcare advice on the above listed diseases. Includes acne, cold & cough, erectile dysfunction, flu & fever, mental health, premature ejaculation, migraine & all other diseases.",
      },
      {
        question: "What are your hours of operation?",
        answer:
          "You can submit a request at any time of day throughout the week, and we will get to your request urgently, within 24 hours. It may vary during nights and bank holidays",
      },
      {
        question: "Do you operate across India?",
        answer:
          "Yes, our healthcare professionals are located across India and can serve people from all Indian states and territories.",
      },
      {
        question: "Do you provide a medical certificate?",
        answer:
          "No, this service is only for healthcare advice. If you want certificates, you need to check our <a href='/medical-certificate' target='_blank' rel='noreferrer noopener'>Medical Certificates section</a>",
      },
      {
        question: "What languages may a doctor speak?",
        answer:
          "Our common language is English and Hindi, although our doctors are fluent in several Indian languages.",
      },
      {
        question: "How long does it take to receive a feedback?",
        answer:
          "You will receive your call feedback through email within 15-20 minutes of the conversation ending. You can also use the app to check.",
      },
      {
        question: "How do I communicate with the doctor?",
        answer:
          "You can communicate with the doctor via chat. Once a doctor has been assigned to your request, chat will be enabled.",
      },
      {
        question: "How long can I communicate with the doctor?",
        answer:
          "It depends on the product you purchase. Usually, it is upto 48 hours.",
      },
      {
        question: "Is it possible to follow-up the doctor again in two days?",
        answer:
          "No, a follow-up call is not currently feasible. You can instead begin a new consultation with old reports.",
      },
    ],
  },
];
