import React from "react";
import "./style.css";

import { Routes } from "constants/index";

const SectionThreeComponent = ({ smallDevice }) => {
  return (
    <div id="section-three" className="section-three-wrapper container">
      <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white">
        <h2 className="text-center">Get your medical certificate, today.</h2>
        <a
          href={Routes.LOGIN}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-lg btn-orange-rounded fs-16 px-4 fw-500 mt-3"
        >
          Get Certificate Now
        </a>
      </div>
    </div>
  );
};

export default SectionThreeComponent;
