import React, { useState, useEffect } from "react";
import "./style.css";

import { get } from "firebase_config";
import { Tables } from "constants/index";

const ClinicsComponent = ({ smallDevice }) => {
  const [profiles, setProfiles] = useState();

  useEffect(() => {
    async function getProfiles() {
      let ps = await get(Tables.DC_PUBLIC_PROFILE);
      ps = ps.sort((a, b) => a.createdAt - b.createdAt);
      setProfiles(ps);
    }

    if (!profiles) {
      getProfiles();
    }
  });

  return (
    <div id="clinics-wrapper" className="clinics-wrapper container">
      <h2 className="text-center my-5 pt-5 py-sm-0 fw-600">
        Our virtual clinics across India
      </h2>
      <div className="row pb-5 px-sm-5">
        {profiles &&
          profiles.map(
            (t, i) =>
              t.bio?.length > 100 &&
              !t.hidden && (
                <div className="col-12 col-sm-6 col-md-4 mb-3" key={i}>
                  <div className="card text-center p-4">
                    <div className="text-center">
                      <img
                        src={t.photoURL}
                        className="card-img-top"
                        alt={t.name.split(" ")[0]}
                      ></img>
                    </div>
                    <div className="fw-600 pt-3">{t.name}</div>
                    <p className="text-center">{t.qualification}</p>
                    <p className="fs-14 text-justify">
                      {t.bio?.substring(0, 100)}...
                    </p>
                    <a
                      href={`https://medoc.life/dr/${t.username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-violet text-center mb-3"
                    >
                      /dr/{t.username}
                    </a>
                    <a
                      href={`https://medoc.life/dr/${t.username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-violet-rounded"
                    >
                      Book
                    </a>
                  </div>
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default ClinicsComponent;
