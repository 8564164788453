import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";

import {
  StaticHeaderComponent,
  StaticFooterComponent,
  HowItWorksComponent,
  TestimonialsComponent,
} from "components";
import { Routes } from "constants/index";
import SickLeaveComponent from "./sick-leave/component";
import FitnessComponent from "./fitness/component";
import FitToTravelComponent from "./fitToTravel/component";
import FitToAdoptComponent from "./fitToAdopt/component";
import FitToAdventuresComponent from "./fitToAdventures/component";
import FitToJoinSchoolComponent from "./fitToJoinSchool/component";
import UnfitToTravelComponent from "./unfitToTravel/component";
import UnfitToWorkComponent from "./unfitToWork/component";
import RecoveryComponent from "./recovery/component";
import CareTakerComponent from "./caretaker/component";
import Form1aComponent from "./form-1a/component";
import CustomFormatComponent from "./customFormat/component";
import WorkFromHomeComponent from "./workFromHome/component";

const ProductsComponent = (props) => {
  const name = props.match.params.product;
  const url = props.match.url;
  const history = useHistory();

  useEffect(() => {
    if (url.includes("/products")) {
      history.push(url.replace("/products", "/online-medical-certificates"));
    }
  }, []);

  const consultNowButton = () => (
    <div className="d-flex flex-column flex-sm-row justify-content-center mt-5">
      <a
        href={Routes.LOGIN}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-violet-rounded text-white p-3 px-4 pointer m-2"
      >
        Buy Certificate
      </a>
      <a
        href={Routes.DOCTOR_CONSULTATION}
        className="btn btn-violet-outline-r p-3 px-4 pointer m-2"
      >
        Get Online Consultation
      </a>
    </div>
  );

  return (
    <div className="products-wrapper">
      <StaticHeaderComponent bottomFooter="false" />
      {name === "sick-leave-certificate" && <SickLeaveComponent name={name} />}
      {name === "fitness-certificate" && <FitnessComponent name={name} />}
      {name === "fit-to-work" && <FitnessComponent name={name} />}
      {name === "fit-to-travel" && <FitToTravelComponent name={name} />}
      {name === "cara-fitness-certificate" && (
        <FitToAdoptComponent name={name} />
      )}
      {name === "fitness-for-school-admission" && (
        <FitToJoinSchoolComponent name={name} />
      )}
      {name === "fitness-for-adventure-sports" && (
        <FitToAdventuresComponent name={name} />
      )}
      {name === "work-from-home-certificate" && (
        <WorkFromHomeComponent name={name} />
      )}
      {name === "unfit-to-work" && <UnfitToWorkComponent name={name} />}
      {name === "unfit-to-travel" && <UnfitToTravelComponent name={name} />}
      {name === "custom-format-certificate" && (
        <CustomFormatComponent name={name} />
      )}
      {name === "caretaker-certificate" && <CareTakerComponent name={name} />}
      {name === "recovery-certificate" && <RecoveryComponent name={name} />}
      {name === "form-1a-certificate" && <Form1aComponent name={name} />}
      {consultNowButton()}
      <div className="text-center pb-5 pt-3">
        <TestimonialsComponent />
      </div>
      <HowItWorksComponent />
      <StaticFooterComponent />
    </div>
  );
};

export default ProductsComponent;
