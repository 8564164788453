import React from "react";
import "./style.css";

import { Routes } from "constants/index";

const SectionTwoComponent = ({ smallDevice }) => {
  return (
    <div id="section-two" className="section-two-wrapper container">
      <div>
        <h3 className="h1 mt-5 mb-4 pt-5">How Medoc Consultation Works?</h3>
        <p className="lead col-12 col-sm-10 px-sm-5 mx-sm-5 fw-400 d-none">
          Medoc is an online healthcare advice platform that helps you enjoy
          confidential consultations with registered Indian doctors - hassle
          free.
        </p>
        <div className="row align-items-center my-5 py-sm-5">
          <div className="col-12 col-sm-6">
            <img
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/consultation_2.jpg"
              alt="play_image"
              className="col-12 consultation-image-4"
            />
          </div>
          <div className="steps lh-32 col-12 col-sm-5 text-center text-sm-left p-5 p-sm-0 pl-sm-4 ">
            <span className="text-orange-600 d-none">Step 1</span>
            <h4 className="h3 steps-header my-3 my-sm-2">
              Submit your request
            </h4>
            <div className="steps-body">
              Complete a quick medical questionnaire - most people tell us it
              only takes a few mintues
            </div>
          </div>
        </div>
        {smallDevice ? (
          <div className="row align-items-center my-5">
            <div className="col-12">
              <img
                src="https://storage.googleapis.com/medoc-live.appspot.com/images/consultation_3.jpg"
                alt="play_image"
                className="col-12 consultation-image-2"
              />
            </div>
            <div className="lh-32 col-12 col-sm-5 text-center text-sm-left p-5 p-sm-0 pl-sm-4 ">
              <span className="text-orange-600 d-none">Step 2</span>
              <h4 className="h3 steps-header my-3 my-sm-0">
                Your Doctor Buddy calls
              </h4>
              <div className="steps-body fs-18">
                Your Doctor Buddy will review your request and call you to
                provide medical advice
              </div>
            </div>
          </div>
        ) : (
          <div className="row align-items-center text-sm-left my-5 py-4">
            <div className="lh-32 col-12 col-sm-6">
              <span className="text-orange-600 d-none">Step 2</span>
              <h4 className="h3 steps-header my-3 my-sm-2">
                Your Doctor Buddy calls
              </h4>
              <div className="steps-body pr-sm-5">
                Your Doctor Buddy will review your request and call you to
                provide medical advice
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <img
                src="https://storage.googleapis.com/medoc-live.appspot.com/images/consultation_3.jpg"
                alt="play_image"
                className="col-12 consultation-image-2"
              />
            </div>
          </div>
        )}
        <div className="row align-items-center my-5 py-sm-5">
          <div className="col-12 col-sm-6">
            <img
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/consultation_4.jpg"
              alt="play_image"
              className="col-12 consultation-image-4"
            />
          </div>
          <div className="lh-32 col-12 col-sm-5 text-center text-sm-left p-5 p-sm-0 pl-sm-5">
            <span className="text-orange-600 d-none">Step 3</span>
            <h4 className="h3 steps-header my-3 my-sm-2">
              Receive further support via chat
            </h4>
            <div className="steps-body">
              Get constant feedback and advice via chat facility in the app -
              simple as that!
            </div>
          </div>
        </div>
      </div>
      <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white d-none">
        <h2 className="h1 text-center">Connect with Doctor Buddy now.</h2>
        <a
          href={Routes.LOGIN}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-lg btn-orange-rounded fs-16 px-4 fw-500 mt-3"
        >
          Consult Now
        </a>
      </div>
    </div>
  );
};

export default SectionTwoComponent;
