export const Blogs = [
  {
    pin: true,
    name: "sick-leave-certificate",
    template: require("./blog_templates/sick_leave_certificate").default,
  },
  {
    name: "fitness-certificate",
    template: require("./blog_templates/fitness_certificate").default,
  },
  {
    name: "recovery-certificate",
    template: require("./blog_templates/recovery_certificate").default,
  },
  {
    name: "caretaker-certificate",
    template: require("./blog_templates/caretaker_certificate").default,
  },
  {
    name: "form-1a-certificate",
    template: require("./blog_templates/form_1a_certificate").default,
  },
];
