import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import SectionOneComponent from "./section_one/component";
import SectionOneTwoComponent from "./section_one_two/component";
import SectionTwoComponent from "./section_two/component";
import SectionFourComponent from "./section_four/component";
import {
  StaticHeaderComponent,
  StaticFooterComponent,
  ClinicsComponent,
} from "components";
import "./style.css";

const HomeConsultationComponent = () => {
  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  return (
    <div className="home-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Medoc | Online Doctor Consultation | Access Healthcare from Your Home
          in India
        </title>
        <link rel="canonical" href="https://medoc.life/doctor-consultation" />
        <meta
          name="description"
          content="Get access to quality healthcare from the comfort of your home with our online doctor consultation services in India. No appointment required."
        />
        <meta
          name="keywords"
          content="online doctor consultation,medical certificate online,India,telemedicine,digital ihealthcare,medical services"
        />
        <meta
          property="og:title"
          content="Online Doctor Consultation | Access Healthcare from Your Home in India"
        />
        <meta
          property="og:description"
          content="Get access to quality healthcare from the comfort of your home with our online doctor consultation services in India. No appointment required."
        />
        <meta
          property="og:url"
          content="https://medoc.life/doctor-consultation"
        />
        <meta property="og:site_name" content="medoc.life" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/consultation_banner_2.png"
        />
        <meta property="og:image:width" content="590" />
        <meta property="og:image:height" content="615" />
        <meta
          property="article:modified_time"
          content="2022-2-2T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Online Doctor Consultation | Access Healthcare from Your Home in India"
        />
        <meta
          name="twitter:description"
          content="Get access to quality healthcare from the comfort of your home with our online doctor consultation services in India. No appointment required."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="2 minutes" />
      </Helmet>
      <StaticHeaderComponent />
      <SectionOneComponent smallDevice={smallDevice} />
      <div
        className="trustpilot-widget text-center my-4 mb-0"
        data-locale="en-UK"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="63489030a91712093d8e88e0"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
        data-min-review-count="10"
        data-without-reviews-preferred-string-id="1"
        data-style-alignment="center"
      >
        <a
          href="https://www.trustpilot.com/review/medoc.life"
          target="_blank"
          rel="noopener noreferrer"
          className="text-black fw-500"
        >
          <span className="fw-600">Excellent 4.7</span> out of 5 &nbsp;
          <img
            src="https://storage.googleapis.com/medoc-live.appspot.com/images/trustpilot.png"
            loading="lazy"
            alt="Medoc Trustpilot"
            width="35"
            className="tp-icon"
          />
          Trustpilot.
        </a>
      </div>
      <hr />
      <SectionOneTwoComponent smallDevice={smallDevice} />
      <SectionTwoComponent smallDevice={smallDevice} />
      <ClinicsComponent smallDevice={smallDevice} />
      <SectionFourComponent smallDevice={smallDevice} />
      <StaticFooterComponent />
    </div>
  );
};

export default HomeConsultationComponent;
