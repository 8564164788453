import React from "react";
import { Helmet } from "react-helmet";
import "../style.css";

import { Routes } from "constants/index";

const Form1aComponent = () => {
  return (
    <>
      <Helmet>
        <title>Get Form 1A Fitness Certificate</title>
        <link
          rel="canonical"
          href="https://medoc.life/online-medical-certificates/form-1a-certificate"
        />
        <meta name="title" content="Get Form 1A Fitness Certificate" />
        <meta
          name="description"
          content="One of the most crucial documents required is RTO Form 1A, also known as the Fitness Medical Certificate. This form attests to the medical fitness of the person applying for the driving license and must be submitted to the registering authority..."
        />
        <meta
          name="keywords"
          content="online medical certificate for driving licence,doctor certificate for driving licence,Form 1a certificate for driving licence"
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://medoc.life/online-medical-certificates/form-1a-certificate"
        />
        <meta property="og:title" content="Get Form 1A Fitness Certificate" />
        <meta
          property="og:description"
          content="One of the most crucial documents required is RTO Form 1A, also known as the Fitness Medical Certificate. This form attests to the medical fitness of the person applying for the driving license and must be submitted to the registering authority..."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
        />
        <meta property="og:site_name" content="medoc.life" />

        <meta
          property="twitter:url"
          content="https://medoc.life/online-medical-certificates/form-1a-certificate"
        />
        <meta
          property="twitter:title"
          content="Get Form 1A Fitness Certificate"
        />
        <meta
          property="twitter:description"
          content="One of the most crucial documents required is RTO Form 1A, also known as the Fitness Medical Certificate. This form attests to the medical fitness of the person applying for the driving license and must be submitted to the registering authority..."
        />
        <meta
          property="twitter:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="3 minutes" />
      </Helmet>
      <div className="container blogs-wrapper px-3 px-sm-0">
        <div className="row align-items-center">
          <div className="col-12 col-sm-6">
            <img
              alt="Form 1A Driving License Certificate Online in India"
              className="blog-img pinned"
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
            />
          </div>
          <div className="col-12 col-sm-6 d-none d-sm-block">
            <h1 className="h3 mt-4 blog-header">
              Get Form 1A Fitness Certificate
            </h1>
            <div className="mb-2">
              Written by&nbsp;
              <a
                href="https://www.linkedin.com/company/medoc-offc/"
                target="_blank"
                rel="noreferrer noopener"
                className="text-violet fw-600"
              >
                medoc.life
              </a>
            </div>
            <small className="text-secondary">Last Updated: Apr 08, 2023</small>{" "}
            <br />
            <small className="text-secondary">Next Update: Jan 31, 2024</small>
          </div>

          <div className="d-sm-none">
            <div className="mx-auto d-flex flex-column align-items-center">
              <h1 className="h5 mt-4 blog-header d-none d-sm-flex">
                Get Form 1A Fitness Certificate
              </h1>
              <h1 className="h5 mt-4 mx-4  blog-header d-sm-none text-center">
                Get Form 1A Fitness Certificate
              </h1>
              <p className="text-center">
                <div>
                  Written by&nbsp;
                  <a
                    href="https://www.linkedin.com/company/medoc-offc/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-violet fw-600"
                  >
                    medoc.life
                  </a>
                </div>
                <small className="text-secondary">
                  Last Updated: Feb 07, 2023
                </small>{" "}
                <br />
                <small className="text-secondary">
                  Next Update: Jan 31, 2024
                </small>
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row justify-content-center mt-5">
          <a
            href={Routes.LOGIN}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-violet-rounded text-white p-3 px-4 pointer m-2"
          >
            Get Form 1A Certificate At 1200 INR/-
          </a>
        </div>
        <div className="mt-3 mt-sm-5 col-12">
          <div className="my-4">
            <h2 className="h5">
              Get Form 1A Medical Fitness Certificate for Driving Licence
            </h2>
            <p>
              A Form 1A Fitness Certificate (FFC) is a mandatory document
              required to obtain a driving license in India. It certifies the
              physical and mental fitness of the applicant to drive a vehicle on
              the roads. The objective of this article is to provide a
              comprehensive guide on the OFC and how to obtain it in the
              simplest and most convenient manner.
            </p>
          </div>
          <div className="my-4">
            <h2 className="h5">
              What is a{" "}
              <a
                href="https://medoc.life/medical-certificate"
                className="text-orange-600 fw-600"
              >
                Form 1A Fitness Certificate for Driving License?
              </a>
            </h2>
            <p>
              A Form 1A Fitness Certificate is a certificate that confirms the
              applicant's fitness to drive a vehicle on the roads. It is a
              crucial document that ensures the safety of other road users,
              pedestrians, and the driver himself. The certificate is valid for
              a maximum period of 3 years and must be renewed after it expires.
            </p>
          </div>
          <div className="my-4">
            <h2 className="h5">
              Why is Form 1A Fitness Certificate Required for Driving License?
            </h2>
            <p>
              The primary purpose of a Fitness Certificate is to make sure that
              drivers are physically and mentally fit to operate a vehicle. It
              ensures that drivers have the necessary coordination and reflexes
              to handle emergency situations on the road and avoid accidents.
              Moreover, it also ensures that the drivers have no medical
              conditions that could impair their driving ability.
            </p>
          </div>
          <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white box">
            <a
              href="https://app.medoc.life"
              target="_blank"
              rel="noopener noreferrer"
              className="h3 text-center text-white"
            >
              Get started with medoc, today.
            </a>
          </div>
          <div className="my-4">
            <h2 className="h5">
              What are the Requirements for Obtaining an Form 1A Fitness
              Certificate?
            </h2>
            <p>
              The eligibility criteria for obtaining a Form 1A Fitness
              Certificate are straightforward and simple. The applicant must:
            </p>
            <ul>
              <li>
                Be above 16 years of age for a two-wheeled vehicle and above 18
                years of age for a four-wheeled vehicle.
              </li>
              <li>
                Not suffer from any medical conditions that could affect their
                driving ability, such as color blindness, poor vision, epilepsy,
                heart diseases, etc.
              </li>
            </ul>
          </div>
          <div className="my-4">
            <h2 className="h5">How to Obtain a Form 1A Certificate?</h2>
            <p>
              The process of obtaining an Form 1A has been made simple and
              convenient through the use of technology. The applicant can now
              obtain the certificate online in a few easy steps:
            </p>
            <ol>
              <li>
                Visit the official website of the Regional Transport Office
                (RTO) in your area.
              </li>
              <li>
                Fill out the required personal and medical information in the
                online application form.
              </li>
              <li>
                Schedule an appointment for a medical examination with an
                authorized doctor.
              </li>
              <li>
                Attend the medical examination and pass the required tests.
              </li>
              <li>
                Receive the Form 1A online, either through email or by
                downloading it from the RTO website.
              </li>
            </ol>
          </div>
          <div className="my-4">
            <h2 className="h5">Get Signed Form 1A Certificate Online</h2>
            <p>A simple process to get certificate:</p>
            <ul>
              <li>
                <a
                  href="https://app.medoc.life"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-orange-600"
                >
                  Answer Simple Questions - about yourself, and upload Govt. ID
                  documents
                </a>
              </li>
              <li>
                Eye/Ear Online Test - book an online appointment for eye/ear
                test with our doctor consultant
              </li>
              <li>
                Quick Doctor Review - a certified M.B.B.S doctor reviews your
                information and provide an online consultation
              </li>
              <li>
                Get Signed Copy of Form 1A Certificate - you'll receive the
                certificate via email as soon as review completes.
              </li>
            </ul>
          </div>
          <div className="my-4">
            <p>
              A Form 1A Fitness Certificate is a mandatory document required to
              obtain a driving license in India. It certifies the physical and
              mental fitness of the applicant to drive a vehicle on the roads.
              The process of obtaining it has been made simple and convenient
              through the use of Medoc technology. By following the steps
              outlined in this article, you can obtain it in a few easy steps
              and be one step closer to obtaining your driving license.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form1aComponent;
