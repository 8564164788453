import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./style.css";

import { StaticHeaderComponent, StaticFooterComponent } from "components";
import { Routes } from "constants/index";

const RefundPolicyComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Medoc | Refund Policy | Ensuring Customer Satisfaction on Our Medical
          Certificate Platform in India
        </title>
        <link rel="canonical" href="https://medoc.life/rp" />
        <meta
          name="description"
          content="Our refund policy outlines our commitment to customer satisfaction on our medical certificate platform in India. Learn about returns and cancellations."
        />
        <meta
          name="keywords"
          content="medical certificate online,doctor consultation,digital healthcare,India,refund policy,returns, cancellations,customer satisfaction"
        />
        <meta
          property="og:title"
          content="Medoc | Refund Policy | Ensuring Customer Satisfaction on Our Medical Certificate Platform in India"
        />
        <meta
          property="og:description"
          content="Our refund policy outlines our commitment to customer satisfaction on our medical certificate platform in India. Learn about returns and cancellations."
        />
        <meta property="og:url" content="https://medoc.life/rp" />
        <meta property="og:site_name" content="medoc.life" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/razorpay_logo.jpeg"
        />
        <meta property="og:image:width" content="590" />
        <meta property="og:image:height" content="615" />
        <meta
          property="article:modified_time"
          content="2023-2-2T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medoc | Refund Policy | Ensuring Customer Satisfaction on Our Medical Certificate Platform in India"
        />
        <meta
          name="twitter:description"
          content="Our refund policy outlines our commitment to customer satisfaction on our medical certificate platform in India. Learn about returns and cancellations."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="15 minutes" />
      </Helmet>

      <StaticHeaderComponent />
      <div className="container privacy-policy-wrapper">
        <h3 className="page-title">Refund Policy</h3>
        <div>
          <Link to={Routes.DEFAULT} className="fw-500">
            Medoc.life
          </Link>{" "}
          has a dynamic refund policy.
        </div>
        <hr />
        <ol>
          <li>
            <b className="pp-header">Refund cases</b>
            <p className="pp-body">
              We provide refund in cases where:
              <ul>
                <li>
                  Underage User i.e. less than 18 years is applying without the
                  knowledge of a caretaker.
                </li>
                <li>
                  Proper medical history or current medical records of ailment
                  is not available and is informed to the team before doctor
                  consultation.
                </li>
                <li>
                  The user is unwilling to do online doctor consultations.
                </li>
                <li>Complaints are found to be invalid by our health team.</li>
                <li>
                  The User is asking for a certificate more than 5 times without
                  getting the disease treated.
                </li>
                <li>
                  Certificate needed for regularization of insurance claims or
                  government employees duty regularization or it’s a
                  medico-legal case.
                </li>
                <li>
                  The Doctor has not contacted the User in the given time.
                </li>
              </ul>
            </p>
          </li>
          <li>
            <b className="pp-header">Deductions</b>
            <p className="pp-body">
              <ul>
                <li>
                  If the purchase amount for any certificate, consultation, or
                  service on Medoc.life is equal to or greater than 1000 INR/-,
                  a fee of INR 500/- will be charged for each and every refund.
                  For purchases that are less than 1000 INR/-, a service charge
                  of INR 250/- will be applied for every single refund.
                </li>
              </ul>
            </p>
          </li>
          <li>
            <b className="pp-header">Non-refund cases</b>
            <p className="pp-body">
              User will not be entitled to any refunds in cases where:
              <ul>
                <li>
                  User does not attend the online appointment, without canceling
                  the appointment at least 60 mins before the appointment time.
                </li>
                <li>
                  Doctor has attempted to call the User 3 times and the User is
                  not attending the online doctor consultation.
                </li>
                <li>The certificate has been issued.</li>
                <li>User’s ailment claims are found incorrect/illegal</li>
                <li>
                  User contradicts their own medical condition w.r.t to the
                  details shared in the form and during doctor consultation.
                </li>
                <li>
                  User fails to produce the documents supporting the ailment
                  mentioned by them after the online consultation.
                </li>
                <li>
                  User has attended a Doctor consultation and then wishes to
                  cancel their order
                </li>
                <li>
                  User fails to comply with guidelines, company terms and
                  policies.
                </li>
              </ul>
            </p>
          </li>
        </ol>
        <p className="text-justify">
          The user/customer is not entitled to any refund in case of
          non-issuance of Medical Certificate after going through the
          consultation process with the Doctor if in case, the Doctor is not
          satisfied and convinced that it is a fit case for the issuance of
          Medical Certificate. It is hereby clarified that the service charge
          paid by the customers/users is only for two heads i.e., facilitating
          the consultation with the Doctor in a convenient and expeditious
          manner, and the fees of the Doctor for consultation done, which in no
          way implies that the Doctor shall mandatorily issue the Medical
          Certificate, which is subject to his/her collective satisfaction and
          laws of the land.
        </p>
        <p className="text-justify">
          All refunds will be processed in the original mode of payment done by
          the User while requesting for a medical certificate within 8-10
          working days as per the payment gateway policies.
        </p>
        <p>
          For any further queries, write to <b>contact@medoc.life</b>
        </p>
      </div>
      <StaticFooterComponent />
    </>
  );
};

export default RefundPolicyComponent;
