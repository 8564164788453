import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Routes, Certs } from "constants/index";

const SectionTwoComponent = ({ smallDevice }) => {
  return (
    <div id="section-two" className="section-two-wrapper container">
      <div>
        <h2 className="h1 my-5 mb-sm-0 header">Online medical certificates</h2>
        <div className="row align-items-center pt-sm-5 text-left">
          {Certs.map(
            (t, i) =>
              t.display && (
                <div
                  className="col-12 col-sm-6 col-md-4 card-product"
                  key={i}
                  data-aos="zoom-in-down"
                  data-aos-duration="1500"
                >
                  <div className="card mb-4">
                    <div className="pull-left h5 text-violet fw-600">
                      {t.name}
                    </div>
                    <p className="card-text fs-14">
                      <span
                        dangerouslySetInnerHTML={{ __html: t.description }}
                      ></span>
                    </p>
                    <div className="row col-12 px-0">
                      <div className="col-6">
                        <a
                          href={Routes.LOGIN}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-violet-rounded col-12 text-violet font-small"
                        >
                          Buy Now
                        </a>
                      </div>
                      <div className="col-6">
                        <Link
                          to={t.blogLink}
                          className="btn btn-violet-outline-r col-12 text-violet font-small"
                        >
                          Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionTwoComponent;
