import React from "react";
import { Helmet } from "react-helmet";
import "../style.css";

import { Routes } from "constants/index";

const RecoveryComponent = () => {
  return (
    <>
      <Helmet>
        <title>Get Online Recovery Certificate In 60 Mins</title>
        <link
          rel="canonical"
          href="https://medoc.life/online-medical-certificates/recovery-certificate"
        />
        <meta
          name="title"
          content="Get Online Recovery Certificate In 60 Mins"
        />
        <meta
          name="description"
          content="A recovery certificate is a document that is issued by a medical professional to confirm that an individual is recovering from an illness, injury, or medical procedure."
        />
        <meta
          name="keywords"
          content="doctor certificate,medical certificate,certificate of doctor,recovery certificate,medical certificate in bangalore,medical certificate in jaipur,medical certificate in hyderabad"
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://medoc.life/online-medical-certificates/recovery-certificate"
        />
        <meta
          property="og:title"
          content="Get Online Recovery Certificate In 60 Mins"
        />
        <meta
          property="og:description"
          content="A recovery certificate is a document that is issued by a medical professional to confirm that an individual is recovering from an illness, injury, or medical procedure."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
        />
        <meta property="og:site_name" content="medoc.life" />

        <meta
          property="twitter:url"
          content="https://medoc.life/online-medical-certificates/recovery-certificate"
        />
        <meta
          property="twitter:title"
          content="Get Online Recovery Certificate In 60 Mins"
        />
        <meta
          property="twitter:description"
          content="A recovery certificate is a document that is issued by a medical professional to confirm that an individual is recovering from an illness, injury, or medical procedure."
        />
        <meta
          property="twitter:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="3 minutes" />
      </Helmet>
      <div className="container blogs-wrapper px-3 px-sm-0">
        <div className="row align-items-center">
          <div className="col-12 col-sm-6">
            <img
              alt="Medical Certificate Online"
              className="blog-img pinned"
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
            />
          </div>
          <div className="col-12 col-sm-6 d-none d-sm-block">
            <h1 className="h3 mt-4 blog-header">
              Get Online Recovery Certificate In 60 Mins
            </h1>
            <div className="mb-2">
              Written by&nbsp;
              <a
                href="https://www.linkedin.com/company/medoc-offc/"
                target="_blank"
                rel="noreferrer noopener"
                className="text-violet fw-600"
              >
                medoc.life
              </a>
            </div>
            <small className="text-secondary">Last Updated: Apr 08, 2023</small>{" "}
            <br />
            <small className="text-secondary">Next Update: Jan 31, 2024</small>
          </div>

          <div className="d-sm-none">
            <div className="mx-auto d-flex flex-column align-items-center">
              <h1 className="h5 mt-4 blog-header d-none d-sm-flex">
                Get Online Recovery Certificate In 60 Mins
              </h1>
              <h1 className="h5 mt-4 mx-4  blog-header d-sm-none text-center">
                Get Online Recovery Certificate In 60 Mins
              </h1>
              <p className="text-center">
                <div>
                  Written by&nbsp;
                  <a
                    href="https://www.linkedin.com/company/medoc-offc/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-violet fw-600"
                  >
                    medoc.life
                  </a>
                </div>
                <small className="text-secondary">
                  Last Updated: Feb 07, 2023
                </small>{" "}
                <br />
                <small className="text-secondary">
                  Next Update: Jan 31, 2024
                </small>
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row justify-content-center mt-5">
          <a
            href={Routes.LOGIN}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-violet-rounded text-white p-3 px-4 pointer m-2"
          >
            Buy Recovery Certificate At 450 INR/-
          </a>
        </div>
        <div className="mt-3 mt-sm-5 col-12">
          <p>
            A recovery certificate is a document that is issued by a medical
            professional to confirm that an individual is recovering from an
            illness, injury, or medical procedure. You may need a recovery
            certificate in a variety of different situations, and understanding
            when and why one may be necessary can be important for individuals
            and their families.
          </p>
          <div className="my-4">
            <h2 className="h5">
              Is{" "}
              <a
                href="https://medoc.life/medical-certificate"
                className="text-orange-600 fw-600"
              >
                online medical certificate
              </a>{" "}
              for you?
            </h2>
            <p>
              Yes, it is important to understand whether a digital medical
              certificate suits your requirement.
            </p>
            <ul>
              <li>
                Employee: If you're an employee, then you may need a recovery
                certificate to:
                <ol>
                  <li>show you've recovered from a medical condition.</li>
                  <li>to determine you're physically fit and healthy.</li>
                </ol>
              </li>
              <li>
                Student: If you're a student, then you may require recovery
                certificate to:
                <ol>
                  <li>prove you've recovered from illness.</li>
                  <li>maintain your school attendance record.</li>
                  <li>comply with your school policies.</li>
                </ol>
              </li>
              <li>
                Employer: If you're an employer, then Medoc medical certificates
                helps you to:
                <ol>
                  <li>verify the recovery of your employees.</li>
                  <li>manage sick leave benefits.</li>
                  <li>support return-to-work processes.</li>
                </ol>
              </li>
            </ul>
          </div>
          <div className="my-4">
            <h2 className="h5">Number of Days</h2>
            <p>
              The number of days on recovery certificate can vary based on
              person's medical condition like medical emergency leave, maternity
              leave, covid recovery leave, medical surgery leave, etc. It also
              depends on sick leave policy, medical leave rules in workplace.
              But, main factors are below:
            </p>
            <ul>
              <li>The number of days requested</li>
              <li>The availability of supporting medical documentation</li>
              <li>
                The discretion of the{" "}
                <a
                  href="https://medoc.life/doctor-consultation"
                  className="text-orange-600 fw-600"
                >
                  doctor consultation
                </a>
              </li>
            </ul>
          </div>
          <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white box">
            <a
              href="https://app.medoc.life"
              target="_blank"
              rel="noopener noreferrer"
              className="h3 text-center text-white"
            >
              Get started with medoc, today.
            </a>
          </div>
          <div className="my-4">
            <h2 className="h5">Recovery Certificate Format</h2>
            <p>
              A recovery certificate is a formal document that serves as proof
              that an individual was physically fit and healthy after a medical
              condition. Before issuing certificate, the following checks will
              be made via online call -
            </p>
            <ul>
              <li>Basic vitals - Blood Pressute, Pulse</li>
              <li>Any diseases like diabetes, piles</li>
              <li>Any disorders</li>
              <li>Mental status</li>
            </ul>
            Also, the doctor may ask for additional information, if required.
            This certificate is usually issued by a certified M.B.B.S doctor.
          </div>
          <div className="my-4">
            <h2 className="h5">Authenticity of Recovery Certificate</h2>
            <p>
              With the telemedicine act of 2019, getting a medical certificate
              is now quick and convenient. Digital healthcare has made it
              possible for the doctors to issue medical certificates digitally
              after assessing the need and health condition of the customers.
            </p>
            <p>
              In India, patients are not much exposed to digital medical
              certificates, but laws and rules have been sanctioned including
              the Information Technology (IT) Act of 2000, the Indian Medical
              Council (Professional Conduct, Etiquette and Ethics) Regulations
              of 2002, and the Medical Council of India (MCI) guidelines on
              telemedicine for the acceptance and validity of the digital
              medical certificates in the form of sick leave, fitness, caretaker
              and recovery certificates.
            </p>
            <p>
              According to the provisions of the Telemedicine Act of 2019, the
              validity of a medical certificate can be determined by checking if
              it has the necessary information:
            </p>
            <ul>
              <li>Doctor name</li>
              <li>Doctor Registration number</li>
              <li>Doctor Signature</li>
              <li>Doctor Seal with Qualification</li>
              <li>Name of the concerned institution</li>
            </ul>
          </div>
          <div className="my-4">
            <h2 className="h5">Get Recovery Certificate Online</h2>
            <p>A simple process to get a digital recovery certificate:</p>
            <ul>
              <li>
                Answer Simple Questions - about yourself, medical condition, and
                upload Govt. ID documents
              </li>
              <li>
                Quick Doctor Review - a certified M.B.B.S doctor reviews your
                information and provide an online consultation
              </li>
              <li>
                Get Approved Recovery Certificate - you'll receive the
                certificate via email as soon as review completes.
              </li>
            </ul>
          </div>
          <div className="my-4">
            <h2 className="h5">Fake Recovery Certificate</h2>
            <p>
              At{" "}
              <a
                href="https://medoc.life/medical-certificate"
                className="text-orange-600 fw-600"
              >
                Medoc.life
              </a>
              , we strictly adhere to ethical practices and do not condone any
              form of fabricated or fake medical certificates. Each certificate
              is issued only after a thorough examination of the medical
              condition by a registered medical practitioner in India. The
              practitioner must be convinced of the symptoms and consultation
              before the certificate is issued, ensuring that all medical
              certificates provided by us are authentic and based on actual
              medical issues.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoveryComponent;
