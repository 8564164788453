import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Routes } from "constants/index";

const DoctorConsultationComponent = ({ smallDevice }) => {
  return (
    <div className="doctor-consultation-wrapper container">
      <div className="card my-4" data-aos="fade-up" data-aos-duration="1500">
        <i
          className="fa fa-user-md fa-4x mb-4 text-violet"
          data-aos="fade-up"
        />
        <div className="h2 text-violet fw-600">Online Doctor Consultation</div>
        <p
          className="card-text py-4 px-sm-5 mx-sm-5"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          Don't stand for queuing. See a doctor in minutes. Our doctors can
          speak various languages <br /> including English and Hindi.
        </p>
        <div className="d-flex flex-column flex-sm-row justify-content-center">
          <div className="col-12 col-sm-4 mb-4">
            <a
              href={Routes.LOGIN}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-violet-rounded col-12 text-violet py-3"
            >
              Consult Now
            </a>
          </div>
          <div className="col-12 col-sm-4">
            <Link
              to={Routes.DOCTOR_CONSULTATION}
              className="btn btn-violet-outline-r col-12 text-violet py-3"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorConsultationComponent;
