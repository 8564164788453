import axios from "axios";
import { ENV } from "constants/env_keys";

const error = (err) => {
  console.log("err", err.toJSON());
  return err.response;
};

export const post = async (url, data = {}) => {
  const res = await axios
    .post(ENV.BASE_URL + url, data, {
      headers: {
        "content-type": "application/json",
        custom_auth_token: ENV.CUSTOM_AUTH_TOKEN,
      },
    })
    .catch((err) => error(err));
  return res;
};

export const get = async (url, data = {}) => {
  const res = await axios
    .get(ENV.BASE_URL + url, data, {
      headers: {
        "content-type": "application/json",
        custom_auth_token: ENV.CUSTOM_AUTH_TOKEN,
      },
    })
    .catch((err) => error(err));
  return res;
};
