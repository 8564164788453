import React from "react";
import { Helmet } from "react-helmet";
import "../style.css";

import { Routes } from "constants/index";

const CareTakerComponent = () => {
  return (
    <>
      <Helmet>
        <title>Get Caretaker Certificate In 60 Mins</title>
        <link
          rel="canonical"
          href="https://medoc.life/online-medical-certificates/caretaker-certificate"
        />
        <meta name="title" content="Get Caretaker Certificate In 60 Mins" />
        <meta
          name="description"
          content="A caretaker certificate is an important document to have. It serves as proof of your identity and your relationship to the person for whom you are providing care, and it can also help to establish your ability to care for them."
        />
        <meta
          name="keywords"
          content="care taker,caretaker certificate,doctor certificate,medical certificate,certificate of doctor"
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://medoc.life/online-medical-certificates/caretaker-certificate"
        />
        <meta
          property="og:title"
          content="Get Caretaker Certificate In 60 Mins"
        />
        <meta
          property="og:description"
          content="A caretaker certificate is an important document to have. It serves as proof of your identity and your relationship to the person for whom you are providing care, and it can also help to establish your ability to care for them."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
        />
        <meta property="og:site_name" content="medoc.life" />

        <meta
          property="twitter:url"
          content="https://medoc.life/online-medical-certificates/caretaker-certificate"
        />
        <meta
          property="twitter:title"
          content="Get Caretaker Certificate In 60 Mins"
        />
        <meta
          property="twitter:description"
          content="A caretaker certificate is an important document to have. It serves as proof of your identity and your relationship to the person for whom you are providing care, and it can also help to establish your ability to care for them."
        />
        <meta
          property="twitter:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="3 minutes" />
      </Helmet>
      <div className="container blogs-wrapper px-3 px-sm-0">
        <div className="row align-items-center">
          <div className="col-12 col-sm-6">
            <img
              alt="Caretaker Certificate Online in India"
              className="blog-img pinned"
              src="https://storage.googleapis.com/medoc-live.appspot.com/images/products/sicknote.webp"
            />
          </div>
          <div className="col-12 col-sm-6 d-none d-sm-block">
            <h1 className="h3 mt-4 blog-header">
              Get Caretaker Certificate In 60 Mins
            </h1>
            <div className="mb-2">
              Written by&nbsp;
              <a
                href="https://www.linkedin.com/company/medoc-offc/"
                target="_blank"
                rel="noreferrer noopener"
                className="text-violet fw-600"
              >
                medoc.life
              </a>
            </div>
            <small className="text-secondary">Last Updated: Feb 07, 2023</small>{" "}
            <br />
            <small className="text-secondary">Next Update: Jan 31, 2024</small>
          </div>

          <div className="d-sm-none">
            <div className="mx-auto d-flex flex-column align-items-center">
              <h1 className="h5 mt-4 blog-header d-none d-sm-flex">
                Get Caretaker Certificate In 60 Mins
              </h1>
              <h1 className="h5 mt-4 mx-4  blog-header d-sm-none text-center">
                Get Caretaker Certificate In 60 Mins
              </h1>
              <p className="text-center">
                <div>
                  Written by&nbsp;
                  <a
                    href="https://www.linkedin.com/company/medoc-offc/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-violet fw-600"
                  >
                    medoc.life
                  </a>
                </div>
                <small className="text-secondary">
                  Last Updated: Feb 07, 2023
                </small>{" "}
                <br />
                <small className="text-secondary">
                  Next Update: Jan 31, 2024
                </small>
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row justify-content-center mt-5">
          <a
            href={Routes.LOGIN}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-violet-rounded text-white p-3 px-4 pointer m-2"
          >
            Buy Caretaker Certificate At 450 INR/-
          </a>
        </div>
        <div className="mt-3 mt-sm-5 col-12">
          <p>
            Whether you are caring for a child, an elderly person, or someone
            with a disability, a caretaker certificate can be an important
            document to have. It serves as proof of your identity and your
            relationship to the person for whom you are providing care, and it
            can also help to establish your ability to care for them.
          </p>
          <div className="my-4">
            <h2 className="h5">Caretaker Certificate</h2>
            <p>
              A caretaker certificate is an important document to have. It
              serves as proof of your identity and your relationship to the
              person for whom you are providing care, and it can also help to
              establish your ability to care for them. If you are responsible
              for the care of a child: If you are a parent, guardian, or other
              caregiver for a child, you may need a caretaker certificate to
              enroll them in school, travel with them, or access certain
              benefits or services.
            </p>
          </div>
          <div className="my-4">
            <h2 className="h5">
              Is{" "}
              <a
                href="https://medoc.life/medical-certificate"
                className="text-orange-600 fw-600"
              >
                online medical certificate
              </a>{" "}
              for you?
            </h2>
            <p>
              Yes, it is important to understand whether a digital medical
              certificate suits your requirement.
            </p>
            <ul>
              <li>
                If you are caring for an elderly person: If you are helping to
                take care of an elderly relative or friend, you may need a
                caretaker certificate to show that you are authorized to make
                decisions on their behalf or to access their financial or
                medical records.
              </li>
              <li>
                If you are caring for someone with a disability: If you are
                assisting someone with a disability to manage their daily needs,
                you may need a caretaker certificate to prove that you are their
                designated caregiver. This can be especially important if you
                are seeking financial assistance or other support for their
                care.
              </li>
            </ul>
          </div>
          <div className="my-4">
            <h2 className="h5">Number of Caretaker Leaves</h2>
            <p>
              The number of days on caretaker leave can vary based on person's
              medical condition like medical emergency leave, maternity leave,
              covid recovery leave, medical surgery leave, etc. It also depends
              on sick leave policy, medical leave rules in workplace. But, main
              factors are below:
            </p>
            <ul>
              <li>The number of days requested</li>
              <li>The availability of supporting medical documentation</li>
              <li>
                The discretion of the{" "}
                <a
                  href="https://medoc.life/doctor-consultation"
                  className="text-orange-600 fw-600"
                >
                  doctor consultation
                </a>
              </li>
            </ul>
            <p>
              It's important to note that the ultimate decision regarding
              caretaker leaves is subject to the discretion of our medical
              practitioner.
            </p>
          </div>
          <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white box">
            <a
              href="https://app.medoc.life"
              target="_blank"
              rel="noopener noreferrer"
              className="h3 text-center text-white"
            >
              Get started with medoc, today.
            </a>
          </div>
          <div className="my-4">
            <h2 className="h5">Caretaker Certificate Format</h2>
            <p>
              A caretaker/caregiver certificate is a formal document that serves
              as proof that an individual was unable to attend work or school
              due to an illness. This certificate is usually issued by a
              healthcare professional such as a M.B.B.S doctor and confirms that
              the individual was indeed sick and unable to perform their duties.
            </p>
          </div>
          <div className="my-4">
            <h2 className="h5">
              Authenticity of Caretaker/Caregiver Certificate
            </h2>
            <p>
              With the telemedicine act of 2019, getting a medical certificate
              is now quick and convenient. Digital healthcare has made it
              possible for the doctors to issue medical certificates digitally
              after assessing the need and health condition of the customers.
            </p>
            <p>
              In India, patients are not much exposed to digital medical
              certificates, but laws and rules have been sanctioned including
              the Information Technology (IT) Act of 2000, the Indian Medical
              Council (Professional Conduct, Etiquette and Ethics) Regulations
              of 2002, and the Medical Council of India (MCI) guidelines on
              telemedicine for the acceptance and validity of the digital
              medical certificates in the form of sick leave, fitness, caretaker
              and recovery certificates.
            </p>
            <p>
              According to the provisions of the Telemedicine Act of 2019, the
              validity of a medical certificate can be determined by checking if
              it has the necessary information:
            </p>
            <ul>
              <li>Doctor name</li>
              <li>Doctor Registration number</li>
              <li>Doctor Signature</li>
              <li>Doctor Seal with Qualification</li>
              <li>Name of the concerned institution</li>
            </ul>
          </div>
          <div className="my-4">
            <h2 className="h5">Get Caretaker Certificate Online</h2>
            <p>A simple process to get a digital caretaker certificate:</p>
            <ul>
              <li>
                Answer Simple Questions - about yourself, your caretaker,
                medical condition, and upload Govt. ID documents
              </li>
              <li>
                Quick Doctor Review - a certified M.B.B.S doctor reviews your
                information and provide an online consultation
              </li>
              <li>
                Get Approved Caretaker Certificate - you'll receive the
                certificate via email as soon as review completes.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareTakerComponent;
