import React, { useState } from "react";
import moment from "moment";

import { getQuery, firestore } from "firebase_config";
import { Tables } from "constants/index";

const daysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const utils = {
  /**
   * Date doesn't matter
   * @param  {string} time
   * @returns {number} return milliseconds of the date created with time parameter
   */
  timeToMilliseconds(time) {
    const [hours, mins] = time.split(":");
    const updateHours = new Date().setHours(hours);
    const updateMinsAndHours = new Date(updateHours).setMinutes(mins);
    return updateMinsAndHours;
  },
  /**
   * @param {Date} Date object
   * @return {string} formatted date i.e. 2020-02-23
   */
  getAndFormatDate(date) {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  },
  /**
   * @param  {number | string} hours
   * @param  {number | string} mins
   * @returns {string} returns formatted time i.e. 09:00, 18:30
   */
  getAndFormatTime(hours, mins) {
    return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
  },
};

const ScheduleAppointmentComponent = ({ profile, handleChange }) => {
  const [date, setDate] = useState("");
  const [slots, setSlots] = useState([]);
  const [time, setTime] = useState("");
  const [bookedSlot, setBookedSlot] = useState("");

  /* Disable today & previous dates */
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  const minDate = tomorrow.toISOString().split("T")[0];

  /* Outside functions */
  // const bookedDates = {};
  const averageTimeTakenInMins = 30;

  async function fetchAvailableSlots(date) {
    const totalTimeSlots = getTotalTimeSlots(date);
    const reservedTimeSlots = await getReservedTimeSlots(date);

    const availableSlots = totalTimeSlots.map((slot) => {
      if (reservedTimeSlots.includes(slot)) return { available: false, slot };
      return { available: true, slot };
    });

    return availableSlots;
  }

  async function getBookedSlotsFromDb(date) {
    const res = await getQuery(
      firestore
        .collection(Tables.DC_PUBLIC_APPOINTMENTS)
        .where("doctorId", "==", profile.userId)
        .where("bookedSlotDate", "==", date)
        .get()
    );
    return res.map((ap) => ap.bookedSlotTimestamp[date]);
  }

  async function getReservedTimeSlots(date) {
    const reservedSlots = await getBookedSlotsFromDb(date); // bookedDates[date] ? bookedDates[date] : [];

    return reservedSlots.map((slot) =>
      utils.getAndFormatTime(
        new Date(slot.seconds * 1000).getHours(),
        new Date(slot.seconds * 1000).getMinutes()
      )
    );
  }

  function getTotalTimeSlots(date) {
    const totalTimeSlots = [];
    const day = new Date(date).getDay(); // get day
    const { openingTime, closingTime } = getOpeningTimes(daysOfWeek[day]);
    const _openingTime = utils.timeToMilliseconds(openingTime);
    const _closingTime = utils.timeToMilliseconds(closingTime);

    if (openingTime >= closingTime) {
      throw new Error("Opening time cannot be greater than closing time");
    }
    for (
      let i = _openingTime;
      i < _closingTime;
      i = new Date(i + averageTimeTakenInMins * 60000).getTime()
    ) {
      totalTimeSlots.push(
        utils.getAndFormatTime(new Date(i).getHours(), new Date(i).getMinutes())
      );
    }
    return totalTimeSlots;
  }

  function getOpeningTimes(day) {
    const obj = profile.availabilities.find((avail) => avail.day === day);
    return {
      openingTime: obj.startTime,
      closingTime: obj.endTime,
    };
  }

  /* Outside Functions */

  function handleSubmit(t) {
    const [hours, mins] = t.split(":");
    const newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(mins);

    setBookedSlot(newDate);
    handleChange("bookedSlot", moment(newDate).format("DD-MM-YYYY, HH:mm"));
    handleChange("bookedSlotDate", moment(newDate).format("YYYY-MM-DD"));
    handleChange("bookedSlotTimestamp", {
      [date]: newDate,
    });
  }

  async function handleDateChange(e) {
    setSlots([]);
    setTime("");

    const { value } = e.target;
    setDate(value);
    const as = await fetchAvailableSlots(value);
    setSlots(as);
  }

  function handleTimeChange(e) {
    const { value } = e.target;
    setTime(value);
    handleSubmit(value);
  }
  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        <h6>Select date</h6>
        <input
          type="date"
          min={minDate}
          value={date}
          onChange={handleDateChange}
          className="form-control pointer col-6"
        />
        <div className={`mt-3 ${!date && "d-none"}`}>
          <h6>Available Slots</h6>
          <div className="time-slot-wrapper">
            {slots.length > 0 &&
              slots.map(
                ({ slot, available }) =>
                  available && (
                    <button
                      className={`btn mr-2 my-1 ${
                        slot === time ? "btn-violet" : "btn-violet-outline"
                      }`}
                      onClick={handleTimeChange}
                      key={slot}
                      value={slot}
                    >
                      {slot}
                    </button>
                  )
              )}
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 mt-3 mt-sm-0">
        <h6>Selected Date & Time</h6>
        <div className="btn btn-violet">
          {date}
          {new Date(bookedSlot).toLocaleString().split(",")[1]}
        </div>
      </div>
    </div>
  );
};

export default ScheduleAppointmentComponent;
