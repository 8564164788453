import React from "react";
import "./style.css";

const SectionTwoComponent = ({ smallDevice }) => {
  return (
    <>
      <div className="numbers-wrapper py-5">
        <h2 className="text-center mb-5 text-white">Medoc, In numbers...</h2>
        <div className="d-flex justify-content-center">
          <div
            className="number-card pt-3 my-3 mx-sm-5"
            data-aos="zoom-in-down"
            data-aos-duration="1500"
          >
            <br /> <span className="fw-600">15,000+</span> <br />
            <span>Users</span>
          </div>
          <div
            className="number-card pt-3 my-3 mx-sm-5"
            data-aos="zoom-in-down"
            data-aos-duration="1000"
          >
            <br /> <span className="fw-600">500+</span> <br />
            <span>Companies</span>
          </div>
          <div
            className="number-card pt-3 my-3 mx-sm-5"
            data-aos="zoom-in-down"
            data-aos-duration="500"
          >
            <br />
            <span className="fw-600">4,000+</span> <br />
            <span>Cities</span>
          </div>
          <div
            className="number-card pt-3 my-3 mx-sm-5"
            data-aos="zoom-in-down"
            data-aos-duration="2000"
          >
            <br /> <span className="fw-600">4.7 / 5</span> <br />
            <span>Rating</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionTwoComponent;
