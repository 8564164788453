import React from "react";
import "./style.css";

import { Routes } from "constants/index";

const treatments = [
  {
    name: "Acne",
  },
  {
    name: "Cold & Cough",
  },
  {
    name: "Erectile Dysfunction",
  },
  {
    name: "Flu & Fever",
  },
  {
    name: "Mental Health",
  },
  {
    name: "Premature Ejaculation",
  },
  {
    name: "Migraine",
  },
];

const SectionTwoComponent = ({ smallDevice }) => {
  return (
    <div id="section-two" className="section-two-wrapper container">
      <div>
        <h1 className="mt-5 header">We help you...</h1>
        <div className="row">
          <div className="m-0">
            {treatments.map((t, i) => (
              <a
                href={Routes.LOGIN}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-violet-outline py-3 px-4 mx-4 my-3"
                key={i}
              >
                {t.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwoComponent;
