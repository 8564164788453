import React, { useState, useEffect } from "react";

import HeaderComponent from "./header";
import SectionOneComponent from "./section_one/component";
import SectionOneTwoComponent from "./section_one_two/component";
import SectionTwoComponent from "./section_two/component";
import SectionFourComponent from "./section_four/component";
import { StaticFooterComponent } from "components";
import "./style.css";

const HomeGlobalComponent = () => {
  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  return (
    <div className="home-wrapper">
      <HeaderComponent />
      <SectionOneComponent smallDevice={smallDevice} />
      <div
        className="trustpilot-widget text-center my-4 mb-0"
        data-locale="en-UK"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="63489030a91712093d8e88e0"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
        data-min-review-count="10"
        data-without-reviews-preferred-string-id="1"
        data-style-alignment="center"
      >
        <a
          href="https://www.trustpilot.com/review/medoc.life"
          target="_blank"
          rel="noopener noreferrer"
          className="text-black fw-500"
        >
          <span className="fw-600">Great 4.0</span> out of 5 &nbsp;
          <img
            src="https://storage.googleapis.com/medoc-live.appspot.com/images/trustpilot.png"
            width="35"
            className="tp-icon"
          />
          Trustpilot.
        </a>
      </div>
      <hr />
      <SectionOneTwoComponent smallDevice={smallDevice} />
      <SectionTwoComponent smallDevice={smallDevice} />
      <SectionFourComponent smallDevice={smallDevice} />
      <StaticFooterComponent />
    </div>
  );
};

export default HomeGlobalComponent;
