import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./style.css";

import { StaticHeaderComponent, StaticFooterComponent } from "components";
import { Routes } from "constants/index";

const TncComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Medoc | Terms and Conditions | Understanding the User Agreement for
          Our Medical Certificate Platform in India
        </title>
        <link rel="canonical" href="https://medoc.life/tnc" />
        <meta
          name="description"
          content="Familiarize yourself with our medical certificate platform's terms and conditions in India. Our user agreement outlines the legal terms of using our services."
        />
        <meta
          name="keywords"
          content="medical certificate online,doctor consultation,digital healthcare,India,terms and conditions,user agreement,legal terms"
        />
        <meta
          property="og:title"
          content="Medoc | Terms and Conditions | Understanding the User Agreement for Our Medical Certificate Platform in India"
        />
        <meta
          property="og:description"
          content="Familiarize yourself with our medical certificate platform's terms and conditions in India. Our user agreement outlines the legal terms of using our services."
        />
        <meta property="og:url" content="https://medoc.life/tnc" />
        <meta property="og:site_name" content="medoc.life" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/razorpay_logo.jpeg"
        />
        <meta property="og:image:width" content="590" />
        <meta property="og:image:height" content="615" />
        <meta
          property="article:modified_time"
          content="2023-2-2T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medoc | Terms and Conditions | Understanding the User Agreement for Our Medical Certificate Platform in India"
        />
        <meta
          name="twitter:description"
          content="Familiarize yourself with our medical certificate platform's terms and conditions in India. Our user agreement outlines the legal terms of using our services."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="15 minutes" />
      </Helmet>

      <StaticHeaderComponent />
      <div className="container tnc-wrapper">
        <h3 className="page-title">Terms of use</h3>
        <div>
          Read our terms below to learn more about your rights and
          responsibilities as a{" "}
          <Link to={Routes.DEFAULT} className="fw-500">
            Medoc.life
          </Link>{" "}
          user.
        </div>
        <hr />
        <p>
          This document is an electronic record in terms of Information
          Technology Act, 2000, allied Rules and Applicable Laws. This
          electronic record is generated by a Computer System and does not
          require any physical or Digital Signatures.
        </p>
        <p>
          This document is published in accordance with the provisions of Rule 3
          (1) of the Information Technology (Intermediaries Guidelines) Rules,
          2011 that require publishing the Rules and Regulations, Privacy Policy
          and Terms of Use for access or usage of Medoc's website.
        </p>
        <ol className="pl-3">
          <li>
            <b className="pp-header">Overview</b>
            <p className="pp-body">
              Thank you for using Medoc. We facilitate the provision of health
              services, including telehealth consultations, from health
              practitioners (Health Practitioners) to individuals (the
              Services). Our online platform consists of our website located at
              https://medoc.life (Platform), which is owned and operated by
              Perla InfoTech Pvt Ltd (Medoc, we, us, our). Please read these
              terms and conditions (Terms) carefully as they form a contract
              between Platform users (User, you) and us (Agreement). By using
              the Platform, you agree with and accept the Terms in this
              Agreement. This Agreement, our Privacy Policy and all policies and
              guidelines published on the Platform from time to time form the
              entire agreement between you and us. If you do not accept any
              terms of the Agreement, you must cease using the Platform
              immediately. We reserve the right to amend the Terms at any time,
              and by continuing to use the Platform, you accept the Terms as
              they apply from time to time.
            </p>
          </li>
          <li>
            <b className="pp-header">Scope of Our Platform</b>
            <p className="pp-body">
              2.1 Overview (a) Our Platform connects individuals with Health
              Practitioners for the purposes of running telehealth
              consultations. (b) Through the Platform, Health Practitioners can
              provide a range of health services to individuals, if the Health
              Practitioner considers it appropriate and in line with their legal
              obligations. 2.2 Our role (a) We act as the developer and provider
              of the Platform and our role is limited to: (i) facilitating your
              access to and use of the Platform; (ii) taking feedback about the
              Platform; and (iii) improving and modifying the Platform. (b) By
              accessing and using the Platform, you agree and acknowledge that:
              (i) we do not offer or provide any medical or health services, or
              employ any Health Practitioners to provide health services to
              individuals; (ii) we are not responsible for, and have no control
              over the use of the Platform by other Users; (iii) we are not
              responsible for and do not control, any consultation, any health
              services or the accuracy of information provided by Health
              Practitioners; and (iv) we reserve the right, but are not obliged,
              to monitor, verify, modify or delete, any material or information
              created, generated or transmitted by Users through the Platform
              (collectively, User Content) and we do not control the accuracy of
              User Content.
            </p>
          </li>

          <li>
            <b className="pp-header">Registration and Access to Platform</b>
            <p className="pp-body">
              3.1 Requests for health services via the Platform (a) To use the
              Services, you must accept these Terms and our Privacy Policy;
              which forms a contractual relationship between you and us. (b) To
              use our Platform, you must: (i) possess the legal right and
              ability to enter into a legally binding agreement; and (ii)
              provide complete and accurate information to all the items in the
              request page of the Platform, which includes your full name, date
              of birth, contact details (including email address and phone
              number), credit or debit card details, and medical history
              (Request Information). (c) The information you provide us through
              the Platform, including but not limited to your Request
              Information, must be accurate, complete and up to date. We are not
              obliged to confirm the identity of Users but may, at our
              discretion, take reasonable steps to ensure details are accurate.
              (d) You acknowledge and agree that if the information that you
              provide to us is incomplete or inaccurate the Health Practitioners
              may not be able to provide their health services to you. (e) We
              reserve the right to refuse to process any User requests for any
              reason at our sole discretion or to deny anyone access to a
              subscription or the Platform at any time and for any reason,
              without notice. 3.2 Account (a) Where the option is made available
              to you through the Platform, you may register for an account with
              us (Account) to allow you to receive the Services and manage any
              health services that you receive from Health Practitioners. (b) To
              register for an Account, you must provide complete and accurate
              information to all the items in the Account registration page of
              the Platform (Registration Information). Your Registration
              Information must be complete, accurate and up-to-date and you must
              update your Registration Information through the functionality of
              your Account if it changes. (c) When registering for an Account,
              you must nominate a username and password. You are responsible for
              maintaining the confidentiality and integrity of the Account, the
              password and for all use and activity carried out on your Account.
              If you believe that there has been unauthorised access to your
              Account, please contact us. (d) We reserve the right to deny
              anyone access to an Account at any time and for any reason,
              without notice. 3.3 Use (a) By accessing and using the Platform as
              a User, you represent and warrant that: (i) you are at least 18
              years of age; (ii) you are currently living in India and have an
              Indian residential address; (iii) you have the legal right,
              authority and capacity to agree to and abide by the Terms of the
              Agreement; and (iv) any information that you provide to us or a
              Health Practitioner through the Platform will be accurate,
              complete and up-to-date. (b) By accessing and using the Platform
              as a User, you agree and acknowledge that: (i) we are not a party
              to, or a participant in, any contractual relationship between
              Users and/or Health Practitioners; (ii) we do not guarantee the
              existence, availability, suitability, legality or safety of the
              Platform; (iii) we do not provide a health service and any
              communication from Medoc or its employees does not constitute
              medical advice or a health service; (iv) the Health Practitioners
              that we connect Users to are not employees or representatives of
              Medoc; (v) consultations are between Health Practitioners and
              Users and we are not responsible for any information or services
              provided in the consultations, and do not warrant that the
              consultations are suitable for Users; (vi) the Health
              Practitioners have full and absolute discretion in determining
              whether a consultation or other health service provided via the
              Platform is suitable for you; (vii) we do not guarantee that a
              Health Practitioner will issue you with a medical certificate or
              provide any other health service to you following a consultation
              via the Platform; (viii) we are not responsible for the
              relationship between Users and Health Practitioners; (ix) we are
              not a health service provider or regulated health service under
              the National Law; and (x) your access to and use of the Platform
              is non-transferable. (c) By submitting a request for health
              services, you agree that we may send you text (SMS) messages, push
              notifications and emails as part of the normal business operation
              of your use of the Platform. You may opt-out of receiving these
              communications from us by contacting us and you acknowledge that
              opting out of receiving these communications may impact your use
              of our Platform. 3.5 Medical certificates (a) Neither we, nor the
              Health Practitioners using our Platform, guarantee that a medical
              certificate will be provided. Provision of any health services
              (including a telehealth consultation and/or provision of a medical
              certification) remains at all times at the sole and complete
              discretion of the Health Practitioner. (b) If the Health
              Practitioner considers it appropriate to provide a medical
              certificate, we will deliver the medical certificate to you by the
              method and in the format you choose at check-out, being either by
              post, by email or by SMS. If you have an Account, you may also
              view your medical certificates through your Account dashboard.
            </p>
          </li>
          <li>
            <b className="pp-header">Use of Platform</b>
            <p className="pp-body">
              4.1 Permitted Use (a) You may view the Platform using a web
              browser or mobile device and copy or print hard copies of parts of
              the Platform solely for the purposes provided for in these Terms.
              (b) Any other use, including the modification, distribution,
              transmission, republication, display or performance of the content
              on the Platform, except as provided for under these Terms, is
              strictly prohibited. 4.2 Your Obligations You represent and
              warrant to: (a) use the Platform in accordance with these Terms
              and for lawful purposes only; (b) comply with applicable laws and
              regulations; and (c) contact your general practitioner immediately
              if directed to by a Health Practitioner. 4.3 Limitations In
              accessing the Platform, you represent and warrant that you will
              not: (a) modify or copy the layout of the Platform or any computer
              software and code contained in the Platform; (b) commit any act or
              engage in any practice that: (i) is harmful to our systems,
              reputation or goodwill; or (ii) interferes with or disrupts the
              integrity of the Platform, including but not limited to, by
              hacking, transmitting any viruses, spyware, malware or any other
              unauthorised malicious code of a destructive or disruptive nature;
              (c) create accounts with us through unauthorised means, including
              by using an automated device, script, bot or other similar means;
              (d) restrict, or attempt to restrict, another User from using or
              enjoying the Platform; (e) interfere with the privacy of another
              person or harvest or otherwise collect information about others,
              including Registration Information, without their consent; (f)
              infringe any intellectual property rights or any other contractual
              or proprietary rights of another person; (g) bypass any measures
              used to prevent or restrict access to our Platform; (h) do any
              act, engage in any practice or omit to do any act or engage in any
              practice that: (i) is or could reasonably be considered obscene,
              illegal, offensive, inappropriate, defamatory, indecent,
              threatening or objectionable in any way; (ii) would cause us to
              breach or to be taken to breach a law; (iii) would bring us into
              disrepute; or (iv) interferes with the integrity or supply of the
              Platform to all Users; (i) encourage or facilitate violations of
              these Terms; (j) distribute or send communications that contain
              spam, chain letters, or pyramid schemes; or (k) harass,
              intimidate, act violently or inappropriately towards or be
              discriminatory against another User.
            </p>
          </li>
          <li>
            <b className="pp-header">Use of Products</b>
            <p className="pp-body">
              4.1 Permitted Time (a) The doctors availability may change
              dynamically. Medoc is not responsible for delays in certificate or
              lack of communications. 4.2 Fast delivery service: (a) the time
              that is mentioned during product purchase is tentative. In any
              case, if doctor required additional information then it may take
              longer than mentioned product to complete the certificate request.
              4.3 Limitations In communications: (a) doctors communicate via
              medoc platform only. Use of third party communications is strictly
              prohibited and any change of this condition is subject to doctors
              and medoc team only.
            </p>
          </li>
          <li>
            <b className="pp-header">Payment Terms</b>
            <p className="pp-body">
              5.1 Fees (a) We may charge fees to Users in consideration for us
              making the Services available. (b) Fees may be one-off (One-off
              Fees) or for an annual subscription (Subscription Fees)
              (collectively, Fees). (c) We will advise you of any applicable
              Fees (including any applicable GST) when you are requesting a
              health service through our Platform. Our Fee rates can be found on
              our website and Platform, prior to check out. (d) Fees are payable
              in advance and are non-refundable, except as expressly provided in
              these Terms. (e) Fees and all other fees, charges and prices are
              stated in Indian dollars and are exclusive of applicable taxes,
              unless otherwise stated. Fees may be converted to your local
              currency at the time of payment. We will be entitled to add on GST
              for any supply in India. (f) We reserve the right to change the
              Fees at any time and we will provide Users with reasonable notice
              of any fee changes before they become effective. (g) You are
              responsible for paying all Fees and taxes and we reserve the right
              to charge you such applicable taxes. 5.2 Payment method (a) Fees
              must be paid in advance with a valid credit card. (b) Subscription
              Fees must be paid monthly or annually in advance in accordance
              with the option selected by you when you subscribe, with a valid
              credit card and access to the Platform is conditional on timely
              payment of all Fees by you. (c) All credit card payments are
              subject to validation checks and authorisations by the card
              issuer. If the issuer of your payment card refuses to or does not
              for any reason authorise payment of the Fees, the payment will not
              be processed. (d) If any applicable Subscription Fee is not paid
              to us or automatically debited for any reason by the due date, we
              may, at our discretion: (i) cancel your Account or personalised
              subscription link; or (ii) temporarily suspend your access to all
              or some of the features of the Platform until such time as payment
              of the Subscription Fee is received by us in full. 5.3 Refund of
              Fees (a) If the Health Practitioner that you are connected with
              determines, in their sole and absolute discretion, that telehealth
              is not appropriate in your circumstances, then we will refund the
              Fees paid by you in full within a reasonable timeframe. (b) If you
              have an annual subscription and you cancel your subscription under
              clause 13 or if we cancel your subscription as a result of you
              breaching these Terms, then any Subscription Fees paid will not be
              refunded and any outstanding Subscription Fees and other fees
              become immediately due and payable upon cancellation. (c) If we
              terminate your Account, subscription or access to or operation of
              the Platform for any other reason and you have paid applicable
              Subscription Fees in respect of the period after cancellation, we
              will refund any Subscription Fees you have paid on a pro rata
              basis to a bank account notified by you for that purpose within a
              reasonable time of cancellation.
            </p>
          </li>
          <li>
            <b className="pp-header">Intellectual Property Rights</b>
            <p className="pp-body">
              (a) In these Terms, Intellectual Property Rights means all
              intellectual property rights, including all copyright, patents,
              trade marks, design rights, trade secrets, circuit layouts, domain
              names, know-how and other rights of a similar nature worldwide,
              whether registered or not, and any applications for registration
              or rights to make such an application. (b) We own or are the
              licensee of all rights, title and interest (including Intellectual
              Property Rights) in the Platform or to the material (including all
              text information and content, graphics, logos, type forms and
              software) made available to you on the Platform (collectively,
              Platform Content). Your use of and access to the Platform does not
              grant or transfer any rights, title or interest to you in relation
              to the Platform Content. (c) You may access the Platform using a
              web browser or mobile device, and electronically copy and print
              hardcopy the Platform Content solely for your personal,
              non-commercial use. (d) You must not modify, copy, distribute,
              transmit, display, perform, reproduce, publish, license,
              commercially exploit, reverse engineer, create derivative works
              from, transfer, or sell any Platform Content or any other material
              in whatever form contained within the Platform unless expressly
              stated otherwise in these Terms. (e) To the extent that the
              Platform uses any open source or third-party code that may be
              incorporated into the Platform, such access to the open source
              code is provided subject to the terms imposed by the licensor of
              that open source code. You acknowledge and agree that any open
              source code is made available "as is", without any warranty from
              us.
            </p>
          </li>
          <li>
            <b className="pp-header">User Content</b>
            <p className="pp-body">
              7.1 General (a) We reserve the right, but are not obliged, to
              monitor, review, verify, edit, modify or delete material, content,
              data or information created, generated or transmitted by Users
              through the Platform (User Content) and we do not control the
              accuracy of User Content. (b) We do not claim ownership of any
              User Content. You grant us a perpetual, non-exclusive,
              royalty-free, irrevocable, worldwide, assignable and transferable
              licence to use, reproduce, modify, copy, store and share the User
              Content, for our business or commercial purposes. (c) You consent
              to any act or omission which would otherwise constitute an
              infringement of your moral rights. If a third party has moral
              rights in your User Content, you must ensure the third-party
              consents in the same manner. (d) The views expressed in any User
              Content are the views of Users and not those of us unless
              specified otherwise. We are not responsible for and disclaim all
              liability in respect of any User Content. We encourage you to
              report problems, offensive content or comments and malicious use
              of the Platform to us. 7.2 Security of content (a) We will take
              all reasonable steps to implement, maintain and enforce security
              procedures and safeguards to protect the security, confidentiality
              and integrity of the User Content from unauthorised access or use
              by a third party or misuse, damage or destruction by any person.
              (b) However, we do not guarantee and cannot ensure the security of
              User Content and to the extent permitted by law, we expressly
              exclude liability for any such loss, however caused. 7.3
              Prohibited content You must not create, upload or generate any
              User Content: (a) unless you hold all necessary rights, licences
              and consents to do so; (b) that would cause you or us to breach
              any law, regulation, rule, code or other legal obligation; (c)
              that we consider inappropriate, offensive, abusive, indecent or
              illegal; or (d) that infringes the rights, including intellectual
              property rights, of any third party.
            </p>
          </li>
          <li>
            <b className="pp-header">Third Party Links</b>
            <p className="pp-body">
              The Platform may contain links and other pointers to websites or
              applications owned and operated by third parties. We do not
              control these linked websites and are not responsible for the
              contents of any linked website. Your access to any such website is
              entirely at your own risk. You should contact the relevant
              third-party directly to enquire as to the accuracy and
              completeness of that information prior to entering into a
              transaction in relation to the third-party products and services.
            </p>
          </li>
          <li>
            <b className="pp-header">Disclaimer</b>
            <p className="pp-body">
              To the fullest extent permitted by law, you agree and acknowledge
              that: (a) the Platform is provided “as is” and “as available” and
              the entire risk arising out of your use of the Platform remains
              solely with you; (b) we do not control, endorse and are not
              responsible for any User Content; (c) we retain complete control
              over the Platform and may alter, amend or cease the operation of
              the Platform in our sole discretion; (d) we make no warranty or
              representation that any result or objective can or will be
              achieved or attained by accessing the Platform; (e) we make no
              warranty or representation that the Platform will be continuous,
              uninterrupted, accurate, fault-free, virus-free, secure or
              accessible at all times; (f) we may remove any content, material
              and/or information, including any User Content, without giving any
              explanation or justification for removing the material and/or
              information; (g) you are responsible for considering the
              appropriateness of the Platform, its services and any Platform
              Content, for your intended application and use and we give no
              warranty, guarantee or representation that the Platform, its
              services or the Platform Content is suitable for or meets your
              requirements; and (h) we reserve the right, but are not obliged,
              to monitor or become involved in any dispute between Users.
            </p>
          </li>
          <li>
            <b className="pp-header">Exclusions and limitation of liability</b>
            <p className="pp-body">
              (a) To the fullest extent permitted by law, we are not liable to
              you or anyone else for any loss or damage you may suffer or incur
              in connection with: (i) your access to and use of the Platform and
              Platform Content; (ii) your inability to access or use the
              Platform and Platform Content or any of the health services
              provided by Health Practitioners via the Platform; (iii) any User
              Content; (iv) any interactions between you and a Health
              Practitioner; and (v) unauthorised creation, access or use of your
              personal information, Account or your User Content, even if we
              have been advised of the possibility of such loss. (b) To the
              fullest extent permitted by law, we exclude: (i) liability for
              special, indirect or consequential damages, including damages for
              loss of data, reputation, goodwill and opportunity, loss of or
              claim for, revenue, profits, actual or potential business
              opportunities or anticipated savings or profit; and (ii) all
              representations, warranties or terms (whether express or implied)
              other than those set out in these Terms. (c) Pursuant to s64A of
              Schedule 2 of the Indian Consumer Law, this clause 10(c) applies
              in respect of services that are not of a kind ordinarily acquired
              for personal, domestic or household use or consumption. Our
              liability for breach of a guarantee conferred by the Indian
              Consumer Law (other than those conferred by s51 to s53 of the
              Indian Consumer Law) is limited at our option to: (i) the
              re-supply of the services or products; or (ii) the payment of the
              direct cost of having the services or products resupplied.
            </p>
          </li>
          <li>
            <b className="pp-header">Indemnity</b>
            <p className="pp-body">
              (a) You agree to indemnify us for all losses, damages,
              liabilities, claims and expenses (including reasonable legal
              costs) incurred by us arising out of or in connection with your
              use of the Platform and Platform Content, any User Content, your
              breach of the Terms or any rights of third parties. (b) We reserve
              the right to assume exclusive control of any matter for which you
              are required to indemnify us, and you agree to provide assistance
              at your expense for the purposes of defending and managing all
              such claims as is reasonably requested by us.
            </p>
          </li>
          <li>
            <b className="pp-header">Dispute Resolution</b>
            <p className="pp-body">
              (a) A party must not commence legal proceedings relating to this
              Agreement unless the party wishing to commence proceedings has
              complied with this clause 12. However, this clause will not apply
              where a party seeks urgent interlocutory relief from a court. (b)
              If a dispute arises between the parties out of or relating to this
              Agreement, then: (i) the party alleging the dispute must notify
              the existence and nature of the dispute to the other party within
              10 days of the dispute arising (Dispute Notice); (ii) upon receipt
              of a Dispute Notice, the parties must, within 5 days, engage in
              good faith negotiations and use best endeavours to resolve the
              dispute; (iii) if the dispute is not resolved as provided in
              paragraph (ii) within 5 days of receipt of the Dispute Notice,
              then either party may refer the dispute to mediation as provided
              in paragraph (iv) and must do so before initiating proceedings in
              a court to resolve the dispute; (iv) any dispute which is referred
              to mediation must be referred to the Resolution Institute and must
              be conducted in accordance with the Mediation Rules of the
              Resolution Institution or the parties may appoint a mediator by
              mutual agreement; and (v) if the dispute is not resolved within 30
              days of referral to mediation, any party is free to initiate
              proceedings in a court in respect of the dispute.
            </p>
          </li>
          <li>
            <b className="pp-header">Termination</b>
            <p className="pp-body">
              (a) Where you are a one-off user, this Agreement will terminate
              upon delivery of the Health Practitioner’s services to the User
              via the Platform. (b) Subscribed Users may terminate this
              Agreement by providing notice to us by email or through your
              Account functionality if you have registered for an Account.
              Termination will be effective upon our receipt of the notice,
              following which, we will disable the User’s subscription at the
              end of the month within which the notice or cancellation is
              received. (c) We may suspend your Account, subscription or your
              access to or use of the Platform, or any portion of the Platform
              and/or terminate this Agreement immediately by providing notice to
              you by email, if we believe that you have breached the terms of
              this Agreement, or for any other reason in our sole discretion,
              subject to clause 13(d) below. We may disable your access to your
              Account and personalised subscription link immediately on
              providing notice to you if we believe your conduct is likely to
              interfere with the Platform, or the rights of any third parties.
              Otherwise, the termination will be effective at the end of the
              month within which the notice is provided to you. (d) Users shall
              remain obligated to complete any outstanding payment for any
              Subscription Fee or other amounts under this Agreement which arose
              before the Agreement termination date. (e) We reserve the right to
              cease operating the Platform, or any part of the Platform, without
              notice and for any reason. (f) On expiry or termination of this
              Agreement or if clause (e) applies: (i) access to your Account or
              personalised subscription link will expire or cease; and (ii) we
              will not be liable for any costs, losses or damages arising as a
              result of terminating your access to the Platform. (g) We are not
              liable for any costs, losses or damages of any kind arising as a
              consequence of terminating your access to the Platform.
            </p>
          </li>
          <li>
            <b className="pp-header">Privacy</b>
            <p className="pp-body">
              We will collect, use and disclose any personal information you
              provide us when accessing or using the Platform in accordance with
              our Privacy Policy. For more information on our information
              collection and handling practices, please view our Privacy Policy.
            </p>
          </li>
          <li>
            <b className="pp-header">General</b>
            <p className="pp-body">
              15.1 Variation We may vary, amend, or otherwise modify these Terms
              at any time. We will publish the new Terms on the Platform and on
              our website at which time they will be effective. Your continued
              use of the Platform following the posting of the new Terms
              constitutes your acceptance of the new Terms. 15.2 No Waiver No
              waiver of rights under this Agreement shall constitute a
              subsequent waiver of this or any other right under this agreement.
              Any failure on our part to enforce any right or provision of these
              Terms will not constitute a waiver of future enforcement of that
              right or provision. 15.3 Severability If any provision of our
              Terms is unenforceable or invalid, it will be ineffective to the
              extent it is unenforceable or invalid and will not affect the
              enforceability or validity of the remaining provisions. 15.4
              Governing law and jurisdiction This Agreement is governed by the
              laws in force in Andhra Pradesh and the parties submit to the
              non-exclusive jurisdiction of the courts of Andhra Pradesh. 15.5
              Contact us Your feedback is important to us and our community. We
              welcome and encourage you to provide feedback, reviews, comments,
              and suggestions for improvements to the Platform (Feedback). You
              may submit Feedback by contacting us via contact@medoc.life
            </p>
          </li>
        </ol>
        Last updated on October, 2022
      </div>
      <StaticFooterComponent />
    </>
  );
};

export default TncComponent;
