import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "./style.css";

import { StaticHeaderComponent } from "components";
import { add } from "firebase_config";
import { Routes, Tables } from "constants/index";

const HomeComponent = () => {
  const [smallDevice, setSmallDevice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employer, setEmployer] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  const handleChange = (key, value) => {
    setEmployer({
      ...employer,
      [key]: value,
    });
  };

  const submitRrequest = async () => {
    if (!employer.name) {
      toast.error("Full name is required");
      return;
    } else if (!employer.email) {
      toast.error("Email is required");
      return;
    } else if (!employer.message) {
      toast.error("Message is required");
      return;
    }
    setLoading(true);
    const res = await add(Tables.CLIENT_REQUESTS, employer);
    console.log("res", res);
    if (res.status === 200) {
      toast.success(
        "Thank You. Our team will reach out to you shortly on email."
      );
      setEmployer("");
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Medoc | Contact Us | Get in Touch with Our Customer Service on Our
          Online Medical Certificates Platform in India
        </title>
        <link rel="canonical" href="https://medoc.life/contact-us" />
        <meta
          name="description"
          content="Need assistance with our online medical certificates platform in India? Contact us for support and customer service. Submit your inquiry today."
        />
        <meta
          name="keywords"
          content="medical certificate online,doctor consultation,digital healthcare,India,contact us,support,customer service,inquiry"
        />
        <meta
          property="og:title"
          content="Medoc | Contact Us | Get in Touch with Our Customer Service on Our Online Medical Certificates Platform in India"
        />
        <meta
          property="og:description"
          content="Need assistance with our online medical certificates platform in India? Contact us for support and customer service. Submit your inquiry today."
        />
        <meta property="og:url" content="https://medoc.life/contact-us" />
        <meta property="og:site_name" content="medoc.life" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/medoc-live.appspot.com/images/razorpay_logo.jpeg"
        />
        <meta property="og:image:width" content="590" />
        <meta property="og:image:height" content="615" />
        <meta
          property="article:modified_time"
          content="2023-2-2T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medoc | Contact Us | Get in Touch with Our Customer Service on Our Online Medical Certificates Platform in India"
        />
        <meta
          name="twitter:description"
          content="Need assistance with our online medical certificates platform in India? Contact us for support and customer service. Submit your inquiry today."
        />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="15 minutes" />
      </Helmet>

      <div className="home-wrapper">
        <StaticHeaderComponent smallDevice={smallDevice} />
        <div className="container pt-3 pt-sm-5 mt-sm-5">
          <div className="row pt-5 mt-5">
            <div className="col-12 col-sm-6">
              <h2>Our support team is always for you!</h2>
              <p className="pt-3">
                It'll take upto 24 hours to respond to your request. For any
                queries, email us to{" "}
                <a href="mailto:contact@medoc.life" className="text-violet">
                  contact@medoc.life
                </a>{" "}
                or{" "}
                <Link to={Routes.DEFAULT} className="fw-500 text-violet">
                  Get Your Certificate Here
                </Link>
              </p>
            </div>
            {employer ? (
              <div className="col-12 col-sm-6 pt-3 pt-sm-0">
                <div className="">
                  <label className="text-violet fw-500">
                    Full name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control font-small"
                    name="form-question"
                    placeholder="Full name"
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                </div>
                <div className="">
                  <label className="text-violet mt-3 fw-500">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control font-small"
                    name="form-question"
                    placeholder="Email"
                    onChange={(e) => handleChange("email", e.target.value)}
                  />
                </div>
                <div className="">
                  <label className="text-violet mt-3 fw-500">
                    Message <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control font-small"
                    name="form-question"
                    placeholder="Message"
                    onChange={(e) => handleChange("message", e.target.value)}
                  />
                </div>
                <div
                  className="btn btn-violet mt-4 btn-sm"
                  onClick={submitRrequest}
                >
                  Submit Request
                </div>{" "}
                {loading && <div className="spinner-border"></div>}
              </div>
            ) : (
              <div className="col-12 col-sm-6 pt-3 pt-sm-0 text-center text-violet fw-500">
                Your request received. Our team will contact you shortly!
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeComponent;
