import React from "react";

const PricingComponent = ({ smallDevice }) => {
  return (
    <div className="pricing-wrapper container-fluid">
      <h4 className="text-center fw-600">Pricing</h4>
      <h2 className="py-4">0 INR/-</h2>
      <p className="text-center">
        No sign up or monthly fees. Our software is completely free of cost.
      </p>
    </div>
  );
};

export default PricingComponent;
