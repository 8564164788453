import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./style.css";

import { getQuery, firestore } from "firebase_config";
import { Tables } from "constants/index";

import {
  StaticHeaderTwoComponent,
  StaticFooterComponent,
  ClinicsComponent,
} from "components";
import AboutMeComponent from "./about_me/component";
import ServicesComponent from "./services/component";
import AppointmentDetailsComponent from "./appointment_details/component";

const DoctorProfileComponent = (props) => {
  const username = props.match.params.username;
  const [profile, setProfile] = useState();

  useEffect(() => {
    async function getProfile() {
      const pro = await getQuery(
        firestore
          .collection(Tables.DC_PUBLIC_PROFILE)
          .where("username", "==", username)
          .get()
      );
      if (pro[0]) {
        setProfile(pro[0]);
      }
    }

    if (!profile) {
      getProfile();
    }
  });

  return (
    <>
      <StaticHeaderTwoComponent />
      {profile && (
        <>
          <Helmet>
            <title>{`Dr. ${profile.name} | Trusted Doctor Across India`}</title>
            <link rel="canonical" href={`https://medoc.life/dr/${username}`} />
            <meta
              name="title"
              content={`Dr. ${profile.displayName} | Trusted Doctor Across India`}
            />
            <meta name="description" content={profile.bio} />
            <meta
              name="keywords"
              content={`${profile.displayName}, ${profile.username}, ${profile.bio}, ${profile.qualification}, ${profile.specialities}`}
            />

            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://medoc.life/dr/${username}`}
            />
            <meta
              property="og:title"
              content={`Dr. ${profile.displayName} | Trusted Doctor Across India`}
            />
            <meta property="og:description" content={profile.bio} />
            <meta property="og:image" content={profile.photoURL} />
            <meta property="og:site_name" content="medoc.life" />

            <meta
              property="twitter:url"
              content={`https://medoc.life/dr/${username}`}
            />
            <meta
              property="twitter:title"
              content={`Dr. ${profile.displayName} | Trusted Doctor Across India`}
            />
            <meta property="twitter:description" content={profile.bio} />
            <meta property="twitter:image" content={profile.photoURL} />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="1 minute" />
          </Helmet>
          <div className="container doctor-profile-wrapper py-80">
            <AboutMeComponent profile={profile} />
            <ServicesComponent profile={profile} />
            <AppointmentDetailsComponent profile={profile} />
          </div>
          <ClinicsComponent />
        </>
      )}
      <StaticFooterComponent />
    </>
  );
};

export default DoctorProfileComponent;
