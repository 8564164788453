import React from "react";
import { Helmet } from "react-helmet";
import "./style.css";

import { StaticHeaderComponent, StaticFooterComponent } from "components";
import { Products } from "constants/index";
import { truncateText } from "helpers/index";

const StoreComponent = (props) => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Medoc Store - Your Premier Online Healthcare Store for the Modern Generation</title>
        <link rel="canonical" href="https://medoc.life/store" />
        <meta
          name="description"
          content="Discover top-quality healthcare products and essentials at Medoc.life. Catering to the needs of India's urban youth, we offer a wide range of health solutions, from wellness products to medical supplies. Shop now for a healthier tomorrow!"
        />
        <meta name="keywords" content="healthcare store, online healthcare store, healthcare products, wellness products, medical supplies, India, metro cities, urban youth, Medoc.life, health essentials, healthcare solutions"/>
        <meta
          property="og:title"
          content="Medoc Store - Your Premier Online Healthcare Store for the Modern Generation"
        />
        <meta
          property="og:description"
          content="Discover top-quality healthcare products and essentials at Medoc.life. Catering to the needs of India's urban youth, we offer a wide range of health solutions, from wellness products to medical supplies. Shop now for a healthier tomorrow!"
        />
        <meta property="og:url" content="https://medoc.life/store" />
        <meta
          property="og:site_name"
          content="Discover top-quality healthcare products and essentials at Medoc.life. Catering to the needs of India's urban youth, we offer a wide range of health solutions, from wellness products to medical supplies. Shop now for a healthier tomorrow!"
        />
        <meta
          property="article:modified_time"
          content="2024-03-10T04:31:02+00:00"
        />
        <meta
          name="twitter:title"
          content="Medoc Store - Your Premier Online Healthcare Store for the Modern Generation"
        />
        <meta
          name="twitter:description"
          content="Discover top-quality healthcare products and essentials at Medoc.life. Catering to the needs of India's urban youth, we offer a wide range of health solutions, from wellness products to medical supplies. Shop now for a healthier tomorrow!"
        />
      </Helmet>
      <StaticHeaderComponent />
      <div className="container store-container">
        <h1 className="h2 text-center fw-700 mt-5">
          India's trending healthcare store
        </h1>
        <div className="text-center text-secondary">Sexual & Skin care products recommended by healthcare professionals</div>
        {Products.map((product, pid) => (
          <>
            <h2 className="h5 fw-800 mt-4">
              {product.name}
            </h2>
            <div className="row">
              {product.template.map((p) => (
                <>
                  <div className="col-12 col-sm-4 col-md-3 my-2">
                    <div className="card b-0 product-card">
                      <a href={p.link} target="_blank" rel="noreferrer noopener" key={p.link}>
                        <img src={p.imgLink} className="card-img-top" alt={p.name} />
                        <div className="card-body text-black">
                          <div className="fw-700 card-title fs-24">{truncateText(p.name)}</div>
                          <p className="card-text fs-14">{p.description}</p>
                          <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="btn btn-violet-rounded btn-sm px-3">Buy</div>
                            <span>&#8377; {p.price}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </>
        ))}
      </div>
      <div className="py-5"></div>
      <StaticFooterComponent />
    </>
  );
};

export default StoreComponent;
