export const Faqs = [
  {
    key: "General",
    values: [
      {
        question: "What certificates do you provide?",
        answer:
          "We provide sick leave, fitness recovery, caretaker and form-1a certificates. Our doctors will complete your request within 60 minutes*",
      },
      {
        question: "When will I receive my certificate?",
        answer:
          "Your certificate will be emailed to you after your online consultation. You can then print or email this to your employer/university to receive your entitlement. Further, you can download the certificate at any time in Medoc platform",
      },
      {
        question: "Will my university/employer accept my certificate?",
        answer:
          "All our certificates are valid documents and meet all the requirements to the clause 34 of The National Medical Commission Act 2019 which requires providing enough evidence that the employee/student was genuinely entitled to the sick or carer’s leave. Your organisation is allowed ask for more evidence, if they require.",
      },
      {
        question: "How can university/employer verify the certificate?",
        answer:
          "All certificates can be verified via <a href='https://medoc.life/employer-verify'>Verify Certificate</a> section.",
      },
      {
        question: "What are your hours of operation?",
        answer:
          "You can submit the request at any time 24x7. Our doctor buddies will deliver your certificate in between 7AM - 11PM IST",
      },
      {
        question: "Do you operate across India?",
        answer:
          "Yes, our healthcare professionals are located across India and can serve people from all Indian states and territories.",
      },
      {
        question: "What happens if my request is not accepted?",
        answer:
          "Your Doctor Buddy might occasionally decide it's inappropriate to issue you a medical certificate. Your Doctor Buddy will always make these determinations in the interest of your health, and you will be given a refund if this occurs, less a service charge of INR 500/-. The charge will be different based on your certificate request. Checkout our <a href='https://medoc.life/rp' target='_blank' rel='noopener noreferrer' class='text-violet fw-600'><u>Refund Policy</u></a> for complete details.",
      },
      {
        question: "How to get a discount on medical certificates?",
        answer:
          "All users who provide a positive review <a href='https://www.trustpilot.com/review/medoc.life' target='_blank' rel='noopener noreferrer' class='text-violet fw-600'><u>on the Trustpilot Page</u></a> get a 10% discount. You will receive a discount code within 60 minutes of posting your Trustpilot review.",
      },
    ],
  },
];
